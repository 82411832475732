<template>
  <component
    :is="tag"
    ref="modalRef"
    class="timepicker-modal"
    tabindex="-1"
    role="dialog"
  >
    <div
      :class="wrapperClass"
      @click.self="handleCancelModal"
      ref="wrapperRef"
      v-mdb-focus-trap
    >
      <div
        class="
          d-flex
          align-items-center
          justify-content-center
          flex-column
          shadow
          timepicker-container
        "
        ref="timepickerContainerRef"
      >
        <div
          class="d-flex flex-column timepicker-elements justify-content-around"
        >
          <MDBTimepickerHeader />
          <MDBTimepickerPlate />
        </div>
        <MDBTimepickerFooter />
      </div>
    </div>
  </component>
</template>

<script>
import { computed, inject, onMounted, ref, watchEffect } from "vue";
import MDBTimepickerFooter from "./MDBTimepickerFooter";
import MDBTimepickerHeader from "./MDBTimepickerHeader";
import MDBTimepickerPlate from "./MDBTimepickerPlate";
import mdbFocusTrap from "@/directives/free/mdbFocusTrap.js";

export default {
  name: "MDBTimepickerModal",
  components: {
    MDBTimepickerFooter,
    MDBTimepickerHeader,
    MDBTimepickerPlate,
  },
  directives: {
    mdbFocusTrap,
  },
  props: {
    tag: {
      type: String,
      default: "div",
    },
  },
  emits: ["cancel-modal"],
  setup(props, { attrs, emit }) {
    // ------------- REFS -------------
    const modalRef = ref(null);
    const wrapperRef = ref(null);

    // ------------- STYLING -------------
    const showClass = ref(false);
    const wrapperClass = computed(() => {
      return [
        "timepicker-wrapper h-100 d-flex align-items-center justify-content-center flex-column position-fixed fade",
        showClass.value && "show",
      ];
    });

    // ------------- OPENING/CLOSING METHODS -------------
    const isActive = inject("isModalOpen", false);
    const closePicker = inject("closePicker", null);

    const handleCancelModal = () => {
      // removeFocusTrap();
      emit("cancel-modal");
    };

    const isBodyOverflowing = ref(null);
    const scrollbarWidth = ref(0);

    // Bootstrap way to measure scrollbar width
    const getScrollbarWidth = () => {
      const scrollDiv = document.createElement("div");
      scrollDiv.className = "modal-scrollbar-measure";
      document.body.appendChild(scrollDiv);
      const scrollbarWidth =
        scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
      return scrollbarWidth;
    };

    const setScrollbarWidth = () => {
      const rect = document.body.getBoundingClientRect();
      isBodyOverflowing.value =
        Math.round(rect.left + rect.right) < window.innerWidth;
      scrollbarWidth.value = isBodyOverflowing.value
        ? getScrollbarWidth().toFixed(2)
        : 0;
    };

    const setPaddings = (mode) => {
      const padding = mode === "add" ? `${scrollbarWidth.value}px` : "0";
      if (wrapperRef.value) {
        wrapperRef.value.style.paddingRight = padding;
      }
      document.body.style.paddingRight = padding;

      if (mode === "add") {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    };

    watchEffect(() => {
      if (!isActive.value) {
        showClass.value = false;
        setPaddings();
        setTimeout(() => {
          closePicker();
        }, 300);
      }
    });

    // ------------- LIFECYCLE HOOKS -------------
    onMounted(() => {
      showClass.value = true;
      setScrollbarWidth();
      setPaddings("add");
    });

    return {
      modalRef,
      wrapperRef,
      wrapperClass,
      handleCancelModal,
      isActive,
      attrs,
      props,
    };
  },
};
</script>
