<template>
  <component :is="tag" :id="uid" ref="canvasRef" :class="canvasClass" />
</template>

<script>
import generateCharts from "./generateCharts";
import { getUID } from "@/components/utils/getUID";
import { onMounted, ref, watch } from "vue";

export default {
  name: "MDBChart",
  props: {
    tag: {
      type: String,
      default: "canvas",
    },
    type: String,
    data: Object,
    options: Object,
    datalabels: {
      type: Boolean,
      default: false,
    },
    id: String,
    canvasClass: String,
  },
  setup(props) {
    const canvasRef = ref(null);
    const uid = props.id || getUID("MDBChart-");

    const setupChartsFunc = ref(null);
    const renderChartFunc = ref(null);
    const addDataLabelsFunc = ref(null);
    const updateChartFunc = ref(null);

    const getChartsFunctions = ({
      setupCharts,
      renderChart,
      addDataLabels,
      updateChart,
    }) => {
      setupChartsFunc.value = setupCharts;
      renderChartFunc.value = renderChart;
      addDataLabelsFunc.value = addDataLabels;
      updateChartFunc.value = updateChart;
    };

    watch(
      () => props.data,
      (newData) => {
        updateChartFunc.value(newData, props.options);
      }
    );

    watch(
      () => props.options,
      (newOptions) => {
        updateChartFunc.value(props.data, newOptions);
      }
    );

    onMounted(() => {
      // chart functions must be initialized on mount to properly pass canvasRef value to Charts.js
      const chartsFunctions = generateCharts(
        canvasRef.value,
        props.type,
        props.data,
        props.options
      );

      getChartsFunctions(chartsFunctions);

      if (props.datalabels) {
        addDataLabelsFunc.value();
      }

      renderChartFunc.value();
    });

    return {
      props,
      canvasRef,
      uid,
    };
  },
};
</script>
