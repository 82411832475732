<template>
  <transition :name="transitionName">
    <component
      v-show="isActive"
      :class="className"
      :is="tag"
      :id="id"
      v-bind="$attrs"
      ref="sidenav"
      @mouseover="onMouseOver"
      @mouseleave="onMouseLeave"
      v-mdb-touch:swipe="{
        callback: swipe,
        direction: 'x',
      }"
    >
      <MDBScrollbar suppressScrollX :height="`${windowHeight}px`">
        <slot />
      </MDBScrollbar>
    </component>
  </transition>
  <transition name="backdrop">
    <div
      :class="['sidenav-backdrop', backdropClass]"
      :style="backdropStyle"
      v-if="isBackdropActive"
      @click.self="hideSideNav"
      @wheel="blockBackdropScroll"
    />
  </transition>
</template>

<script>
import {
  computed,
  ref,
  onMounted,
  watch,
  onUnmounted,
  nextTick,
  provide,
  watchEffect,
} from "vue";
import { on, off } from "../../utils/MDBEventHandlers";
import mdbTouch from "@/directives/pro/mdbTouch.js";
import { MDBScrollbar } from "@/index.pro.js";

export default {
  name: "MDBSideNav",
  inheritAttrs: false,
  components: {
    MDBScrollbar,
  },
  directives: { mdbTouch },
  props: {
    tag: {
      type: String,
      default: "nav",
    },
    color: {
      type: String,
      default: "primary",
    },
    classes: String,
    modelValue: Boolean,
    relative: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "over",
      validator: (value) =>
        ["over", "side", "push"].indexOf(value.toLowerCase()) > -1,
    },
    light: {
      type: Boolean,
      defualt: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    slim: {
      type: Boolean,
      default: false,
    },
    slimCollapsed: {
      type: Boolean,
      default: true,
    },
    slimWidth: {
      type: Number,
      default: 60,
    },
    sidenavWidth: {
      type: Number,
      default: 240,
    },
    backdrop: {
      type: Boolean,
      default: true,
    },
    backdropClass: String,
    backdropStyle: String,
    contentSelector: String,
    id: String,
    modeBreakpoint: Number,
    closeOnEsc: {
      type: Boolean,
      default: false,
    },
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const sidenav = ref("sidenav");
    const isMounted = ref(false);

    const className = computed(() => {
      return [
        "sidenav",
        props.color && `sidenav-${props.color}`,
        props.absolute && "sidenav-absolute",
        props.relative && "sidenav-relative",
        props.light && "sidenav-light",
        props.right && "sidenav-right",
        props.slim && "sidenav-slim",
        props.slim &&
          props.slimCollapsed &&
          isSlimCollapsed.value &&
          "sidenav-slim-collapsed",
        props.classes,
      ];
    });

    const transitionName = computed(() => {
      return props.right ? "right-sidenav" : "sidenav";
    });

    const isBackdropActive = computed(() => {
      if (
        isMounted.value &&
        props.backdrop &&
        isActive.value &&
        sidenavMode.value === "over"
      ) {
        return true;
      }

      return false;
    });

    const blockBackdropScroll = (e) => {
      if (isActive.value) e.preventDefault();
    };

    const getSidenavScrollheight = () => {
      if (props.relative || props.absolute) {
        return sidenav.value.parentNode.offsetHeight ?? null;
      }
      return window.innerHeight;
    };

    const handleHeightChange = () => {
      windowHeight.value = getSidenavScrollheight();
    };

    const windowHeight = ref(null);

    const sidenavMode = ref(props.mode);
    const toggleButton = ref(null);
    const windowWidth = ref(window.innerWidth);
    const modeTransitionOver = ref(false);
    const handleModeTransitionResize = () => {
      windowWidth.value = window.innerWidth;

      if (window.innerWidth < props.modeBreakpoint) {
        sidenavMode.value = "over";
        emit("update:modelValue", false);
        modeTransitionOver.value = true;
        if (toggleButton.value) {
          toggleButton.value.style.display = "unset";
        }
      } else {
        sidenavMode.value = "side";
        if (!isActive.value) {
          emit("update:modelValue", true);
        }
        modeTransitionOver.value = false;
        if (toggleButton.value) {
          toggleButton.value.style.display = "none";
        }
      }

      setContentOffset(sidenavMode.value);
    };

    const pageContent = ref(null);

    const contentOffsetWidth = computed(() => {
      if (isActive.value) {
        return props.slim && props.slimCollapsed
          ? props.slimWidth
          : props.sidenavWidth;
      }
      return 0;
    });

    const setContentOffset = (value) => {
      if (!pageContent.value) {
        pageContent.value = document.querySelector(props.contentSelector);
      }

      const marginProperty = props.right ? "margin-left" : "margin-right";
      const paddingProperty = props.right ? "padding-right" : "padding-left";

      if (!pageContent.value) return;

      pageContent.value.style.transition = "all 0.3s linear 0s";

      if (value === "push") {
        pageContent.value.style[marginProperty] =
          -1 * contentOffsetWidth.value + "px";
        pageContent.value.style[paddingProperty] =
          contentOffsetWidth.value + 20 + "px";
      } else if (value === "side") {
        pageContent.value.style[marginProperty] = "0px";
        pageContent.value.style[paddingProperty] =
          contentOffsetWidth.value + 20 + "px";
      } else if (value === "over") {
        pageContent.value.style[paddingProperty] = "20px";
        pageContent.value.style[marginProperty] = "0px";
      }

      setTimeout(() => {
        pageContent.value.style.transition = "all 0.3s ease 0s";
      }, 300);
    };

    const isActive = ref(props.modelValue);

    const setFocusTrapHandler = () => {
      nextTick(() => {
        calculateFocusTrap();
        on(window, "keydown", focusFirstElement);
      });
    };

    watch(
      () => props.modelValue,
      (cur) => {
        isActive.value = cur;

        if (isActive.value && sidenavMode.value === "over") {
          setFocusTrapHandler();
        }
        if (isActive.value && props.closeOnEsc) {
          bindHandleEscapeClick();
        }

        setContentOffset(sidenavMode.value);
      }
    );

    watchEffect(() => (sidenavMode.value = props.mode));

    watch(
      () => props.mode,
      (cur) => {
        if (isActive.value) {
          setContentOffset(cur);
          if (cur === "over") {
            setFocusTrapHandler();
          } else if (
            cur !== "over" &&
            lastFocusableElement &&
            lastFocusableElement.value
          ) {
            removeFocusTrap();
          }
        }
      }
    );

    const parentsEl = (element, selector) => {
      const parents = [];

      let ancestor = element.parentNode;

      while (
        ancestor &&
        ancestor.nodeType === Node.ELEMENT_NODE &&
        ancestor.nodeType !== 3
      ) {
        if (ancestor.matches(selector)) {
          parents.push(ancestor);
        }

        ancestor = ancestor.parentNode;
      }

      return parents;
    };

    const prevEl = (element, selector) => {
      let previous = element.previousElementSibling;

      while (previous) {
        if (previous.matches(selector)) {
          return [previous];
        }

        previous = previous.previousElementSibling;
      }

      return [];
    };

    const activeNode = ref(null);
    const setActiveNode = (id, node) => {
      activeNode.value = id;

      const [collapse] = parentsEl(node, ".sidenav-collapse");

      if (!collapse) {
        setActiveCategory();
        return;
      }

      // Category

      const [category] = prevEl(collapse, ".sidenav-link");
      setActiveCategory(category);
    };

    const setActiveCategory = (el) => {
      [...sidenav.value.querySelectorAll(".sidenav-menu")].forEach((menu) => {
        const collapses = menu.querySelectorAll(".sidenav-collapse");

        collapses.forEach((collapse) => {
          const [collapseToggler] = prevEl(collapse, ".sidenav-link");
          if (!el || collapseToggler.id !== el.id) {
            collapseToggler.classList.remove("active");
          } else {
            collapseToggler.classList.add("active");
          }
        });
      });
    };

    provide("activeNode", activeNode);
    provide("setActiveNode", setActiveNode);

    const bindHandleEscapeClick = () => {
      on(document, "keydown", handleEscKey);
    };

    const handleEscKey = (e) => {
      // prevent from closing sidenav when no toggle (toggleButton) is visible
      if (
        props.closeOnEsc &&
        e.key === "Escape" &&
        toggleButton.value &&
        toggleButton.value.style.display !== "none"
      ) {
        emit("update:modelValue", false);
        off(document, "keydown", handleEscKey);
      }
    };

    const hideSideNav = () => {
      emit("update:modelValue", false);
    };

    const firstFocusableElement = ref(null);
    const lastFocusableElement = ref(null);

    const calculateFocusTrap = () => {
      const focusable = Array.from(
        sidenav.value.querySelectorAll(
          'button, a, input, select, textarea, [tabindex]:not([tabindex="-1"])'
        )
      ).filter((el) => {
        return (
          !el.classList.contains("ps__thumb-x") &&
          !el.classList.contains("ps__thumb-y")
        );
      });

      if (focusable.length === 0) return;

      firstFocusableElement.value = focusable[0];
      const lastElement = focusable[focusable.length - 1];

      if (
        lastFocusableElement.value &&
        lastFocusableElement.value !== lastElement
      ) {
        off(lastFocusableElement.value, "blur", focusTrap);
      }

      lastFocusableElement.value = lastElement;
      on(lastFocusableElement.value, "blur", focusTrap);
    };

    const focusTrap = () => {
      if (!firstFocusableElement.value) return;

      firstFocusableElement.value.focus();
    };

    const focusFirstElement = (e) => {
      if (isActive.value && e.key === "Tab") {
        e.preventDefault();
        focusTrap();
      }
      off(window, "keydown", focusFirstElement);
    };

    const removeFocusTrap = () => {
      off(lastFocusableElement.value, "blur", focusTrap);
    };

    const isSlimCollapsed = ref(props.slimCollapsed);

    const onMouseLeave = () => {
      if (props.slim && props.expandOnHover) {
        isSlimCollapsed.value = true;
      }
    };

    const onMouseOver = () => {
      if (props.slim && props.expandOnHover) {
        isSlimCollapsed.value = false;
      }
    };

    const swipe = (direction) => {
      let isOpenSwipeDirection = direction === "right" ? true : false;

      if (props.right) {
        isOpenSwipeDirection = !isOpenSwipeDirection;
      }

      if (props.slim && isSlimCollapsed.value && isOpenSwipeDirection) {
        isSlimCollapsed.value = false;
        return;
      }
      if (props.slim && !isSlimCollapsed.value && !isOpenSwipeDirection) {
        isSlimCollapsed.value = true;
      }
    };

    provide("sidenavColor", props.color);

    onMounted(() => {
      isMounted.value = true;

      if (props.contentSelector) {
        pageContent.value = document.querySelector(props.contentSelector);
      }

      // setting initial values for watched properties
      // in composition api setup is run on `created` cycle so following would be ran before
      // pageContent is set, thus those handlers wold not work properly
      if (props.modelValue) {
        setContentOffset(props.mode);
      }
      if (props.mode === "over") {
        setFocusTrapHandler();
      }

      if (props.closeOnEsc) {
        bindHandleEscapeClick();
      }
      // end of described handlers

      toggleButton.value = document.querySelector(
        `[aria-controls="#${props.id}"]`
      );

      if (props.dark) {
        sidenav.value.style.backgroundColor = "#2d2c2c";
      }

      windowHeight.value = getSidenavScrollheight();
      on(window, "resize", handleHeightChange);

      if (props.modeBreakpoint) {
        handleModeTransitionResize();
        on(window, "resize", handleModeTransitionResize);
      }

      on(window, "keydown", focusFirstElement);
    });

    onUnmounted(() => {
      off(window, "resize", handleModeTransitionResize);
      off(window, "resize", handleHeightChange);
      off(document, "keydown", handleEscKey);
    });

    return {
      sidenav,
      className,
      isActive,
      hideSideNav,
      onMouseOver,
      onMouseLeave,
      swipe,
      transitionName,
      isBackdropActive,
      windowHeight,
      blockBackdropScroll,
      props,
    };
  },
};
</script>
