<template>
  <component
    :is="tag"
    ref="modalRef"
    class="timepicker-modal"
    tabindex="-1"
    role="dialog"
  >
    <div
      ref="wrapperRef"
      :class="wrapperClass"
      v-mdb-click-outside="handleCancelModal"
      v-mdb-focus-trap
    >
      <div
        class="
          d-flex
          align-items-center
          justify-content-center
          flex-column
          shadow
          timepicker-container
        "
      >
        <div
          class="
            d-flex
            flex-column
            timepicker-elements
            justify-content-around
            timepicker-elements-inline
          "
        >
          <MDBTimepickerHeader />
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { computed, inject, nextTick, onMounted, ref, watchEffect } from "vue";
import mdbClickOutside from "@/directives/free/mdbClickOutside.js";
import MDBPopper from "@/components/utils/MDBPopper";
import MDBTimepickerHeader from "./MDBTimepickerHeader";
import mdbFocusTrap from "@/directives/free/mdbFocusTrap.js";

export default {
  name: "MDBTimepickerInline",
  components: { MDBTimepickerHeader },
  props: {
    tag: {
      type: String,
      default: "div",
    },
  },
  directives: {
    mdbClickOutside,
    mdbFocusTrap,
  },
  emits: ["cancel-modal"],
  setup(props, { attrs, emit }) {
    // ------------- REFS -------------
    const modalRef = ref("modalRef");
    const wrapperRef = ref("wrapperRef");

    // ------------- STYLING -------------
    const showClass = ref(false);
    const wrapperClass = computed(() => {
      return [
        "timepicker-wrapper h-100 d-flex align-items-center justify-content-center flex-column timepicker-wrapper-inline fade",
        showClass.value && "show",
      ];
    });

    // ------------- POPPER METHODS -------------
    const { setPopper, openPopper, closePopper } = MDBPopper();
    const parentId = inject("inputId", null);

    const fallbackPlacements = ["top", "right", "bottom", "left"];

    const popperSetup = () => {
      let triggerEl;
      if (parentId) {
        triggerEl = document.getElementById(parentId);
      }
      const popperEl = modalRef.value;

      const config = {
        placement: `bottom-start`,
        eventsEnabled: true,
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements,
            },
          },
          {
            name: "preventOverflow",
            options: {
              boundary: "clippingParent",
            },
          },
        ],
      };

      setPopper(triggerEl, popperEl, config);

      openPopper();
    };

    // ------------- OPENING/CLOSING METHODS -------------
    const isActive = inject("isModalOpen", false);
    const closePicker = inject("closePicker", null);
    const handleCancelClick = inject("handleCancelClick", null);

    watchEffect(() => {
      if (!isActive.value) {
        showClass.value = false;
        closePopper();
        setTimeout(() => {
          closePicker();
        }, 300);
      }
    });

    // prevent handleClose from firing on modal show
    const canClose = ref(false);
    const handleCancelModal = (e) => {
      if (isActive.value && !e.target.closest(".timepicker-modal")) {
        if (canClose.value) {
          emit("cancel-modal");
        }
      }
    };

    // ------------- LIFECYCLE HOOKS -------------
    onMounted(() => {
      popperSetup();
      nextTick(() => {
        showClass.value = true;
      });
      setTimeout(() => {
        canClose.value = true;
      }, 500);
    });

    return {
      wrapperRef,
      modalRef,
      wrapperClass,
      handleCancelModal,
      handleCancelClick,
      attrs,
      props,
    };
  },
};
</script>
