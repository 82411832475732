<template>
  <component v-if="collapse" :class="className" :is="tag" v-bind="$attrs">
    <MDBSideNavLink :icon="isArrowIcon" v-model="isActive">
      <MDBIcon :icon="icon" :iconStyle="iconStyle" :class="iconClass" />
      <span :class="nonSlimClass">{{ title }}</span>
    </MDBSideNavLink>
    <MDBCollapse sidenav tag="ul" v-model="isActive">
      <slot />
    </MDBCollapse>
  </component>

  <component v-else :class="className" :is="tag" v-bind="$attrs">
    <slot />
  </component>
</template>

<script>
import { computed, onMounted, provide, ref, watch } from "vue";
import MDBCollapse from "../../free/components/MDBCollapse";
import MDBIcon from "../../free/content-styles/MDBIcon";
import MDBSideNavLink from "./MDBSideNavLink";

export default {
  name: "MDBSideNavItem",
  components: { MDBCollapse, MDBIcon, MDBSideNavLink },
  props: {
    tag: {
      type: String,
      default: "li",
    },
    collapse: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    classes: String,
    icon: String,
    iconStyle: {
      type: String,
      default: "fas",
    },
    iconClass: {
      type: String,
      default: "fa-fw me-3",
    },
    title: String,
    hasArrow: {
      type: Boolean,
      default: true,
    },
    nonSlim: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:show"],
  setup(props) {
    const className = computed(() => {
      return ["sidenav-item", props.classes];
    });

    const nonSlimClass = computed(() => {
      return [props.nonSlim && "sidenav-non-slim"];
    });

    const isArrowIcon = computed(() => {
      return props.hasArrow ? "angle-down" : false;
    });

    const hasCollapsibleContent = ref(false);
    provide("hasCollapsibleContent", hasCollapsibleContent);

    onMounted(() => {
      if (props.collapse) {
        hasCollapsibleContent.value = true;
      }
    });

    const isActive = ref(props.show);
    watch(
      () => props.show,
      (cur) => (isActive.value = cur)
    );

    return {
      className,
      nonSlimClass,
      isArrowIcon,
      isActive,
      props,
    };
  },
};
</script>
