<template>
  <MDBNavbar v-bind="$props" :classNavbar="className">
    <slot />
  </MDBNavbar>
</template>

<script>
import MDBNavbar from "@/components/free/navigation/MDBNavbar";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { on, off } from "@/components/utils/MDBEventHandlers";

export default {
  name: "MDBNavbarPro",
  components: {
    MDBNavbar,
  },
  props: {
    tag: {
      type: String,
      default: "nav",
    },
    bg: {
      type: String,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    double: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: String,
    },
    position: {
      type: String,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    scrolling: {
      type: Boolean,
      default: false,
    },
    scrollingOffset: {
      type: Number,
      default: 100,
    },
    center: {
      type: Boolean,
      default: false,
    },
    container: {
      type: [Boolean, String],
      default: false,
    },
    classContainer: {
      type: String,
    },
    classNavbar: String,
    scroll: Boolean,
  },
  setup(props) {
    const isScrolled = ref(false);

    const className = computed(() => {
      return [
        props.scroll && "navbar-scroll",
        isScrolled.value ? "navbar-scrolled" : null,
        props.classNavbar,
      ].join(" ");
    });
    const handleScroll = () => {
      if (window.scrollY > 0) {
        isScrolled.value = true;
      } else {
        isScrolled.value = false;
      }
    };

    onMounted(() => {
      on(window, "scroll", handleScroll);
    });

    onUnmounted(() => {
      off(window, "scroll", handleScroll);
    });

    return { className, isScrolled };
  },
};
</script>
