<template>
  <component :is="tag" class="timepicker-footer">
    <div class="w-100 d-flex justify-content-between">
      <button
        type="button"
        class="timepicker-button timepicker-clear"
        tabindex="0"
        @click="handleClearClick"
        @keydown.enter="handleClearClick"
        @keydown.space="handleClearClick"
      >
        {{ clearLabel }}
      </button>
      <button
        type="button"
        class="timepicker-button timepicker-cancel"
        tabindex="0"
        @click="handleCancelClick"
        @keydown.enter="handleCancelClick"
        @keydown.space="handleCancelClick"
      >
        {{ cancelLabel }}
      </button>
      <button
        type="button"
        class="timepicker-button timepicker-submit"
        tabindex="0"
        @click="handleOkClick"
        @keydown.enter="handleOkClick"
        @keydown.space="handleOkClick"
      >
        {{ okLabel }}
      </button>
    </div>
  </component>
</template>

<script>
import { inject } from "vue";

export default {
  name: "MDBTimepickerFooter",
  props: {
    tag: {
      type: String,
      default: "div",
    },
  },
  setup(props) {
    // ------------- TIMEPICKER PROPS INJECTS -------------
    const okLabel = inject("okLabel", null);
    const clearLabel = inject("clearLabel", null);
    const cancelLabel = inject("cancelLabel", null);

    const handleOkClick = inject("handleOkClick", null);
    const handleClearClick = inject("handleClearClick", null);
    const handleCancelClick = inject("handleCancelClick", null);

    return {
      okLabel,
      clearLabel,
      cancelLabel,
      handleOkClick,
      handleClearClick,
      handleCancelClick,
      props,
    };
  },
};
</script>
