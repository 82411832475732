<template>
  <component
    :is="tag"
    :class="className"
    @mouseenter="() => isOnHover && startAnimation()"
    @click="() => isOnClick && startAnimation()"
    v-mdb-on-scroll="trigger === 'onScroll' ? activeScrollEvents : false"
    :style="animationWrapperStyle"
    ref="wrapper"
  >
    <slot />
  </component>
</template>

<script>
import { computed, onMounted, onUnmounted, watch, watchEffect } from "vue";
import { on, off } from "../../utils/MDBEventHandlers";
import mdbOnScroll from "@/directives/free/mdbOnScroll";
import { ref } from "vue";

export default {
  name: "MDBAnimation",
  props: {
    tag: {
      type: String,
      default: "div",
    },
    trigger: {
      type: String,
      default: "onClick",
      validator: (type) =>
        ["onHover", "onClick", "manually", "onLoad", "onScroll"].indexOf(type) >
        -1,
    },
    modelValue: Boolean,
    animation: {
      type: [String, Boolean],
      default: "fade-in",
    },
    duration: {
      type: Number,
      default: 500,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 0,
    },
    interval: {
      type: Number,
      default: 0,
    },
    repeat: {
      type: [Boolean, Number],
      default: false,
    },
    direction: {
      type: String,
      default: "normal",
    },
    repeatOnScroll: {
      type: Boolean,
      default: false,
    },
    scrollOffset: {
      type: Number,
      default: 0,
    },
    showOnLoad: {
      type: Boolean,
      default: true,
    },
  },
  directives: { mdbOnScroll },
  emits: ["hide", "show", "start", "end", "update:modelValue"],
  setup(props, { emit }) {
    const className = computed(() => {
      return [animationClasses.value];
    });

    const animationClasses = ref("");
    const setAnimationClasses = () => {
      animationClasses.value = `animation ${props.animation}`;
      emit("start", wrapper.value);
    };

    const clearAnimationClasses = () => {
      animationClasses.value = "";

      off(wrapper.value, "animationend", clearAnimationClasses);

      if (props.trigger === "manually" && props.modelValue && !props.repeat) {
        emit("update:modelValue", false);
      }
    };

    const emitEnd = () => {
      emit("end", wrapper.value);

      off(wrapper.value, "animationend", emitEnd);
    };

    const animationWrapperStyle = computed(() => {
      return {
        animationIterationCount:
          !props.interval && props.repeat === true ? "infinite" : props.repeat,
        animationDelay: `${props.delay}ms`,
        animationDuration: `${props.duration}ms`,
        animationDirection: props.direction,
      };
    });

    const startAnimation = () => {
      setAnimationClasses();

      if (isOnHover.value) {
        resetHoverEvents();
      }

      if (props.interval) {
        on(wrapper.value, "animationend", startInterval);
      }

      if (props.reset) {
        bindAnimationReset();
      }

      on(wrapper.value, "animationend", emitEnd);
    };

    const stopAnimation = () => {
      clearAnimationClasses();
    };

    watch(
      () => props.animation,
      () => {
        startAnimation();
      }
    );

    const isOnHover = ref(props.trigger === "onHover");
    const activateHoverEvents = () => {
      isOnHover.value = true;
    };

    const isOnClick = ref(props.trigger === "onClick");
    const activateClickEvents = () => {
      isOnClick.value = true;
    };

    const isOnManual = ref(props.trigger === "manually");
    const activateManualEvents = () => {
      isOnManual.value = true;
    };

    const activeScrollEvents = {
      callback: (value) => handleScroll(value),
      offset: props.scrollOffset,
      repeatOnShow: props.repeatOnScroll,
    };
    const handleScroll = (scrollStatus) => {
      switch (scrollStatus) {
        case "hasShown":
          startAnimation();
          showAnimateElement();
          break;
        case "hasHidden":
          if (props.repeatOnScroll) {
            clearAnimationClasses();
            if (!props.showOnLoad || props.scrollOffset > 0) {
              hideAnimateElement();
            }
          }
          break;
        default:
          break;
      }
    };

    const hideScrollElementOnShow = () => {
      if (!props.showOnLoad || props.scrollOffset > 0) {
        hideAnimateElement();
      }
    };

    const hideAnimateElement = () => {
      wrapper.value.style.visibility = "hidden";
      emit("hide", wrapper.value);
    };

    const showAnimateElement = () => {
      wrapper.value.style.visibility = "visible";
      emit("show", wrapper.value);
    };

    const triggerManual = ref(props.modelValue);
    watchEffect(() => {
      triggerManual.value = props.modelValue;
    });

    watch(
      () => triggerManual.value,
      (cur, prev) => {
        if (cur && isOnManual.value) {
          startAnimation();
        } else if (prev === true) {
          stopAnimation();
        }
      }
    );

    const startInterval = () => {
      clearAnimationClasses();
      setTimeout(() => {
        setAnimationClasses();
      }, props.interval);
    };

    const resetHoverEvents = () => {
      isOnHover.value = false;

      setTimeout(() => {
        isOnHover.value = true;
      }, props.duration + 100);
    };

    const bindAnimationReset = () => {
      on(wrapper.value, "animationend", clearAnimationClasses);
    };

    const wrapper = ref("wrapper");

    onMounted(() => {
      if (!props.animation) {
        return;
      }

      switch (props.trigger) {
        case "onHover":
          activateHoverEvents();
          break;
        case "onClick":
          activateClickEvents();
          break;
        case "onLoad":
          startAnimation();
          break;
        case "manually":
          activateManualEvents();
          break;
        case "onScroll":
          hideScrollElementOnShow();
          break;
        default:
          break;
      }
    });

    onUnmounted(() => {
      off(wrapper.value, "animationend", clearAnimationClasses);
      off(wrapper.value, "animationend", startInterval);
      off(wrapper.value, "animationend", emitEnd);
    });

    return {
      wrapper,
      animationWrapperStyle,
      className,
      isOnHover,
      isOnClick,
      activeScrollEvents,
      startAnimation,
      stopAnimation,
    };
  },
};
</script>
