<template>
  <component
    :is="tag"
    :class="className"
    v-bind="$attrs"
    ref="selectWrapperRef"
    v-mdb-click-outside="close"
    @click="toggle"
    @keydown.enter="handleEnter"
    @keydown.esc="close"
    @keydown.down.prevent="handleArrowDown"
    @keydown.up.prevent="handleArrowUp"
    @keydown.tab="close"
  >
    <MDBInput
      v-model="inputValue"
      labelClass="select-label"
      class="select-input"
      :class="isPopperActive && 'focused active'"
      :label="label"
      :placeholder="placeholder"
      :disabled="disabled"
      :size="size"
      :aria-disabled="disabled"
      :aria-expanded="isDropdownActive"
      :aria-required="isValidated && required"
      :role="filter ? 'combobox' : 'listbox'"
      aria-haspopup="true"
      readonly
    >
      <div class="valid-feedback">
        {{ validFeedback }}
      </div>
      <div class="invalid-feedback">
        {{ invalidFeedback }}
      </div>
      <span
        v-if="inputValue && clearButton"
        class="select-clear-btn"
        tabindex="0"
        @click.stop="clear"
        @keydown.enter.stop="clear"
        >✕</span
      >
      <span v-if="arrow" class="select-arrow"></span>
    </MDBInput>
  </component>
  <div
    v-if="isDropdownActive"
    :id="dropdownId"
    class="select-dropdown-container"
    :style="selectDropdownContainerStyle"
    ref="dropdownRef"
  >
    <div class="select-dropdown" :class="isPopperActive && 'open'" tabindex="0">
      <div v-if="filter" class="input-group" ref="searchWrapperRef" @click.stop>
        <input
          class="form-control select-filter-input"
          ref="searchRef"
          @input="handleFilter"
          @keydown.enter="handleEnter"
          @keydown.esc="close"
          @keydown.down.prevent="handleArrowDown"
          @keydown.up.prevent="handleArrowUp"
          @keydown.tab="close"
          @touchstart.stop
          :placeholder="searchPlaceholder"
          role="searchbox"
          type="text"
        />
      </div>
      <div
        class="select-options-wrapper"
        :style="selectOptionsWrapperStyle"
        @touchstart.stop
        ref="selectOptionsWrapperRef"
      >
        <div
          v-if="filter && filteredOptions.length === 0"
          class="select-no-results"
          :style="{ height: `${optionHeight}px` }"
        >
          {{ noResultsText }}
        </div>
        <div class="select-options-list">
          <div
            v-if="multiple && selectAll && search === ''"
            class="select-option"
            :class="[
              activeOptionKey === -1 && 'active',
              areAllOptionsChecked && 'selected',
              isOptgroup && !option.optgroup ? 'select-option-group' : null,
            ]"
            @click.stop="toggleSelectAll"
            :style="{ height: `${optionHeight}px` }"
          >
            <span class="select-option-text">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="areAllOptionsChecked"
                  tabindex="-1"
                />
                {{ selectAllLabel }}
              </div>
            </span>
          </div>
          <div
            class="select-option"
            :class="[
              option.disabled && 'disabled',
              activeOptionKey === key && 'active',
              option.selected && 'selected',
              isOptgroup && !option.optgroup ? 'select-option-group' : null,
            ]"
            v-for="(option, key) in filteredOptions"
            :style="{ height: `${optionHeight}px` }"
            @click.stop="handleOptionClick(option)"
            :key="key"
            role="option"
            :aria-selected="option.selected"
            :aria-disabled="option.disabled || false"
          >
            <span class="select-option-text" v-if="multiple">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="option.selected"
                  tabindex="-1"
                />
                {{ option.text }}
                <span
                  v-if="option.secondaryText"
                  class="select-option-secondary-text"
                  >{{ option.secondaryText }}</span
                >
              </div>
            </span>
            <span class="select-option-text" v-else>
              {{ option.text }}
              <span
                v-if="option.secondaryText"
                class="select-option-secondary-text"
                >{{ option.secondaryText }}</span
              >
            </span>
            <span v-if="option.icon" class="select-option-icon-container"
              ><img
                class="select-option-icon rounded-circle"
                :src="option.icon"
                alt="select-icon"
            /></span>
          </div>
        </div>
      </div>
      <div
        v-if="$slots.default"
        class="select-custom-content"
        @click.stop
        @touchstart.stop
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, onBeforeMount, nextTick } from "vue";
import MDBInput from "@/components/free/forms/MDBInput";
import MDBPopper from "@/components/utils/MDBPopper.js";
import { getUID } from "@/components/utils/getUID";
import mdbClickOutside from "@/directives/free/mdbClickOutside.js";

export default {
  name: "MDBSelect",
  inheritAttrs: false,
  components: {
    MDBInput,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selected: [String, Array, Number],
    preselect: {
      type: Boolean,
      default: true,
    },
    label: String,
    placeholder: String,
    disabled: Boolean,
    optionHeight: {
      type: Number,
      default: 38,
    },
    visibleOptions: {
      type: Number,
      default: 5,
    },
    optionsSelectedLabel: {
      type: String,
      default: "options selected",
    },
    displayedLabels: {
      type: Number,
      default: 5,
    },
    selectAll: {
      type: Boolean,
      default: true,
    },
    selectAllLabel: {
      type: String,
      default: "Select all",
    },
    required: Boolean,
    size: String,
    clearButton: Boolean,
    multiple: Boolean,
    isValidated: Boolean,
    isValid: Boolean,
    validFeedback: String,
    invalidFeedback: String,
    filter: Boolean,
    searchPlaceholder: {
      type: String,
      default: "Search...",
    },
    noResultsText: {
      type: String,
      default: "No results",
    },
    filterDebounce: {
      type: Number,
      default: 300,
    },
    tag: {
      type: String,
      default: "div",
    },
    arrow: {
      type: Boolean,
      default: true,
    },
  },
  directives: {
    mdbClickOutside,
  },
  emits: [
    "update:options",
    "update:selected",
    "update:modelValue",
    "change",
    "open",
    "close",
    "clear",
  ],
  setup(props, { emit }) {
    // Class & styles ------------------------
    const className = computed(() => {
      return [
        "select-wrapper",
        isSelectValidated.value && isSelectValid.value ? "is-valid" : "",
        isSelectValidated.value && !isSelectValid.value ? "is-invalid" : "",
      ];
    });
    const selectDropdownContainerStyle = computed(() => {
      return {
        position: "absolute",
        top: "0",
        width: dropdownWidth.value,
      };
    });
    const selectOptionsWrapperStyle = computed(() => {
      return {
        maxHeight: `${props.visibleOptions * props.optionHeight}px`,
      };
    });

    // Config ------------------------
    const selectWrapperRef = ref(null);
    const dropdownRef = ref(null);
    const selectOptionsWrapperRef = ref(null);
    const dropdownId = getUID("MDBSelectDropdown-");
    const dropdownWidth = ref("200px");
    const isDropdownActive = ref(false);
    const activeOptionKey = ref(null);
    let wasInitiated = false;
    const popperConfig = {
      placement: "bottom-start",
      eventsEnabled: true,
    };

    // Data ------------------------
    const modelOptions = ref(props.options);
    const filteredOptions = ref(modelOptions.value);
    const modelSelected = ref(props.selected);
    const isOptgroup = ref(
      modelOptions.value.some((option) => option.optgroup)
    );
    modelOptions.value.map((option, key) => (option.mdbKey = key));

    // Popper ------------------------
    const { setPopper, isPopperActive, closePopper, openPopper } = MDBPopper();

    // Public methods ------------------------
    const toggle = () => {
      if (isPopperActive.value) {
        close();
      } else {
        open();
      }
    };

    const close = () => {
      closePopper();
      emit("close");
      setTimeout(() => {
        isDropdownActive.value = false;
        search.value = "";
        filteredOptions.value = modelOptions.value;
      }, 300);
    };

    const open = () => {
      if (props.disabled) {
        return;
      }

      isDropdownActive.value = true;
      setActiveOptionKey();
      nextTick(() => openDropdown());

      if (props.filter) {
        setTimeout(() => {
          initSearch();
          scrollToInput();
        }, 100);
      }
    };

    const setOption = (key) => {
      if (props.multiple) {
        if (key === -1) {
          return toggleSelectAll();
        }
        if (!modelOptions.value[key].disabled) {
          modelOptions.value[key].selected = !modelOptions.value[key].selected;
        }
      } else {
        modelOptions.value.forEach((option) => {
          option.selected = false;
        });
        modelOptions.value[key].selected = true;
      }
    };

    const clear = () => {
      modelOptions.value.forEach((option) => {
        option.selected = false;
      });
      activeOptionKey.value = null;
      emit("clear");
    };

    const setValue = (request) => {
      clear();

      if (props.multiple) {
        request.forEach((val) => {
          const selectedKey = modelOptions.value.findIndex(
            (option) => option.value === val
          );
          if (selectedKey >= 0) {
            setOption(selectedKey);
          }
        });
      } else {
        const selectedKey = modelOptions.value.findIndex(
          (option) => option.value === request
        );

        if (selectedKey >= 0) {
          setOption(selectedKey);
          close();
        }
      }
    };

    const toggleSelectAll = () => {
      const areAllOptionsSelected = areAllOptionsChecked.value;
      modelOptions.value.forEach((option) => {
        option.selected = !areAllOptionsSelected;
      });
    };

    // Private methods ------------------------
    const emitChangeEvents = () => {
      emit("update:selected", modelSelected.value);
      if (wasInitiated) {
        emit("change");
      } else {
        wasInitiated = true;
      }
    };

    const setActiveOptionKey = () => {
      if (selectedOptions.value[0]) {
        activeOptionKey.value = filteredOptions.value.findIndex(
          (option) => option === selectedOptions.value[0]
        );

        if (props.multiple && props.selectAll && areAllOptionsChecked.value) {
          activeOptionKey.value = -1;
        }
      }
    };

    const openDropdown = () => {
      if (!dropdownRef.value) {
        return;
      }

      setPopper(selectWrapperRef.value, dropdownRef.value, popperConfig);
      openPopper();
      dropdownWidth.value = `${selectWrapperRef.value.offsetWidth}px`;

      nextTick(() => scrollBottomToOption());

      emit("open");
    };

    const initSearch = () => {
      if (searchRef.value) {
        searchRef.value.focus();
        searchHeight = searchWrapperRef.value.offsetHeight;
      }
    };

    const getScrollParent = (node) => {
      if (node == null) {
        return null;
      }

      if (node.scrollHeight > node.clientHeight) {
        return node;
      } else {
        return getScrollParent(node.parentNode);
      }
    };

    const scrollToInput = () => {
      if (!window) {
        return;
      }

      if (window.innerWidth < 992) {
        const scrollableParent = getScrollParent(selectWrapperRef.value);
        const selectOffsetTop = selectWrapperRef.value.offsetTop;

        scrollableParent.scrollTo({
          top: selectOffsetTop - 20,
          behavior: "smooth",
        });
      }
    };

    const setFirstNotDisabledOption = () => {
      if (
        !props.multiple &&
        props.preselect &&
        modelOptions.value.filter((option) => option.selected === true)
          .length === 0
      ) {
        const firstNotDisabledOption = modelOptions.value.findIndex(
          (option) => option.disabled !== true
        );
        setOption(firstNotDisabledOption);
      }

      if (!props.preselect) {
        wasInitiated = true;
      }
    };

    const handleOptionClick = (option) => {
      if (option.disabled) {
        return;
      }

      setOption(option.mdbKey);
      if (!props.multiple) {
        close();
      }
    };

    const setDefaults = () => {
      setFirstNotDisabledOption();
      setActiveOptionKey();
    };

    // Getters
    const selectedOptions = computed(() => {
      /* eslint-disable */
      if (props.multiple) {
        modelSelected.value = modelOptions.value
          .filter((option) => option.selected === true)
          .map((option) => option.value)
          .join(",");
      } else if (
        modelOptions.value.filter((value) => value.selected === true).length > 0
      ) {
        modelSelected.value = modelOptions.value.filter(
          (value) => value.selected === true
        )[0].value;
      } else {
        modelSelected.value = "";
        return "";
      }

      return modelOptions.value.filter((value) => value.selected === true);
      /* eslint-enable */
    });

    const inputValue = computed(() => {
      if (!selectedOptions.value || selectedOptions.value.length === 0) {
        return "";
      } else if (selectedOptions.value.length === 1) {
        return selectedOptions.value[0].text;
      } else if (props.multiple) {
        if (
          props.displayedLabels !== -1 &&
          selectedOptions.value.length > props.displayedLabels
        ) {
          return `${selectedOptions.value.length} ${props.optionsSelectedLabel}`;
        } else {
          return selectedOptions.value
            .map((selectedOption) => selectedOption.text)
            .join(", ");
        }
      }
      return "";
    });

    const areAllOptionsChecked = computed(() => {
      if (
        props.multiple &&
        props.selectAll &&
        selectedOptions.value.length === modelOptions.value.length
      ) {
        return true;
      }
      return false;
    });

    const activeEl = computed(() => {
      return dropdownRef.value.querySelectorAll(".select-option")[
        activeOptionKey.value
      ];
    });

    const isSelectAllVisible = computed(() => {
      if (props.multiple && props.selectAll && search.value === "") {
        return true;
      }

      return false;
    });

    const selectAllOptionHeight = computed(() => {
      if (isSelectAllVisible.value) {
        return props.optionHeight;
      }

      return 0;
    });

    const activeOptionOffsetTop = computed(() => {
      let offsetTop = 0;

      if (activeEl.value) {
        offsetTop =
          activeEl.value.offsetTop + selectAllOptionHeight.value - searchHeight;
      }

      return offsetTop;
    });

    const optionListHeight = computed(() => {
      return props.visibleOptions * props.optionHeight;
    });

    const isActiveOptionVisible = computed(() => {
      if (
        selectOptionsWrapperRef.value &&
        selectOptionsWrapperRef.value.scrollTop < activeOptionOffsetTop.value &&
        selectOptionsWrapperRef.value.scrollTop + optionListHeight.value >
          activeOptionOffsetTop.value
      ) {
        return true;
      }

      return false;
    });

    // Keyboard accessibility ------------------------
    const handleEnter = () => {
      if (props.multiple) {
        if (isPopperActive.value && activeOptionKey.value === null) {
          close();
          focusInput();
        } else if (isPopperActive.value && activeOptionKey.value === -1) {
          setOption(-1);
        } else if (isPopperActive.value && activeOptionKey.value !== null) {
          setOption(filteredOptions.value[activeOptionKey.value].mdbKey);
        } else {
          open();
        }
      } else {
        if (isPopperActive.value) {
          if (activeOptionKey.value !== null) {
            setOption(filteredOptions.value[activeOptionKey.value].mdbKey);
          }
          close();
          focusInput();
        } else {
          open();
        }
      }
    };

    const focusInput = () => {
      if (props.filter) {
        selectWrapperRef.value.querySelector(".select-input").focus();
      }
    };

    const handleArrowDown = () => {
      setNextActiveOptionKey();

      if (isDropdownActive.value) {
        scrollBottomToOption();
      }

      if (!props.multiple && !isDropdownActive.value) {
        setOption(activeOptionKey.value);
      } else if (props.multiple && !isDropdownActive.value) {
        open();
      }
    };

    const handleArrowUp = () => {
      if (activeOptionKey.value === null) {
        return;
      }

      setPrevActiveOptionKey();

      if (isDropdownActive.value) {
        scrollTopToOption();
      }

      if (!props.multiple && !isDropdownActive.value) {
        setOption(activeOptionKey.value);
      }
    };

    const setNextActiveOptionKey = () => {
      let nextOptionKey = activeOptionKey.value;

      if (activeOptionKey.value === null && isSelectAllVisible.value) {
        nextOptionKey = -1;
      } else {
        nextOptionKey = filteredOptions.value.findIndex(
          (option, key) =>
            (key > nextOptionKey || nextOptionKey === null) && !option.disabled
        );

        if (nextOptionKey === -1) {
          return;
        }
      }

      activeOptionKey.value = nextOptionKey;
    };

    const setPrevActiveOptionKey = () => {
      let prevOptionKey = activeOptionKey.value;

      if (activeOptionKey.value === 0 && isSelectAllVisible.value) {
        prevOptionKey = -1;
      } else {
        prevOptionKey = filteredOptions.value.indexOf(
          filteredOptions.value
            .filter((option, key) => key < prevOptionKey && !option.disabled)
            .pop()
        );

        if (prevOptionKey === -1) {
          return;
        }
      }

      activeOptionKey.value = prevOptionKey;
    };

    const scrollBottomToOption = () => {
      if (!isActiveOptionVisible.value && selectOptionsWrapperRef.value) {
        const offsetBottom =
          activeOptionOffsetTop.value -
          optionListHeight.value +
          props.optionHeight;
        selectOptionsWrapperRef.value.scrollTo(0, offsetBottom);
      }
    };

    const scrollTopToOption = () => {
      if (!isActiveOptionVisible.value && selectOptionsWrapperRef.value) {
        selectOptionsWrapperRef.value.scrollTo(0, activeOptionOffsetTop.value);
      }
    };

    // Validation ------------------------
    const isSelectValidated = ref(props.isValidated);
    const isSelectValid = ref(props.isValid);

    // Filtering ------------------------
    const searchWrapperRef = ref(null);
    const searchRef = ref(null);
    const search = ref("");
    let filterTimeout;
    let searchHeight = 0;

    const handleFilter = (event) => {
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(() => {
        activeOptionKey.value = null;
        search.value = event.target.value.toLowerCase();

        filteredOptions.value = modelOptions.value.filter((option) =>
          option.text.toLowerCase().includes(search.value)
        );

        closePopper();
        openPopper();
      }, props.filterDebounce);
    };

    // Hooks ------------------------
    onBeforeMount(() => {
      if (modelOptions.value.length > 0) {
        setDefaults();
      }
    });
    // Watchers ----------------------
    watch(
      () => props.options,
      (options) => {
        modelOptions.value = options;
        modelOptions.value.map((option, key) => (option.mdbKey = key));
        filteredOptions.value = modelOptions.value;
        if (modelOptions.value.length > 0) {
          setDefaults();
        }
      }
    );

    watch(
      () => modelSelected.value,
      () => emitChangeEvents()
    );

    watch(
      () => props.isValidated,
      (value) => (isSelectValidated.value = value)
    );

    watch(
      () => props.isValid,
      (value) => (isSelectValid.value = value)
    );

    return {
      className,
      props,
      dropdownId,
      inputValue,
      modelOptions,
      filteredOptions,
      handleOptionClick,
      handleArrowDown,
      handleArrowUp,
      isPopperActive,
      isDropdownActive,
      handleEnter,
      areAllOptionsChecked,
      activeOptionKey,
      selectDropdownContainerStyle,
      selectOptionsWrapperStyle,
      selectWrapperRef,
      isSelectValidated,
      isSelectValid,
      searchRef,
      searchWrapperRef,
      search,
      handleFilter,
      isOptgroup,
      dropdownRef,
      selectOptionsWrapperRef,
      // public
      open,
      close,
      toggle,
      clear,
      setValue,
      setOption,
      toggleSelectAll,
    };
  },
};
</script>
