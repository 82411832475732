<template>
  <component
    :is="tag"
    @click="handleClick"
    @keydown.delete="handleDeletePress"
    :class="tagClassName"
  >
    <div class="form-outline chips-input-wrapper" :class="notchClassName">
      <div
        v-for="(chip, index) of chipsList"
        :ref="setChipRef"
        :key="chip.id"
        :class="chipClassName(index)"
        @dblclick="handleEditable(chip.id, index)"
        @blur="disableEditable(chip.id, index)"
        @keydown.enter="disableEditable(chip.id, index)"
        v-mdb-ripple
        :contenteditable="chip.editable"
      >
        {{ chip.value }}
        <i
          v-show="chip.closeBtn"
          class="fas fa-times close"
          @click="handleCloseClick(chip)"
        />
      </div>
      <input
        class="form-control chips-input"
        :class="inputClassName"
        type="text"
        ref="input"
        :id="uid"
        v-model="inputValue"
        @blur="onBlur"
        :placeholder="calculatePlaceholder"
        @keyup.enter="handleAddingChips"
        @keydown.left="handleLeftPress"
        @keydown.right="handleRightPress"
        @keydown.down="handleDownPress"
        @keydown.up="handleUpPress"
      />
      <label
        v-if="props.label"
        ref="labelRef"
        :class="labelClassName"
        :for="uid"
      >
        {{ props.label }}
      </label>

      <div class="form-notch">
        <div
          class="form-notch-leading"
          :style="{ width: `${notchLeadingWidth}px` }"
        ></div>
        <div
          class="form-notch-middle"
          :style="{ width: `${notchMiddleWidth}px` }"
        ></div>
        <div class="form-notch-trailing"></div>
      </div>
    </div>
  </component>
</template>

<script>
import mdbRipple from "@/directives/free/mdbRipple.js";
import { getUID } from "../../../utils/getUID";
import {
  ref,
  computed,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  reactive,
} from "vue";

export default {
  name: "MDBChipsInput",
  directives: {
    mdbRipple,
  },
  props: {
    id: String,
    tag: {
      type: String,
      default: "div",
    },
    chips: {
      type: Array,
      default: () => [],
    },
    label: String,
    labelClasses: String,
    placeholder: String,
    secondaryPlaceholder: String,
    chipSize: String,
    value: Array,
    editable: Boolean,
  },
  emits: [
    "delete-chip",
    "add-chip",
    "arrow-down",
    "arrow-up",
    "arrow-left",
    "arrow-right",
  ],
  setup(props, { emit }) {
    const chipsList = ref([]);
    const inputValue = ref("");
    const input = ref(null);
    const chipRef = ref([]);
    const labelRef = ref(null);
    const isReadyToDelete = ref(true);
    const notchLeadingWidth = ref(9);
    const notchMiddleWidth = ref(0);
    const uid = props.id || getUID("MDBInput-");
    const state = reactive({
      count: 0,
      activeStep: undefined,
      prevStep: undefined,
    });

    function addChip() {
      if (
        inputValue.value === "" ||
        chipsList.value.find((chip) => chip.value === inputValue.value)
      ) {
        inputValue.value = "";
        return;
      }

      chipsList.value.push({
        value: inputValue.value,
        id: getUID("chip-"),
        editable: null,
        closeBtn: true,
        isActive: null,
      });
      inputValue.value = "";

      emit("add-chip", chipsValueList.value);
    }

    // focus behavior
    function handleClick(e) {
      if (
        e.target.classList.contains("chip") ||
        e.target.classList.contains("close")
      ) {
        return;
      }
      input.value.focus();
    }

    function onBlur() {
      chipsList.value.forEach((chip) => (chip.isActive = null));
      handleAddingChips();
    }

    // handle adding new Chips:
    function handleAddingChips() {
      if (/^ *$/.test(inputValue.value)) {
        inputValue.value = "";
        return;
      }
      addChip();
    }

    // handle removal - by pressing "Backspace" or "Delete":
    function handleDeletePress() {
      if (!inputValue.value && isReadyToDelete.value) {
        const activeChipIndex = chipsList.value.findIndex(
          (chip) => chip.isActive === true
        );

        if (activeChipIndex > -1) {
          chipsList.value.splice(activeChipIndex, 1);
          if (state.count) {
            if (state.prevStep > state.activeStep) {
              state.count = state.prevStep - 1;
            } else {
              state.count = state.activeStep - 1;
            }
          }

          if (chipsList.value[state.count]) {
            chipsList.value[state.count].isActive = true;
          }
        } else {
          chipsList.value.pop();

          if (state.count >= chipsList.value.length - 1) {
            state.count = chipsList.value.length - 1;
          }
        }

        emit("delete-chip", chipsValueList.value);
      }
    }

    // ... and by pressing the "close" button:
    function handleCloseClick(chipName) {
      const array = chipsList.value;
      const index = array.indexOf(chipName);
      array.splice(index, 1);

      if (state.count >= chipsList.value.length - 1) {
        state.count = chipsList.value.length - 1;
      }

      emit("delete-chip", chipsValueList.value);
    }

    function handleRightPress() {
      handleRightMove();
      emit("arrow-right");
    }

    function handleUpPress() {
      handleRightMove();
      emit("arrow-up");
    }

    function handleLeftPress() {
      handleLeftMove();
      emit("arrow-left");
    }

    function handleDownPress() {
      handleLeftMove();
      emit("arrow-down");
    }

    function handleRightMove() {
      if (state.activeStep === undefined) {
        chipsList.value[state.count].isActive = true;
        state.activeStep = state.count;
      } else {
        state.count++;
        chipsList.value[state.count - 1].isActive = null;
        if (state.count >= chipsList.value.length) {
          state.count = 0;
        }

        state.prevStep = state.activeStep;
        state.activeStep = state.count;

        chipsList.value[state.count].isActive = true;
      }
    }

    function handleLeftMove() {
      if (state.activeStep === undefined) {
        state.count = chipsList.value.length - 1;
        chipsList.value[state.count].isActive = true;
        state.activeStep = state.count;
      } else {
        state.count--;
        chipsList.value[state.count + 1].isActive = null;
        if (state.count <= -1) {
          state.count = chipsList.value.length - 1;
        }

        state.prevStep = state.activeStep;
        state.activeStep = state.count;

        chipsList.value[state.count].isActive = true;
      }
    }

    function handleEditable(id, index) {
      if (props.editable) {
        const filteredChip = chipsList.value.find((chip) => chip.id === id);
        if (filteredChip) {
          isReadyToDelete.value = false;
          filteredChip.editable = true;
          filteredChip.closeBtn = false;
        }

        if (chipRef.value[index]) {
          setTimeout(() => {
            chipRef.value[index].focus();
          }, 0);
        }
      }
    }

    function disableEditable(id, index) {
      if (props.editable) {
        isReadyToDelete.value = true;

        const filteredChip = chipsList.value.find((chip) => chip.id === id);
        if (filteredChip) {
          filteredChip.editable = false;
          filteredChip.closeBtn = true;
        }

        if (chipRef.value[index]) {
          filteredChip.value = chipRef.value[index].textContent.trim();
          if (filteredChip.value === "") {
            chipsList.value.splice(index, 1);
          }
        }
      }
    }

    function calcNotch() {
      if (labelRef.value) {
        notchMiddleWidth.value = labelRef.value.clientWidth * 0.8 + 8;
      }
    }

    function addInitialValues() {
      if (props.chips.length > 0) {
        props.chips.forEach((chip) => {
          chipsList.value.push({
            value: chip,
            id: getUID("chip-"),
            editable: null,
            closeBtn: true,
            isActive: null,
          });
        });
      } else {
        chipsList.value = [];
      }
    }

    const setChipRef = (el) => {
      if (el) {
        chipRef.value.push(el);
      }
    };

    onBeforeUpdate(() => (chipRef.value = []));

    onMounted(() => addInitialValues());

    onUpdated(() => calcNotch());

    const isNotEmpty = computed(() => chipsList.value.length > 0);

    const chipsValueList = computed(() =>
      chipsList.value.map((chip) => chip.value)
    );

    const tagClassName = computed(() => ["chips", "chips-placeholder"]);

    const chipClassName = (index) => {
      const isActive = chipsList.value.map((chip) => chip.isActive);

      return [
        "chip",
        props.chipSize && "chip-" + props.chipSize,
        isActive[index] && "active",
      ];
    };

    const notchClassName = computed(() => [
      isNotEmpty.value && "chips-padding chips-transition",
    ]);

    const inputClassName = computed(() => [isNotEmpty.value && "active"]);

    const labelClassName = computed(() => ["form-label", props.labelClasses]);

    const calculatePlaceholder = computed(() => {
      const calculatedPlaceholder =
        !props.secondaryPlaceholder && props.placeholder
          ? props.placeholder
          : props.secondaryPlaceholder;
      const calculatedSecondaryPlaceholder =
        !props.placeholder && props.secondaryPlaceholder
          ? props.secondaryPlaceholder
          : props.placeholder;
      if (chipsList.value.length > 0) {
        return calculatedSecondaryPlaceholder;
      }
      return calculatedPlaceholder;
    });

    return {
      tagClassName,
      chipClassName,
      notchClassName,
      inputClassName,
      labelClassName,
      calculatePlaceholder,
      handleDeletePress,
      handleLeftPress,
      handleRightPress,
      handleUpPress,
      handleDownPress,
      handleCloseClick,
      handleAddingChips,
      handleEditable,
      handleClick,
      onBlur,
      disableEditable,
      setChipRef,
      chipsList,
      inputValue,
      input,
      chipRef,
      uid,
      notchLeadingWidth,
      notchMiddleWidth,
      labelRef,
      props,
    };
  },
};
</script>

<style></style>
