<template>
  <div class="datepicker-date-controls">
    <button
      class="datepicker-view-change-button"
      :aria-label="switchToDayViewLabel"
      @click.stop="changeView('day')"
    >
      {{ years[0][0].label }} - {{ years[5][years[5].length - 1].label }}
    </button>
    <div class="datepicker-arrow-controls">
      <button
        class="datepicker-previous-button"
        :aria-label="prevMultiYearLabel"
        @click="startingYearKey -= 24"
      ></button>
      <button
        class="datepicker-next-button"
        :aria-label="nextMultiYearLabel"
        @click="startingYearKey += 24"
      ></button>
    </div>
  </div>

  <div
    class="datepicker-view"
    ref="datepickerViewRef"
    @keydown.up.prevent="handleDecrementYear(4)"
    @keydown.down.prevent="handleIncrementYear(4)"
    @keydown.left.prevent="handleDecrementYear(1)"
    @keydown.right.prevent="handleIncrementYear(1)"
    @keydown.enter="handleEnter"
    @keydown="handleKeydown"
    @focus="isDatepickerViewFocused = true"
    @blur="isDatepickerViewFocused = false"
    tabindex="0"
  >
    <table class="datepicker-table">
      <tbody class="datepicker-table-body">
        <tr v-for="(yearRow, key) in years" :key="key">
          <td
            class="datepicker-cell datepicker-large-cell datepicker-year-cell"
            :class="[
              year.current && 'current',
              year.focused && isDatepickerViewFocused && 'focused',
              !year.selectable && 'disabled',
              year.selected && 'selected',
            ]"
            v-for="(year, key) in yearRow"
            :key="key"
            :aria-label="year.label"
            @click.stop="handleYearClick(year.label)"
          >
            <div class="datepicker-cell-content datepicker-large-cell-content">
              {{ year.label }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject, ref, computed, onMounted } from "vue";

export default {
  name: "MDBDatepickerYearView",
  setup() {
    const activeDate = inject("activeDate");
    const selectedDate = inject("selectedDate");
    const focusedDate = inject("focusedDate");
    const changeActiveYear = inject("changeActiveYear");
    const changeView = inject("changeView");
    const today = inject("today");
    const nextMultiYearLabel = inject("nextMultiYearLabel");
    const prevMultiYearLabel = inject("prevMultiYearLabel");
    const switchToDayViewLabel = inject("switchToDayViewLabel");
    const startingYearKey = ref(-5);

    const minDate = inject("minDate");
    const maxDate = inject("maxDate");

    const startingYear = computed(() => {
      if (startingYearKey.value < 0) {
        return activeDate.value.subtract(-startingYearKey.value, "year");
      }
      return activeDate.value.add(startingYearKey.value, "year");
    });

    const years = computed(() => {
      const list = [];

      for (let i = 0; i < 6; i++) {
        list.push([]);
        const rowStartingDate = startingYear.value.add(i + i * 3, "year");
        for (let j = 0; j < 4; j++) {
          const colStartingDate = rowStartingDate.add(j, "year");
          list[i].push({
            current: colStartingDate.year() === today.year(),
            focused: colStartingDate.isSame(focusedDate.value, "year"),
            label: colStartingDate.year(),
            selectable: isYearSelectable(colStartingDate.year()),
            selected: colStartingDate.isSame(selectedDate.value, "year"),
          });
        }
      }

      return list;
    });

    const isYearSelectable = (year) => {
      if (minDate.value && maxDate.value) {
        return year >= minDate.value.year() && year <= maxDate.value.year();
      } else if (minDate.value) {
        return year >= minDate.value.year();
      } else if (maxDate.value) {
        return year <= maxDate.value.year();
      }

      return true;
    };

    const handleYearClick = (year) => {
      changeActiveYear(year);
      changeView("month");
    };

    // keyboard accessibility -------------------------------------
    const handleDecrementYear = inject("handleDecrementYear");
    const handleIncrementYear = inject("handleIncrementYear");
    const handleYearHome = inject("handleYearHome");
    const handleYearEnd = inject("handleYearEnd");
    const handleYearPageUp = inject("handleYearPageUp");
    const handleYearPageDown = inject("handleYearPageDown");
    const datepickerViewRef = ref(null);
    const isDatepickerViewFocused = ref(false);

    const handleEnter = () => {
      changeActiveYear(focusedDate.value.year());
      changeView("month");
    };
    const handleKeydown = (event) => {
      switch (event.key) {
        case "Home":
          handleYearHome();
          break;
        case "End":
          handleYearEnd();
          break;
        case "PageUp":
          handleYearPageUp();
          break;
        case "PageDown":
          handleYearPageDown();
          break;
        default:
          break;
      }
    };
    onMounted(() => {
      setTimeout(() => {
        datepickerViewRef.value.focus();
      }, 100);
    });

    return {
      years,
      startingYearKey,
      handleYearClick,
      nextMultiYearLabel,
      prevMultiYearLabel,
      switchToDayViewLabel,
      changeView,
      datepickerViewRef,
      handleDecrementYear,
      handleIncrementYear,
      handleEnter,
      handleKeydown,
      isDatepickerViewFocused,
    };
  },
};
</script>
