<template>
  <MDBModal
    v-bind="binds"
    :dialogClasses="dialogClass"
    :transform="dialogTransform"
    v-model="value"
    @show="emitEvent($event, 'show')"
    @shown="emitEvent($event, 'shown')"
    @hide="emitEvent($event, 'hide')"
    @hidden="emitEvent($event, 'hidden')"
  >
    <slot />
  </MDBModal>
</template>

<script>
import { computed, ref, watch, watchEffect } from "vue";
import MDBModal from "@/components/free/components/MDBModal";

export default {
  name: "MDBModalPro",
  components: { MDBModal },
  props: {
    tag: {
      type: String,
      default: "div",
    },
    modelValue: Boolean,
    size: {
      type: String,
      validator: (value) =>
        ["sm", "lg", "xl"].indexOf(value.toLowerCase()) > -1,
    },
    side: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
    },
    frame: {
      type: Boolean,
      default: false,
    },
    removeBackdrop: {
      type: Boolean,
      default: false,
    },
    staticBackdrop: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    bgSrc: {
      type: String,
      default: "",
    },
    direction: {
      type: String,
      default: "top",
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 400,
    },
    labelledby: String,
    fullscreen: {
      type: [Boolean, String],
      default: false,
    },
    animation: {
      type: Boolean,
      default: true,
    },
    keyboard: {
      type: Boolean,
      default: true,
    },
    focus: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["show", "shown", "hide", "hidden", "update:modelValue"],
  setup(props, { attrs, emit }) {
    const dialogClass = computed(() => {
      return `${props.side ? "modal-side" : null} ${
        props.frame ? "modal-frame" : null
      } ${props.position ? "modal-" + props.position : ""}`;
    });

    const value = ref(props.modelValue);
    watchEffect(() => (value.value = props.modelValue));

    watch(
      () => value.value,
      (cur) => {
        emit("update:modelValue", cur);
      }
    );

    const dialogTransform = computed(() => {
      if (props.direction === "right") {
        return "translate(25%,0)";
      } else if (props.direction === "bottom") {
        return "translate(0,25%)";
      } else if (props.direction === "left") {
        return "translate(-25%,0)";
      }
      return null;
    });

    const emitEvent = (e, eventName) => {
      emit(eventName, e);
    };

    const binds = {
      ...props,
      ...attrs,
    };

    return {
      dialogTransform,
      dialogClass,
      emitEvent,
      value,
      binds,
    };
  },
};
</script>
