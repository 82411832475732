<template>
  <component
    :is="tag"
    class="stepper-head"
    @click="setCurrentActive"
    :data-mdb-content="optional ? 'Optional' : null"
  >
    <span v-if="icon" class="stepper-head-icon">{{ icon }}</span>
    <span v-else class="stepper-head-icon"><slot name="icon" /></span>
    <span class="stepper-head-text"><slot /></span>
  </component>
</template>

<script>
import { inject, toRefs } from "vue";
import { validateStep } from "./stepper-validation";

export default {
  name: "MDBStepperStepHead",
  props: {
    tag: {
      type: String,
      default: "div",
    },
    icon: String,
  },
  setup(props) {
    const { activeStep, steps } = toRefs(inject("state"));
    const stepIndex = inject("stepIndex", null);
    const setActiveStep = inject("setActiveStep", null);
    const setPrevStep = inject("setPrevStep", null);
    const linear = inject("linear", null);
    const noEditable = inject("noEditable", null);
    const setStepAttribute = inject("setStepAttribute", null);
    const optional = inject("optional", null);
    const emitEvent = inject("emitEvent", null);

    const setCurrentActive = () => {
      if (linear && stepIndex > activeStep.value) {
        setStepAttribute(activeStep.value, "validated");
        const isValid = validateStep(
          activeStep.value,
          steps.value,
          stepIndex,
          linear
        );
        if (!isValid) {
          emitEvent(activeStep.value, "on-invalid");
          return;
        }
      }

      if (noEditable) {
        setStepAttribute(activeStep.value, "visited");
        const isVisited = steps.value[stepIndex].visited;

        if (isVisited) {
          return;
        }
      }
      stepIndex !== activeStep.value && setPrevStep(activeStep.value);
      setActiveStep(stepIndex);
    };

    return {
      optional,
      setCurrentActive,
      stepIndex,
      activeStep,
      props,
    };
  },
};
</script>
