<template>
  <div class="datepicker-header">
    <div class="datepicker-title">
      <span class="datepicker-title-text">{{ title }}</span>
    </div>
    <div class="datepicker-date">
      <span class="datepicker-date-text"
        >{{ headerWeekday }}, {{ headerMonth }} {{ headerMonthday }}</span
      >
    </div>
  </div>
</template>

<script>
import { inject, computed } from "vue";

export default {
  name: "MDBDatepickerHeader",
  setup() {
    const headerDate = inject("headerDate");
    const weekdaysShort = inject("weekdaysShort");
    const monthsShort = inject("monthsShort");
    const title = inject("title");

    const headerWeekday = computed(() => weekdaysShort[headerDate.value.day()]);
    const headerMonth = computed(() => monthsShort[headerDate.value.month()]);
    const headerMonthday = computed(() => headerDate.value.date());

    return {
      headerWeekday,
      headerMonth,
      headerMonthday,
      title,
    };
  },
};
</script>
