<template>
  <MDBInput
    ref="inputRef"
    :label="label"
    v-model="inputValue"
    :wrapperClass="inputWrapperClass"
    v-bind="$attrs"
    :id="uid"
    @input="handleInput"
    @click="handleSelfOpen"
    :invalidFeedback="invalidLabel"
    :validFeedback="validLabel"
    aria-haspopup="dialog"
    :isValid="valid"
    :isValidated="validated"
    :title="validationTitle"
  >
    <button
      v-if="icon"
      type="button"
      class="timepicker-toggle-button"
      aria-haspopup="dialog"
      ref="btnRef"
      @click="togglePicker"
    >
      <i :class="customIconClass" /></button
  ></MDBInput>
  <teleport to="body">
    <MDBTimepickerModal
      v-if="picker && !inline"
      ref="modalRef"
      @cancelModal="handleCancelClick"
    />
    <MDBTimepickerInline
      v-else-if="picker && inline"
      ref="modalRef"
      @cancelModal="handleCancelClick"
    />
  </teleport>
</template>

<script>
import {
  computed,
  onMounted,
  onUnmounted,
  provide,
  ref,
  toRefs,
  watch,
  watchEffect,
} from "vue";
import MDBInput from "@/components/free/forms/MDBInput";
import MDBTimepickerModal from "./MDBTimepickerModal";
import MDBTimepickerInline from "./MDBTimepickerInline";
import { getUID } from "@/components/utils/getUID";
import { on, off } from "@/components/utils/MDBEventHandlers";

export default {
  name: "MDBTimepicker",
  inheritAttrs: false,
  components: {
    MDBInput,
    MDBTimepickerModal,
    MDBTimepickerInline,
  },
  props: {
    modelValue: {
      type: [String, Date],
    },
    label: String,
    id: String,
    icon: {
      type: Boolean,
      default: true,
    },
    iconClass: {
      type: String,
      default: "far fa-clock fa-sm",
    },
    selfOpen: Boolean,
    inline: {
      type: Boolean,
      default: false,
    },
    hoursFormat: {
      type: Number,
      default: 12,
      validator: (value) => value === 24 || value === 12,
    },
    min: {
      type: [Number, String],
    },
    max: {
      type: [Number, String],
    },
    increment: {
      type: Number,
      default: 1,
      validator: (value) => {
        return [1, 5, 10, 15, 30].indexOf(value) > -1;
      },
    },
    amLabel: {
      type: String,
      default: "AM",
    },
    pmLabel: {
      type: String,
      default: "PM",
    },
    okLabel: {
      type: String,
      default: "ok",
    },
    clearLabel: {
      type: String,
      default: "clear",
    },
    cancelLabel: {
      type: String,
      default: "cancel",
    },
    invalidLabel: {
      type: String,
      default: "Invalid date format",
    },
    validLabel: {
      type: String,
    },
    isValid: Boolean,
    isValidated: Boolean,
  },
  emits: [
    "update:modelValue",
    "update:isValid",
    "update:isValidated",
    "open",
    "close",
  ],
  setup(props, { emit }) {
    // ------------- REFS -------------
    const inputRef = ref(null);
    const modalRef = ref(null);
    const btnRef = ref(null);
    const timepickerContainerRef = ref(null);

    const uid = props.id || getUID("MDBInput-");
    provide("inputId", uid);

    // ------------- STYLING -------------
    const inputWrapperClass = computed(() => {
      return "timepicker";
    });
    const customIconClass = computed(() => {
      return ["timepicker-icon", props.iconClass];
    });

    // ------------- PROPS VARIABLES -------------
    provide("hoursFormat", props.hoursFormat);
    provide("amLabel", props.amLabel);
    provide("pmLabel", props.pmLabel);
    provide("okLabel", props.okLabel);
    provide("clearLabel", props.clearLabel);
    provide("cancelLabel", props.cancelLabel);
    provide("inline", props.inline);
    provide("increment", props.increment);
    provide("min", props.min);
    provide("max", props.max);

    // ------------- STATE MANAGEMENT -------------
    const inputValue = ref(props.modelValue);

    const modelValueProp = toRefs(props).modelValue;
    watch(
      () => modelValueProp.value,
      (cur) => {
        if (cur && cur !== inputValue.value) {
          inputValue.value = cur;
          formatToAmPm(cur);
        }
      }
    );

    const hours = ref(12);
    const minutes = ref(0);
    const unitsMode = ref("hours");
    const dayTime = ref("am");

    const computedMinutes = computed(() => {
      return parseInt(minutes.value) < 10
        ? `0${parseInt(minutes.value)}`
        : `${minutes.value}`;
    });
    const computedHours = computed(() => {
      if (hours.value < 10) {
        return hours.value.toString().startsWith("0")
          ? hours.value.toString()
          : hours.value === 0
          ? `00`
          : `0${hours.value}`;
      } else {
        return hours.value === 24 ? "00" : hours.value;
      }
    });

    const computedMinValue = computed(() => {
      if (props.min === undefined) return 0;

      if (props.hoursFormat === 24) {
        return getMinutesValue(getTimeUnits(props.min));
      }
      // needed for situation when 12h format and no dayTime is passed
      // will create range for both am and pm values
      return getMinutesValue(getTimeUnits(props.min, dayTime.value));
    });
    const computedMaxValue = computed(() => {
      if (props.max === undefined) return 24 * 60;

      if (props.hoursFormat === 24) {
        return getMinutesValue(getTimeUnits(props.max));
      }
      // needed for situation when 12h format and no dayTime is passed
      // will create range for both am and pm values
      return getMinutesValue(getTimeUnits(props.max, dayTime.value));
    });

    const changeDayTime = (value) => {
      if (!value) return;
      dayTime.value = value.toLowerCase();
    };

    const chageUnitsMode = (value) => {
      if (!value) return;
      unitsMode.value = value.toLowerCase();
    };

    const updateHoursValue = (value) => {
      hours.value = value;
    };

    const incrementHoursValue = (
      delta,
      currentValue = hours.value,
      recursion
    ) => {
      if (unitsMode.value !== "hours") {
        chageUnitsMode("hours");
      }

      const isCurrentInRange = allowedHours(parseInt(currentValue));

      const incrementedValue = parseInt(currentValue) + delta;
      let result =
        incrementedValue <= 0 || incrementedValue === 24
          ? props.hoursFormat
          : incrementedValue > props.hoursFormat
          ? 1
          : incrementedValue;

      const isIncrementedInRange = allowedHours(result);

      if (isCurrentInRange && !isIncrementedInRange) {
        // prevent from changing to not valid hour
        result = incrementHoursValue(delta, result, true);
      } else if (!isCurrentInRange && !isIncrementedInRange) {
        // if value is between not allowed values
        // e.g after changing dayTime manually inputted value
        if (props.hoursFormat === 24) {
          // find first allowed value in delta direction
          result = incrementHoursValue(delta, result, true);
        } else {
          const activeValues = document.querySelectorAll(
            ".timepicker-time-tips-hours:not(.disabled)"
          );

          const min = props.min && getTimeUnits(props.min);
          const max = props.max && getTimeUnits(props.max);

          if (!activeValues.length) {
            if (delta > 0) {
              result = min ? min.hours : 1;
              minutes.value = allowedMinutes(minutes.value)
                ? minutes.value
                : min
                ? min.minutes
                : max
                ? max.minutes
                : 0;
              changeDayTime(
                (min && min.ampm) || (max && max.ampm) || dayTime.value
              );
            } else if (delta < 0) {
              result = max ? max.hours : props.hoursFormat;
              minutes.value = allowedMinutes(minutes.value)
                ? minutes.value
                : max
                ? max.minutes
                : min
                ? min.minutes
                : 0;
              changeDayTime(
                (max && max.ampm) || (min && min.ampm) || dayTime.value
              );
            }
          } else {
            result = incrementHoursValue(delta, result, true);
          }
        }
      }

      if (recursion) {
        return result;
      }

      hours.value = result;
    };

    const updateMinutesValue = (value) => {
      minutes.value = value;
    };

    const incrementMinutesValue = (
      delta,
      currentValue = minutes.value,
      recursion
    ) => {
      if (unitsMode.value !== "minutes") {
        chageUnitsMode("minutes");
      }

      const isCurrentInRange = allowedMinutes(parseInt(currentValue));

      const incrementedValue = parseInt(currentValue) + delta;
      let result =
        incrementedValue < 0
          ? 60 - props.increment
          : incrementedValue > 59
          ? 0
          : incrementedValue;

      const isIncrementedInRange = allowedMinutes(result);

      if (isCurrentInRange && !isIncrementedInRange) {
        result = incrementMinutesValue(delta, result, true);
      } else if (!isCurrentInRange && !isIncrementedInRange) {
        // if value is between not allowed values
        // e.g after changing dayTime manually inputted value
        const activeValues = document.querySelectorAll(
          ".timepicker-time-tips-minutes:not(.disabled)"
        );

        const min = props.min && getTimeUnits(props.min);
        const max = props.max && getTimeUnits(props.max);

        if (!activeValues.length) {
          if (delta > 0) {
            result = min && min.minutes ? min.minutes : 0;
            hours.value = allowedHours(hours.value)
              ? hours.value
              : min
              ? min.hours
              : 1;
            changeDayTime(
              (min && min.ampm) || (max && max.ampm) || dayTime.value
            );
          } else if (delta < 0) {
            result =
              max && max.minutes
                ? max.minutes
                : min && min.minutes
                ? min.minutes
                : 0;
            hours.value = allowedHours(hours.value)
              ? hours.value
              : max
              ? max.hours
              : props.hoursFormat;
            changeDayTime(
              (max && max.ampm) || (min && min.ampm) || dayTime.value
            );
          }
        } else {
          result = incrementMinutesValue(delta, result, true);
        }
      }

      if (recursion) {
        return result;
      }

      minutes.value = result;
    };

    const handleOkClick = () => {
      if (!allowedHours(hours.value) || !allowedMinutes(minutes.value)) {
        return;
      }
      inputValue.value =
        props.hoursFormat === 12
          ? `${computedHours.value}:${
              computedMinutes.value
            } ${dayTime.value.toUpperCase()}`
          : `${computedHours.value}:${computedMinutes.value}`;

      closeModal();
      unitsMode.value = "hours";
    };

    const handleClearClick = () => {
      inputValue.value = "";
      hours.value = 12;
      minutes.value = 0;
      unitsMode.value = "hours";
      dayTime.value = "am";
    };

    const handleCancelClick = () => {
      if (!inputValue.value) {
        handleClearClick();
      }
      closeModal();
      formatToAmPm(inputValue.value);
    };

    const handleInput = (e) => {
      let { value } = e.target;
      if (checkFormatValidity(value.trimEnd())) {
        formatToAmPm(value);
      }
    };

    watch(
      () => inputValue.value,
      (cur) => {
        checkFormatValidity(cur.trimEnd(), true);
        emit("update:modelValue", cur);
      }
    );

    const formatToAmPm = (date) => {
      if (date === "") return;
      let _hours;
      let _minutes;
      let _amOrPm;

      if (isValidDate(date)) {
        _hours = date.getHours();
        _minutes = date.getMinutes();
        _hours %= 12;
        _hours = _hours || 12;
        _amOrPm = _hours >= 12 ? "PM" : "AM";
      } else {
        const {
          hours: inputHours,
          minutes: inputMinutes,
          ampm = null,
        } = getTimeUnits(date);

        if (ampm) {
          _amOrPm = ampm;
        }

        _hours = inputHours;
        _minutes = inputMinutes;
      }

      hours.value = parseInt(_hours) < 10 ? `0${_hours}` : _hours;
      minutes.value = parseInt(_minutes) < 10 ? `0${_minutes}` : _minutes;

      changeDayTime(_amOrPm);
      chageUnitsMode("hours");

      return `${hours.value}:${minutes.value} ${
        props.hoursFormat === 12 ? dayTime.value : ""
      }`;
    };

    const getTimeUnits = (date, computedDayTime) => {
      let [time, ampm] = date.split(" ");
      let [hours, minutes] = time.split(":");

      if (props.hoursFormat === 12 && !ampm && computedDayTime) {
        ampm = computedDayTime;
      }

      hours = parseInt(hours);
      minutes = parseInt(minutes);
      return { hours, minutes, ampm };
    };

    provide("hours", computedHours);
    provide("minutes", computedMinutes);
    provide("unitsMode", unitsMode);
    provide("dayTime", dayTime);

    provide("changeDayTime", changeDayTime);
    provide("chageUnitsMode", chageUnitsMode);
    provide("updateHoursValue", updateHoursValue);
    provide("incrementHoursValue", incrementHoursValue);
    provide("updateMinutesValue", updateMinutesValue);
    provide("incrementMinutesValue", incrementMinutesValue);
    provide("handleOkClick", handleOkClick);
    provide("handleClearClick", handleClearClick);
    provide("handleCancelClick", handleCancelClick);

    // ------------- KEYBOARD NAVIGATION -------------
    const handleKeydown = (e) => {
      if (!isModalOpen.value) return;
      const { key } = e;

      if (key === "Escape") {
        closeModal();
      }
      if (key !== "Tab") {
        e.preventDefault();
      } else if (key === "Tab" && e.target === document.body) {
        // will reinitial lost focus trap
        // arrow navigation causes focus trap to be lost and document.body to be focused
        modalRef.value.modalRef.focus();
      }

      switch (key) {
        case "ArrowUp":
          handleChangeTime(1);
          break;
        case "ArrowDown":
          handleChangeTime(-1);
          break;
        case "ArrowLeft":
          handleChangeClockCircle("left");
          break;
        case "ArrowRight":
          handleChangeClockCircle("right");
          break;
        default:
          break;
      }
    };

    const handleChangeTime = (multiplier) => {
      if (unitsMode.value === "hours") {
        incrementHoursValue(1 * multiplier);
      } else {
        incrementMinutesValue(props.increment * multiplier);
      }
    };
    const handleChangeClockCircle = (side) => {
      if (props.hoursFormat === 12 || unitsMode.value === "minutes") {
        return;
      }

      if (side === "left" && hours.value < 12) {
        hours.value += 12;
      }

      if (side === "right" && hours.value > 12) {
        hours.value -= 12;
      }
    };

    const blurInput = () => {
      inputRef.value.inputRef.focus();
      inputRef.value.inputRef.blur();
    };

    const focusToggler = () => {
      if (btnRef.value) {
        btnRef.value.focus();
      } else {
        inputRef.value.inputRef.focus();
      }
    };

    // ------------- VALIDATION -------------
    const valid = ref(props.isValid);
    const validated = ref(props.isValidated);

    watchEffect(() => {
      valid.value = props.isValid;
    });
    watchEffect(() => {
      validated.value = props.isValidated;
    });

    const validationTitle = computed(() => {
      return props.hoursFormat === 12
        ? "Date should match HH:MM 12-hour format, optional leading 0, mandatory meridiems (AM/PM)"
        : "Date should match HH:MM 24-hour format, optional leading 0";
    });
    const validationPattern = computed(() => {
      return props.hoursFormat === 12
        ? // eslint-disable-next-line no-useless-escape
          /\b((1[0-2]|0?[1-9]):([0-5][0-9])\s?([AaPp][Mm]))/
        : /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    });

    const checkFormatValidity = (date, clear) => {
      if (!validationPattern.value.test(date)) {
        validated.value = true;
        valid.value = false;

        emit("update:isValid", false);
        emit("update:isValidated", true);

        return false;
      } else {
        // if clear - we do not want to show that is ok - just clear previous state
        validated.value = props.validLabel ? true : clear ? false : true;
        valid.value = true;

        emit("update:isValid", true);
        emit("update:isValidated", validated.value);

        return true;
      }
    };

    const isValidDate = (date) => {
      return (
        date &&
        Object.prototype.toString.call(date) === "[object Date]" &&
        !isNaN(date)
      );
    };

    const getMinutesValue = ({ hours, minutes, ampm }) => {
      ampm = ampm ? ampm.toLowerCase() : ampm;

      const addition = props.hoursFormat === 12 && ampm === "pm" ? 12 * 60 : 0;

      minutes = minutes || 0;

      return hours * 60 + minutes + addition;
    };

    const allowedHours = (value) => {
      if (!props.max && !props.min) return value;

      let _max = computedMaxValue.value;
      let _min = computedMinValue.value;

      if (_max && _min && _max < _min) {
        [_max, _min] = [_min, _max];
      }

      _min = _min - (_min % 60);
      const addition =
        props.hoursFormat === 12 && dayTime.value.toLowerCase() === "pm"
          ? 12 * 60
          : 0;
      const selectedHourValue = value * 60 + addition;

      let boundryCondition = true;

      if (props.min)
        boundryCondition = boundryCondition && selectedHourValue >= _min;
      if (props.max)
        boundryCondition = boundryCondition && selectedHourValue <= _max;

      return boundryCondition;
    };

    const allowedMinutes = (value) => {
      if (!props.min && !props.max) {
        return value / props.increment === Math.floor(value / props.increment);
      }

      let _max = computedMaxValue.value;
      let _min = computedMinValue.value;

      if (_max && _min && _max < _min) {
        [_max, _min] = [_min, _max];
      }

      const addition =
        props.hoursFormat === 12 && dayTime.value.toLowerCase() === "pm"
          ? 12 * 60
          : 0;

      const hourValue = hours.value * 60 + addition;

      let boundryCondition = true;

      if (props.min)
        boundryCondition = boundryCondition && hourValue + value >= _min;
      if (props.max)
        boundryCondition = boundryCondition && hourValue + value <= _max;

      return (
        boundryCondition &&
        value / props.increment === Math.floor(value / props.increment)
      );
    };

    provide("allowedHours", allowedHours);
    provide("allowedMinutes", allowedMinutes);

    // ------------- OPENING/CLOSING METHODS -------------
    const picker = ref(false);
    const isModalOpen = ref(picker.value);
    const shouldClockAnimateOnShow = ref(true);
    const setClockAnimateOnShow = (value) => {
      shouldClockAnimateOnShow.value = value;
    };

    const handleSelfOpen = () =>
      !props.icon && props.selfOpen && togglePicker();

    const togglePicker = () => {
      if (!picker.value) {
        picker.value = true;
        isModalOpen.value = true;
        emit("open");
        blurInput();
      } else {
        closeModal();
      }
    };
    const closeModal = () => {
      isModalOpen.value = false;
      emit("close");
      focusToggler();
      setClockAnimateOnShow(true);
    };
    const closePicker = () => {
      picker.value = false;
    };
    provide("isModalOpen", isModalOpen);
    provide("closeModal", closeModal);
    provide("closePicker", closePicker);
    provide("shouldClockAnimateOnShow", shouldClockAnimateOnShow);
    provide("setClockAnimateOnShow", setClockAnimateOnShow);

    // ------------- LIFECYCLE METHODS -------------

    onMounted(() => {
      if (props.modelValue) {
        inputValue.value = formatToAmPm(props.modelValue);
      }

      on(window, "keydown", handleKeydown);
    });

    onUnmounted(() => {
      off(window, "keydown", handleKeydown);
    });

    return {
      handleCancelClick,
      uid,
      togglePicker,
      timepickerContainerRef,
      inputWrapperClass,
      customIconClass,
      inputRef,
      modalRef,
      btnRef,
      inputValue,
      handleInput,
      handleSelfOpen,
      validationTitle,
      valid,
      validated,
      picker,
      props,
    };
  },
};
</script>
