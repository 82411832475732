<template>
  <component
    :is="tag"
    class="btn btn-primary popconfirm-toggle"
    ref="triggerEl"
    v-bind="$attrs"
    @click="open"
  >
    <slot />
  </component>
  <div
    v-if="modal && isActive"
    class="popconfirm-backdrop"
    @click="close"
    :id="uid"
    ref="modalRef"
  >
    <div :class="[popconfirmContainerClassName]">
      <div class="popconfirm show">
        <div class="popconfirm-message">
          <span class="popconfirm-icon-container" v-if="icon"
            ><i :class="icon"
          /></span>
          <span class="popconfirm-message-text">{{
            !$slots.message ? message : null
          }}</span>
          <slot v-if="$slots.message" name="message" />
        </div>
        <div class="popconfirm-buttons-container">
          <MDBBtn
            color="flat"
            size="sm"
            :aria-label="cancelLabel"
            @click="handleButtonClick('cancel')"
            >{{ cancelText }}</MDBBtn
          >
          <MDBBtn
            color="primary"
            size="sm"
            :aria-label="confirmLabel"
            @click="handleButtonClick('confirm')"
            >{{ confirmText }}</MDBBtn
          >
        </div>
      </div>
    </div>
  </div>
  <teleport to="body" v-else>
    <div
      :class="popconfirmContainerClassName"
      ref="popperEl"
      v-if="isActive"
      v-mdb-click-outside="clickOutside"
      :id="uid"
    >
      <div :class="popconfirmClassName">
        <div class="popconfirm-message">
          <span class="popconfirm-icon-container" v-if="icon"
            ><i :class="icon"
          /></span>
          <span class="popconfirm-message-text">{{
            !$slots.message ? message : null
          }}</span>
          <slot v-if="$slots.message" name="message" />
        </div>
        <div class="popconfirm-buttons-container">
          <MDBBtn
            color="flat"
            size="sm"
            :aria-label="cancelLabel"
            @click="handleButtonClick('cancel')"
            >{{ cancelText }}</MDBBtn
          >
          <MDBBtn
            color="primary"
            size="sm"
            :aria-label="confirmLabel"
            @click="handleButtonClick('confirm')"
            >{{ confirmText }}</MDBBtn
          >
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { computed, nextTick, onUnmounted, ref, watchEffect } from "vue";
import MDBPopper from "@/components/utils/MDBPopper.js";
import MDBBtn from "@/components/free/components/MDBBtn";
import mdbClickOutside from "@/directives/free/mdbClickOutside.js";
import { getUID } from "@/components/utils/getUID";
import { on, off } from "@/components/utils/MDBEventHandlers";

export default {
  name: "MDBPopconfirm",
  components: {
    MDBBtn,
  },
  props: {
    tag: {
      type: String,
      default: "button",
    },
    message: {
      type: String,
      default: "Are you sure?",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    cancelLabel: {
      type: String,
      default: "Cancel",
    },
    confirmText: {
      type: String,
      default: "Ok",
    },
    confirmLabel: {
      type: String,
      default: "Confirm",
    },
    icon: {
      type: String,
      default: "",
    },
    modal: Boolean,
    offset: {
      type: String,
      default: "0, 5",
    },
    position: {
      type: String,
      default: "bottom",
    },
  },
  directives: {
    mdbClickOutside,
  },
  emits: ["confirm", "cancel"],
  setup(props, { emit }) {
    const isRTL = document.documentElement.dir === "rtl";

    const popconfirmContainerClassName = computed(() => {
      return [
        props.modal ? "popconfirm-modal" : "popconfirm-popover",
        "shadow-4",
      ];
    });

    const popconfirmClassName = computed(() => {
      return ["popconfirm", "fade", show.value];
    });

    const uid = getUID("MDBPopconfirm-");

    const show = ref("");

    const translatePositionValue = () => {
      switch (props.position) {
        case "top left":
          return isRTL ? "top-start" : "top-end";
        case "top":
          return "top";
        case "top right":
          return isRTL ? "top-end" : "top-start";
        case "bottom left":
          return isRTL ? "bottom-start" : "bottom-end";
        case "bottom":
          return "bottom";
        case "bottom right":
          return isRTL ? "bottom-end" : "bottom-start";
        case "left":
          return isRTL ? "right" : "left";
        case "left top":
          return isRTL ? "right-end" : "left-end";
        case "left bottom":
          return isRTL ? "right-start" : "left-start";
        case "right":
          return isRTL ? "left" : "right";
        case "right top":
          return isRTL ? "left-end" : "right-end";
        case "right bottom":
          return isRTL ? "left-start" : "right-start";
        case undefined:
          return "bottom";
        default:
          return props.position;
      }
    };

    const modalRef = ref(null);

    const {
      setPopper,
      isPopperActive,
      openPopper,
      closePopper,
      getPopperOffset,
    } = MDBPopper();
    const triggerEl = ref("triggerEl");
    const popperEl = ref("popperEl");

    const popperSetup = () => {
      const placement = translatePositionValue();

      const config = {
        placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: getPopperOffset(props.offset, modalRef.value),
            },
          },
        ],
      };

      setPopper(triggerEl.value, popperEl.value, config);
    };

    const isActive = ref(false);

    watchEffect(() => {
      if (isActive.value && !props.modal) {
        nextTick(() => {
          popperSetup();

          openPopper();
          setTimeout(() => {
            show.value = "show";
          }, 0);

          on(window, "keydown", handleEscapeKey);
        });
      } else {
        if (!isPopperActive.value) {
          return;
        }
        show.value = "";
        setTimeout(closePopper, 150);
        off(window, "keydown", handleEscapeKey);
      }
    });

    const open = () => {
      if (!isActive.value) {
        isActive.value = true;
      }
    };

    const close = () => {
      isActive.value = false;
    };

    const clickOutside = (event) => {
      const isButton = triggerEl.value === event.target;
      const isPopover = popperEl.value === event.target;
      const isPopoverContent =
        popperEl.value && popperEl.value.contains(event.target);

      if (!isButton && !isPopover && !isPopoverContent) {
        close();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        close();
      }
    };

    const handleButtonClick = (event) => {
      emit(event);
      close();
    };

    onUnmounted(() => {
      off(window, "keydown", handleEscapeKey);
    });

    return {
      uid,
      popconfirmClassName,
      popconfirmContainerClassName,
      isActive,
      triggerEl,
      popperEl,
      modalRef,
      open,
      close,
      clickOutside,
      handleButtonClick,
    };
  },
};
</script>
