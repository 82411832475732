<template>
  <component :is="tag" :class="className" ref="stepRef">
    <slot />
  </component>
</template>

<script>
import {
  computed,
  onMounted,
  ref,
  inject,
  watch,
  provide,
  toRefs,
  onUnmounted,
  nextTick,
} from "vue";
import { on, off } from "../../../utils/MDBEventHandlers";

export default {
  name: "MDBStepperStep",
  props: {
    tag: {
      type: String,
      default: "li",
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const stepRef = ref(null);

    const className = computed(() => {
      return [
        "stepper-step",
        isActive.value && "stepper-active",
        isCompleted.value && "stepper-completed",
        isValidated.value && "was-validated",
        isVisited.value && "stepper-disabled",
      ];
    });

    // ---------- Managing Current Step -------------
    const { steps, activeStep } = toRefs(inject("state", null));
    const addStep = inject("addStep", null);
    const addStepRef = inject("addStepRef", null);
    const stepIndex = addStep(stepRef.value);
    provide("stepIndex", stepIndex);

    const isActive = computed(() => {
      return activeStep.value === stepIndex;
    });
    const isCompleted = computed(() => {
      return steps.value[stepIndex].completed;
    });
    const isVisited = computed(() => {
      return steps.value[stepIndex].visited;
    });

    provide("optional", props.optional);

    watch(
      () => isActive.value,
      (cur) => {
        if (cur) {
          nextTick(() => setHeight());
        }
      }
    );

    // ---------- Validation -------------
    const isValidated = computed(() => {
      return steps.value[stepIndex].validated;
    });

    watch(
      () => isValidated.value,
      (cur) => {
        if (cur) {
          setTimeout(setHeight, 200);
        }
      }
    );

    // ---------- Stepper Height -------------

    const vertical = inject("vertical", false);
    const mobile = inject("mobile", false);

    watch(
      () => mobile.value,
      (cur) => {
        if (cur) {
          setTimeout(setHeight, 250);
        }
      }
    );
    watch(
      () => vertical.value,
      (cur) => {
        if (cur) {
          setHeight();
        }
      }
    );

    const getParentElement = (element, selector) => {
      let isParentOk = false;
      let checkElement = element;
      while (!isParentOk && checkElement !== document.body) {
        const parentElement = checkElement.parentNode;
        if (parentElement.classList.contains(selector)) {
          isParentOk = true;
        }
        checkElement = parentElement;
      }

      return checkElement;
    };

    const setStepperHeight = inject("setStepperHeight");
    const setHeight = () => {
      const element = stepRef.value;
      const stepContent = element.querySelector(".stepper-content");
      const stepFooter = getParentElement(element, "stepper").querySelector(
        `.stepper-mobile-footer`
      );
      const contentStyle = stepContent && getComputedStyle(stepContent);
      const footerStyle = stepFooter ? getComputedStyle(stepFooter) : "";
      let stepHead;

      if (mobile.value) {
        stepHead = getParentElement(element, "stepper").querySelector(
          ".stepper-mobile-head"
        );
      } else {
        stepHead = element.querySelector(".stepper-head");
      }

      const headStyle = stepHead && getComputedStyle(stepHead);

      if (stepContent && contentStyle) {
        const stepContentHeight =
          stepContent.offsetHeight +
          parseFloat(contentStyle.marginTop) +
          parseFloat(contentStyle.marginBottom);

        const stepHeadHeight =
          stepHead.offsetHeight +
          parseFloat(headStyle.marginTop) +
          parseFloat(headStyle.marginBottom);

        const stepFooterHeight = footerStyle
          ? stepFooter.offsetHeight +
            parseFloat(footerStyle.marginTop) +
            parseFloat(footerStyle.marginBottom)
          : 0;

        setStepperHeight(stepHeadHeight + stepContentHeight + stepFooterHeight);
      }
    };

    // ---------- Lifecycle Hooks -------------
    onMounted(() => {
      addStepRef(stepRef.value, stepIndex);

      if (activeStep.value === stepIndex && !vertical.value) {
        setHeight();
      }

      on(window, "resize", setHeight);
    });

    onUnmounted(() => {
      off(window, "resize", setHeight);
    });

    return {
      stepRef,
      className,
      props,
      setHeight,
    };
  },
};
</script>
