<template>
  <div class="form-outline datepicker" ref="datepickerRef">
    <MDBInput
      v-bind="$attrs"
      v-model="inputValue"
      :wrap="false"
      aria-haspopup="dialog"
      @click.stop="handleInputToggle"
      @keydown.enter="handleInputToggle"
    />
    <button
      v-if="toggleButton"
      type="button"
      class="datepicker-toggle-button"
      aria-haspopup="dialog"
      ref="toggleButtonRef"
      @click.stop="isActive = !isActive"
      v-html="toggleIcon"
    ></button>

    <teleport v-if="inline" to="body">
      <transition name="datepicker-fade">
        <div
          v-if="isActive"
          ref="datepickerInlineRef"
          class="datepicker-dropdown-container"
          v-mdb-click-outside="handleClickOutside"
        >
          <MDBDatepickerMain inline />
        </div>
      </transition>
    </teleport>

    <MDBDatepickerModal v-else v-model="isActive" />
  </div>
</template>

<script>
import {
  ref,
  computed,
  provide,
  onMounted,
  onUnmounted,
  watch,
  nextTick,
  toRefs,
} from "vue";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isoWeek from "dayjs/plugin/isoWeek";
import MDBDatepickerModal from "./MDBDatepickerModal";
import MDBDatepickerMain from "./MDBDatepickerMain";
import MDBInput from "@/components/free/forms/MDBInput.vue";
import MDBPopper from "@/components/utils/MDBPopper.js";
import mdbClickOutside from "@/directives/free/mdbClickOutside";
import { on, off } from "@/components/utils/MDBEventHandlers";

dayjs.extend(isToday);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);

export default {
  name: "MDBDatepicker",
  inheritAttrs: false,
  components: {
    MDBInput,
    MDBDatepickerModal,
    MDBDatepickerMain,
  },
  directives: {
    mdbClickOutside,
  },
  props: {
    cancelBtnLabel: {
      type: String,
      default: "Cancel selection",
    },
    cancelBtnText: {
      type: String,
      default: "Cancel",
    },
    clearBtnLabel: {
      type: String,
      default: "Clear selection",
    },
    clearBtnText: {
      type: String,
      default: "Clear",
    },
    filter: {
      type: Function,
      default: () => false,
    },
    format: {
      type: String,
      default: "DD/MM/YYYY",
    },
    inline: Boolean,
    inputToggle: Boolean,
    max: String,
    min: String,
    modelValue: String,
    monthsFull: {
      type: Array,
      default: () => [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
    monthsShort: {
      type: Array,
      default: () => [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    nextMonthLabel: {
      type: String,
      default: "Next month",
    },
    nextMultiYearLabel: {
      type: String,
      default: "Next 24 years",
    },
    nextYearLabel: {
      type: String,
      default: "Next year",
    },
    okBtnLabel: {
      type: String,
      default: "Confirm selection",
    },
    okBtnText: {
      type: String,
      default: "Ok",
    },
    prevMonthLabel: {
      type: String,
      default: "Previous month",
    },
    prevMultiYearLabel: {
      type: String,
      default: "Previous 24 years",
    },
    prevYearabel: {
      type: String,
      default: "Previous year",
    },
    startDate: String,
    startDay: {
      type: Number,
      default: 0,
    },
    switchToMultiYearViewLabel: {
      type: String,
      default: "Choose year and month",
    },
    switchToDayViewLabel: {
      type: String,
      default: "Choose date",
    },
    title: {
      type: String,
      default: "Select date",
    },
    toggleButton: {
      type: Boolean,
      default: true,
    },
    toggleIcon: {
      type: String,
      default: "<i class='far fa-calendar datepicker-toggle-icon'></i>",
    },
    view: {
      type: String,
      default: "day",
    },
    weekdaysFull: {
      type: Array,
      default: () => [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    },
    weekdaysNarrow: {
      type: Array,
      default: () => ["S", "M", "T", "W", "T", "F", "S"],
    },
    weekdaysShort: {
      type: Array,
      default: () => ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    },
  },
  emits: ["update:modelValue", "close", "open"],
  setup(props, { emit }) {
    const inputValue = ref(props.modelValue || "");
    const isActive = ref(false);
    const view = ref(props.view);
    const activeDate = ref(
      dayjs(inputValue.value, props.format).isValid()
        ? dayjs(inputValue.value, props.format)
        : dayjs(props.startDate)
    );
    const headerDate = ref(activeDate.value);
    const selectedDate = ref(
      dayjs(inputValue.value, props.format).isValid() ? headerDate.value : null
    );
    const focusedDate = ref(selectedDate.value || activeDate.value);
    const today = dayjs();
    const minDate = ref(props.min ? dayjs(props.min, props.format) : "");
    const maxDate = ref(props.max ? dayjs(props.max, props.format) : "");

    watch(
      () => props.min,
      (cur) => {
        minDate.value = cur ? dayjs(cur, props.format) : "";
      }
    );
    watch(
      () => props.max,
      (cur) => {
        maxDate.value = cur ? dayjs(cur, props.format) : "";
      }
    );

    const goToPrevMonth = () => {
      activeDate.value = activeDate.value.subtract(1, "month");
      focusedDate.value = activeDate.value;
    };
    const goToNextMonth = () => {
      activeDate.value = activeDate.value.add(1, "month");
      focusedDate.value = activeDate.value;
    };
    const selectDay = (day) => {
      headerDate.value = dayjs(day.dayJS);
      selectedDate.value = headerDate.value;
      focusedDate.value = headerDate.value;

      if (props.inline) {
        ok();
      }
    };
    const changeActiveYear = (year) => {
      headerDate.value = headerDate.value.year(year);
      activeDate.value = headerDate.value;
    };
    const changeActiveMonth = (month) => {
      headerDate.value = headerDate.value.month(month);
      activeDate.value = headerDate.value;
    };
    const changeView = (newView) => {
      view.value = newView;
    };

    const ok = () => {
      updateInputValue();
      isActive.value = false;
    };
    const cancel = () => {
      isActive.value = false;
    };
    const clear = () => {
      selectedDate.value = null;
      updateInputValue();
    };

    const updateInputValue = () => {
      if (selectedDate.value) {
        inputValue.value = selectedDate.value.format(props.format);
      } else {
        inputValue.value = "";
      }

      emit("update:modelValue", inputValue.value);
    };
    const handleClickOutside = () => {
      if (props.inline) {
        isActive.value = false;
      }
    };
    const handleInputToggle = () => {
      if (props.inputToggle) {
        isActive.value = !isActive.value;
      }
    };

    // popper ------------------------------------
    const datepickerRef = ref(null);
    const datepickerInlineRef = ref(null);
    const toggleButtonRef = ref(null);
    if (props.inline) {
      const { setPopper, openPopper, closePopper } = MDBPopper();

      watch(
        () => isActive.value,
        (isActive) => {
          if (isActive) {
            nextTick(() => {
              setPopper(datepickerRef.value, datepickerInlineRef.value, {
                placement: "bottom-start",
                eventsEnabled: true,
              });
              openPopper();
              emit("open");
            });
          } else {
            closePopper();
            focusToggler();
            setTimeout(() => {
              setDefaults();
              emit("close");
            }, 400);
          }
        }
      );
    } else {
      watch(
        () => isActive.value,
        (isActive) => {
          if (isActive) {
            emit("open");
          } else {
            focusToggler();
            setTimeout(() => {
              setDefaults();
              emit("close");
            }, 400);
          }
        }
      );
    }

    const focusToggler = () => {
      if (toggleButtonRef.value) {
        toggleButtonRef.value.focus();
      } else {
        datepickerRef.value.querySelector("input").focus();
      }
    };

    const setDefaults = () => {
      activeDate.value = selectedDate.value ? selectedDate.value : dayjs();
      focusedDate.value = activeDate.value;
      view.value = "day";
    };

    const activeDateObject = computed(() => activeDate.value);
    const headerDateObject = computed(() => headerDate.value);
    const selectedDateObject = computed(() => selectedDate.value);
    const focusedDateObject = computed(() => focusedDate.value);

    // monday first
    let weekdaysNarrow = [...props.weekdaysNarrow];
    let weekdaysFull = [...props.weekdaysFull];
    if (props.startDay === 1) {
      weekdaysNarrow.push(weekdaysNarrow.shift());
      weekdaysFull.push(weekdaysFull.shift());
    }

    provide("activeDate", activeDateObject);
    provide("headerDate", headerDateObject);
    provide("selectedDate", selectedDateObject);
    provide("focusedDate", focusedDateObject);
    provide("inputValue", inputValue);
    provide("monthsFull", props.monthsFull);
    provide("monthsShort", props.monthsShort);
    provide("title", props.title);
    provide("weekdaysFull", weekdaysFull);
    provide("weekdaysNarrow", weekdaysNarrow);
    provide("weekdaysShort", props.weekdaysShort);
    provide("goToPrevMonth", goToPrevMonth);
    provide("goToNextMonth", goToNextMonth);
    provide("selectDay", selectDay);
    provide("changeActiveYear", changeActiveYear);
    provide("changeActiveMonth", changeActiveMonth);
    provide("ok", ok);
    provide("cancel", cancel);
    provide("clear", clear);
    provide("view", view);
    provide("today", today);
    provide("changeView", changeView);
    provide("cancelBtnText", props.cancelBtnText);
    provide("clearBtnText", props.clearBtnText);
    provide("okBtnText", props.okBtnText);
    provide("minDate", minDate);
    provide("maxDate", maxDate);
    provide("filter", props.filter);
    provide("startDay", props.startDay);

    provide("cancelBtnLabel", props.cancelBtnLabel);
    provide("clearBtnLabel", props.clearBtnLabel);
    provide("okBtnLabel", props.okBtnLabel);
    provide("nextMonthLabel", props.nextMonthLabel);
    provide("prevMonthLabel", props.prevMonthLabel);
    provide("nextYearLabel", props.nextYearLabel);
    provide("prevYearLabel", props.prevYearLabel);
    provide("nextMultiYearLabel", props.nextMultiYearLabel);
    provide("prevMultiYearLabel", props.prevMultiYearLabel);
    provide("switchToMultiYearViewLabel", props.switchToMultiYearViewLabel);
    provide("switchToDayViewLabel", props.switchToDayViewLabel);

    // keyboard accessibility -------------------------------------
    const handleDayUp = () => {
      const prevWeek = focusedDate.value.subtract(1, "week");
      if (focusedDate.value.month() !== prevWeek.month()) {
        activeDate.value = prevWeek;
      }

      focusedDate.value = prevWeek;
    };
    const handleDayDown = () => {
      const nextWeek = focusedDate.value.add(1, "week");
      if (focusedDate.value.month() !== nextWeek.month()) {
        activeDate.value = nextWeek;
      }

      focusedDate.value = nextWeek;
    };
    const handleDayLeft = () => {
      const prevDay = focusedDate.value.subtract(1, "day");
      if (focusedDate.value.date() === 1) {
        activeDate.value = prevDay;
      }

      focusedDate.value = prevDay;
    };
    const handleDayRight = () => {
      const nextDay = focusedDate.value.add(1, "day");
      if (focusedDate.value.date() === dayjs(focusedDate.value).daysInMonth()) {
        activeDate.value = nextDay;
      }

      focusedDate.value = focusedDate.value.add(1, "day");
    };
    const handleDayHome = () => {
      focusedDate.value = focusedDate.value.startOf("month");
    };
    const handleDayEnd = () => {
      focusedDate.value = focusedDate.value.endOf("month");
    };
    const handleDayPageUp = () => {
      focusedDate.value = focusedDate.value.subtract(1, "month");
      activeDate.value = focusedDate.value;
    };
    const handleDayPageDown = () => {
      focusedDate.value = focusedDate.value.add(1, "month");
      activeDate.value = focusedDate.value;
    };
    const handleDecrementYear = (key) => {
      const decrementYear = focusedDate.value.subtract(key, "year");
      const startingYearRange = activeDate.value.year() - 5;
      if (decrementYear.year() < startingYearRange) {
        activeDate.value = activeDate.value.subtract(24, "year");
      }

      focusedDate.value = decrementYear;
    };
    const handleIncrementYear = (key) => {
      const incrementYear = focusedDate.value.add(key, "year");
      const endingYearRange = activeDate.value.year() + 18;
      if (incrementYear.year() > endingYearRange) {
        activeDate.value = activeDate.value.add(24, "year");
      }

      focusedDate.value = incrementYear;
    };
    const handleYearHome = () => {
      focusedDate.value = activeDate.value.subtract(5, "year");
    };
    const handleYearEnd = () => {
      focusedDate.value = activeDate.value.add(18, "year");
    };
    const handleYearPageUp = () => {
      activeDate.value = activeDate.value.subtract(24, "year");
      focusedDate.value = focusedDate.value.subtract(24, "year");
    };
    const handleYearPageDown = () => {
      activeDate.value = activeDate.value.add(24, "year");
      focusedDate.value = focusedDate.value.add(24, "year");
    };
    const handleDecrementMonth = (key) => {
      focusedDate.value = focusedDate.value.subtract(key, "month");
      activeDate.value = focusedDate.value;
    };
    const handleIncrementMonth = (key) => {
      focusedDate.value = focusedDate.value.add(key, "month");
      activeDate.value = focusedDate.value;
    };
    const handleMonthHome = () => {
      focusedDate.value = activeDate.value.startOf("year");
      activeDate.value = focusedDate.value;
    };
    const handleMonthEnd = () => {
      focusedDate.value = activeDate.value.endOf("year");
      activeDate.value = focusedDate.value;
    };
    const handleMonthPageUp = () => {
      activeDate.value = activeDate.value.subtract(1, "year");
      focusedDate.value = activeDate.value;
    };
    const handleMonthPageDown = () => {
      activeDate.value = activeDate.value.add(1, "year");
      focusedDate.value = activeDate.value;
    };

    provide("handleDayUp", handleDayUp);
    provide("handleDayDown", handleDayDown);
    provide("handleDayLeft", handleDayLeft);
    provide("handleDayRight", handleDayRight);
    provide("handleDayHome", handleDayHome);
    provide("handleDayEnd", handleDayEnd);
    provide("handleDayPageUp", handleDayPageUp);
    provide("handleDayPageDown", handleDayPageDown);
    provide("handleDecrementYear", handleDecrementYear);
    provide("handleIncrementYear", handleIncrementYear);
    provide("handleYearHome", handleYearHome);
    provide("handleYearEnd", handleYearEnd);
    provide("handleYearPageUp", handleYearPageUp);
    provide("handleYearPageDown", handleYearPageDown);
    provide("handleDecrementMonth", handleDecrementMonth);
    provide("handleIncrementMonth", handleIncrementMonth);
    provide("handleMonthHome", handleMonthHome);
    provide("handleMonthEnd", handleMonthEnd);
    provide("handleMonthPageUp", handleMonthPageUp);
    provide("handleMonthPageDown", handleMonthPageDown);

    const handleEsc = (event) => {
      if (event.key === "Escape") {
        cancel();
      }
    };

    onMounted(() => {
      on(document, "keydown", handleEsc);
    });
    onUnmounted(() => {
      off(document, "keydown", handleEsc);
    });

    // public methods
    const open = () => {
      isActive.value = true;
    };
    const close = () => {
      isActive.value = false;
    };

    // watch input manual changes
    watch(
      () => inputValue.value,
      (date) => {
        const dateJS = dayjs(date, props.format);
        if (dateJS.isValid()) {
          selectedDate.value = dateJS;
          headerDate.value = dateJS;
          activeDate.value = dateJS;
          focusedDate.value = dateJS;
        }

        emit("update:modelValue", inputValue.value);
      }
    );

    const modelValueProp = toRefs(props).modelValue;

    watch(
      () => modelValueProp.value,
      (date) => {
        const dateJS = dayjs(date, props.format);
        if (dateJS.isValid()) {
          selectedDate.value = dateJS;
          headerDate.value = dateJS;
          activeDate.value = dateJS;
          focusedDate.value = dateJS;
          inputValue.value = date;
        }
      }
    );

    return {
      inputValue,
      isActive,
      datepickerRef,
      datepickerInlineRef,
      handleClickOutside,
      handleInputToggle,
      toggleButtonRef,
      open,
      close,
    };
  },
};
</script>
