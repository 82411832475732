<template>
  <label v-if="label" :class="labelClassName" :for="uid">{{ label }}</label>
  <component :is="tag" :class="wrapperClassName">
    <input
      type="range"
      :class="inputClassName"
      :id="uid"
      :value="inputValue"
      :min="minValue"
      :max="maxValue"
      v-bind="$attrs"
      @input="handleInput"
      @mousedown="toggleThumb('first', true), $emit('start')"
      @touchstart="toggleThumb('first', true), $emit('start')"
      @mouseup="toggleThumb('first', false, $emit('end'))"
      @touchend="toggleThumb('first', false, $emit('end'))"
      @keydown.left="$emit('slide')"
      @keydown.right="$emit('slide')"
      @keydown.up="$emit('slide')"
      @keydown.down="$emit('slide')"
      @focus="toggleThumb('first', true)"
      @blur="toggleThumb('first', false)"
    />
    <span
      v-if="thumb"
      :class="thumbClassName"
      :style="{ left: thumbsLeftPosition['first'] }"
    >
      <span class="thumb-value">{{ inputValue }}</span>
    </span>
    <input
      v-if="!props.oneRange"
      type="range"
      :class="secondInputClassName"
      :id="secondUid"
      :value="secondInputValue"
      :min="minValue"
      :max="maxValue"
      v-bind="$attrs"
      @input="handleSecondInput"
      @mousedown="toggleThumb('second', true), $emit('start')"
      @touchstart="toggleThumb('second', true), $emit('start')"
      @mouseup="toggleThumb('second', false), $emit('end')"
      @touchend="toggleThumb('second', false), $emit('end')"
      @keydown.left="$emit('slide')"
      @keydown.right="$emit('slide')"
      @keydown.up="$emit('slide')"
      @keydown.down="$emit('slide')"
      @focus="toggleThumb('second', true)"
      @blur="toggleThumb('second', false)"
      :style="secondInputStyle"
    />
    <span
      v-if="thumb && !props.oneRange"
      :class="secondThumbClassName"
      :style="{ left: thumbsLeftPosition['second'] }"
    >
      <span class="thumb-value">{{ secondInputValue }}</span>
    </span>
  </component>
</template>

<script>
import { computed, ref, watch, nextTick, reactive } from "vue";
import { getUID } from "@/components/utils/getUID";
export default {
  name: "MDBMultiRange",
  inheritAttrs: false,
  props: {
    id: String,
    secondId: String,
    inputClass: String,
    label: String,
    labelClass: String,
    max: {
      type: Number,
      default: 100,
    },
    min: {
      type: Number,
      default: 0,
    },
    firstValue: {
      type: Number,
      default: 0,
    },
    secondValue: {
      type: Number,
      default: 100,
    },
    tag: {
      type: String,
      default: "div",
    },
    thumb: {
      type: Boolean,
      default: true,
    },
    oneRange: {
      type: Boolean,
      default: false,
    },
    thumbClass: String,
    wrapperClass: String,
  },
  emits: ["update:firstValue", "update:secondValue", "start", "end", "slide"],
  setup(props, { emit }) {
    const inputValue = ref(props.firstValue);
    const minValue = ref(props.min);
    const maxValue = ref(props.max);
    const uid = props.id || getUID("MDBRange-");
    const activeThumbs = reactive({ first: false, second: false });

    const secondInputValue = ref(props.secondValue);
    const secondUid = props.secondId || getUID("MDBRange-");

    const wrapperClassName = computed(() => {
      return ["range multi-range-slider", props.wrapperClass];
    });
    const inputClassName = computed(() => {
      return ["form-range", props.inputClass];
    });
    const secondInputClassName = computed(() => {
      return ["form-range multi-range-input", props.inputClass];
    });
    const labelClassName = computed(() => {
      return ["form-label", props.labelClass];
    });
    const thumbClassName = computed(() => {
      return [
        "thumb",
        activeThumbs["first"] && "thumb-active",
        props.thumbClass,
      ];
    });
    const secondThumbClassName = computed(() => {
      return [
        "thumb",
        activeThumbs["second"] && "thumb-active",
        props.thumbClass,
      ];
    });
    const thumbsLeftPosition = reactive({ first: 0, second: 0 });

    const secondInputStyle = computed(() => {
      return {
        position: "absolute",
        left: 0,
      };
    });

    const handleInput = (e) => {
      const value = parseFloat(e.target.value);
      if (value > secondInputValue.value) {
        e.target.value = secondInputValue.value;
        return;
      }

      inputValue.value = value;
      emit("update:firstValue", inputValue.value);

      setThumbPosition("first");
    };

    const handleSecondInput = (e) => {
      const value = parseFloat(e.target.value);
      if (value < inputValue.value) {
        e.target.value = inputValue.value;
        return;
      }
      secondInputValue.value = value;
      emit("update:secondValue", secondInputValue.value);

      setThumbPosition("second");
    };

    const toggleThumb = (thumb, state) => {
      activeThumbs[thumb] = state;
    };

    const setThumbPosition = (thumb) => {
      let left;

      if (thumb === "first") {
        left = parseFloat(
          ((inputValue.value - minValue.value) * 100) /
            (maxValue.value - minValue.value)
        );
      } else if (thumb === "second") {
        left = parseFloat(
          ((secondInputValue.value - minValue.value) * 100) /
            (maxValue.value - minValue.value)
        );
      }

      thumbsLeftPosition[thumb] = `calc(${left}% + (${8 - left * 0.15}px))`;
    };

    nextTick(() => {
      setThumbPosition("first");
      setThumbPosition("second");
    });

    watch(
      () => props.firstValue,
      (value) => {
        inputValue.value = value;
        setThumbPosition("first");
      }
    );

    watch(
      () => props.secondValue,
      (value) => {
        secondInputValue.value = value;
        setThumbPosition("second");
      }
    );

    return {
      inputValue,
      secondInputValue,
      minValue,
      maxValue,
      uid,
      secondUid,
      wrapperClassName,
      inputClassName,
      secondInputClassName,
      labelClassName,
      thumbClassName,
      secondThumbClassName,
      thumbsLeftPosition,
      props,
      handleInput,
      handleSecondInput,
      toggleThumb,
      secondInputStyle,
    };
  },
};
</script>

<style scoped></style>
