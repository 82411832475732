<template>
  <MDBInput
    type="text"
    v-model="inputValue"
    labelClass="autocomplete-label"
    :class="['autocomplete-input', isPopperActive && 'focused']"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    :size="size"
    :aria-disabled="disabled"
    :aria-expanded="isDropdownActive"
    :aria-required="isValidated && required"
    :validFeedback="validFeedback"
    :invalidFeedback="invalidFeedback"
    :required="required"
    role="combobox"
    aria-haspopup="true"
    @focus="toggle"
    @keydown="handleOpenKeydown"
    @touchstart.stop
    @clickOutside="close"
    ref="autocompleteWrapperRef"
    v-bind="$attrs"
  >
    <div v-if="isLoadingData" class="autocomplete-loader spinner-border">
      <span className="sr-only">Loading...</span>
    </div>
  </MDBInput>
  <teleport to="body">
    <div
      v-if="
        isDropdownActive &&
        threshold !== undefined &&
        inputValue.length >= threshold
      "
      :id="dropdownId"
      class="autocomplete-dropdown-container"
      :style="autocompleteDropdownContainerStyle"
      ref="dropdownRef"
    >
      <div
        class="autocomplete-dropdown"
        :class="isPopperActive && 'open'"
        tabindex="0"
      >
        <ul class="autocomplete-items-list" :style="autocompleteItemsListStyle">
          <li
            v-if="filteredData.length === 0"
            class="autocomplete-item autocomplete-no-results"
            :style="{ height: `px` }"
          >
            {{ noResultsText }}
          </li>
          <li
            v-for="(item, i) in filteredData"
            class="autocomplete-item"
            :class="[activeItem === i && 'active']"
            :style="{ height: `px` }"
            @click.stop="handleItemClick(i)"
            :key="i"
            role="option"
          >
            {{ itemContent ? null : displayValue ? displayValue(item) : item }}
            <div v-if="itemContent" v-html="itemContent(item)"></div>
          </li>
          <div
            v-if="$slots.default"
            class="autocomplete-custom-content"
            @click.stop
            @touchstart.stop
          >
            <slot></slot>
          </div>
        </ul>
      </div>
    </div>
  </teleport>
</template>

<script>
import { computed, ref, watch, nextTick, watchEffect } from "vue";
import MDBInput from "@/components/free/forms/MDBInput";
import MDBPopper from "@/components/utils/MDBPopper.js";
import { getUID } from "@/components/utils/getUID";
import {
  ESCAPE,
  UP_ARROW,
  DOWN_ARROW,
  HOME,
  END,
  ENTER,
  TAB,
} from "@/components/utils/MDBKeycodes";

export default {
  name: "MDBSelect",
  components: {
    MDBInput,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    filter: Function,
    displayValue: Function,
    itemContent: Function,
    noResultsText: {
      type: String,
      default: "No results",
    },
    threshold: {
      type: Number,
      default: 0,
    },
    optionHeight: {
      type: Number,
      default: 38,
    },
    visibleOptions: {
      type: Number,
      default: 5,
    },
    // input specific props
    label: String,
    placeholder: String,
    disabled: Boolean,
    required: Boolean,
    size: String,
    isValidated: Boolean,
    isValid: Boolean,
    validFeedback: String,
    invalidFeedback: String,
  },
  inheritAttrs: false,
  emits: ["update:modelValue", "open", "close", "update", "item-select"],
  setup(props, { emit }) {
    // Class & styles ------------------------
    const className = computed(() => {
      return [
        "autocomplete-wrapper",
        isSelectValidated.value && isSelectValid.value ? "is-valid" : "",
        isSelectValidated.value && !isSelectValid.value ? "is-invalid" : "",
      ];
    });
    const autocompleteDropdownContainerStyle = computed(() => {
      return {
        position: "absolute",
        top: "0",
        width: dropdownWidth.value,
      };
    });

    const autocompleteItemsListStyle = computed(() => {
      return {
        maxHeight: `${props.visibleOptions * props.optionHeight}px`,
      };
    });

    // Config ------------------------
    const autocompleteWrapperRef = ref(null);
    const dropdownRef = ref(null);
    const autocompleteOptionsWrapperRef = ref(null);
    const dropdownId = getUID("MDBAutocompleteDropdown-");
    const dropdownWidth = ref("200px");
    const isDropdownActive = ref(false);
    const popperConfig = {
      placement: "bottom-start",
      eventsEnabled: true,
    };

    // Data ------------------------
    const filteredData = ref([]);
    const activeItem = ref(-1);

    // Popper ------------------------
    const { setPopper, isPopperActive, closePopper, openPopper } = MDBPopper();

    // Public methods ------------------------
    const toggle = () => {
      if (isPopperActive.value) {
        close();
      } else {
        open();
      }
    };

    const close = () => {
      closePopper();
      emit("close");
      setTimeout(() => {
        isDropdownActive.value = false;
      }, 300);
    };

    const open = () => {
      if (
        props.threshold !== undefined &&
        inputValue.value.length >= props.threshold
      ) {
        isDropdownActive.value = true;
        nextTick(() => openDropdown());
      }
    };

    // Private methods ------------------------
    const inputValue = ref(props.modelValue);

    const updateModelValue = () => {
      emit("update:modelValue", inputValue.value);
    };

    const handleItemClick = (itemIndex) => {
      activeItem.value = itemIndex;
      const item = filteredData.value[itemIndex];

      inputValue.value = props.displayValue ? props.displayValue(item) : item;
      emit("item-select", item);
      close();
    };

    const openDropdown = () => {
      if (!dropdownRef.value) {
        return;
      }

      setPopper(
        autocompleteWrapperRef.value.inputRef,
        dropdownRef.value,
        popperConfig
      );
      openPopper();

      handleFilter();
      dropdownWidth.value = `${autocompleteWrapperRef.value.inputRef.offsetWidth}px`;

      emit("open");
    };

    const scrollToItem = () => {
      if (activeItem.value > -1) {
        const list = dropdownRef.value?.querySelector("ul");
        if (list) {
          const listHeight = list.offsetHeight;
          const listElements = dropdownRef.value?.querySelectorAll("li");
          if (listElements) {
            const item = listElements[activeItem.value];
            const itemHeight = item.offsetHeight;
            const scrollTop = list.scrollTop;

            const itemOffset = activeItem.value * itemHeight;
            const isBelow = itemOffset + itemHeight > scrollTop + listHeight;
            const isAbove = itemOffset < scrollTop;

            if (isAbove) {
              list.scrollTop = itemOffset;
            } else if (isBelow) {
              list.scrollTop = itemOffset - listHeight + itemHeight;
            } else {
              list.scrollTop = scrollTop;
            }
          }
        }
      }
    };

    // Keyboard accessibility ------------------------

    const handleOpenKeydown = (event) => {
      const key = event.keyCode;
      const isCloseKey =
        key === ESCAPE || (key === UP_ARROW && event.altKey) || key === TAB;

      if (isCloseKey) {
        close();
        autocompleteWrapperRef.value.inputRef.focus();
        return;
      }

      switch (key) {
        case DOWN_ARROW:
          activeItem.value =
            activeItem.value < filteredData.value.length - 1
              ? activeItem.value + 1
              : activeItem.value;
          break;
        case UP_ARROW:
          activeItem.value =
            activeItem.value > 0 ? activeItem.value - 1 : activeItem.value;

          break;
        case HOME:
          activeItem.value = 0;
          break;
        case END:
          activeItem.value = filteredData.value.length - 1;
          break;

        case ENTER:
          if (activeItem.value > -1) {
            handleItemClick(activeItem.value);
          }
          return;
        default:
          return;
      }
    };

    // Validation ------------------------
    const isSelectValidated = ref(props.isValidated);
    const isSelectValid = ref(props.isValid);

    // Filtering ------------------------
    const isLoadingData = ref(false);

    const handleFilter = () => {
      const isPromise = (value) => {
        return !!value && typeof value.then === "function";
      };

      if (isPopperActive) {
        const data = props.filter(inputValue.value);
        if (isPromise(data)) {
          isLoadingData.value = true;
          data.then((items) => {
            filteredData.value = items;
            emit("update", items);

            isLoadingData.value = false;
          });
        } else {
          emit("update", data);

          filteredData.value = data;
        }
      }
    };

    // Watchers ----------------------
    watchEffect(() => (inputValue.value = props.modelValue));

    watch(
      () => inputValue.value,
      () => {
        if (
          !isDropdownActive.value &&
          props.threshold !== undefined &&
          inputValue.value.length >= props.threshold
        ) {
          open();
        }

        if (
          isDropdownActive.value &&
          props.threshold !== undefined &&
          inputValue.value.length < props.threshold
        ) {
          close();
        }

        activeItem.value = -1;
        updateModelValue();
        handleFilter();
      }
    );

    watch(
      () => [activeItem.value, isPopperActive],
      () => scrollToItem()
    );

    watch(
      () => props.isValidated,
      (value) => (isSelectValidated.value = value)
    );

    watch(
      () => props.isValid,
      (value) => (isSelectValid.value = value)
    );

    return {
      className,
      props,
      dropdownId,
      inputValue,
      filteredData,
      isPopperActive,
      isDropdownActive,
      activeItem,
      autocompleteDropdownContainerStyle,
      autocompleteItemsListStyle,
      autocompleteWrapperRef,
      isSelectValidated,
      isSelectValid,
      dropdownRef,
      autocompleteOptionsWrapperRef,
      isLoadingData,
      handleItemClick,
      handleFilter,
      handleOpenKeydown,
      // public
      open,
      close,
      toggle,
    };
  },
};
</script>
