<template>
  <MDBPopover v-if="popover" v-model="isPopoverActive" direction="top">
    <template #reference>
      <component
        :is="tag"
        v-bind="$attrs"
        ref="ratingItemRef"
        :class="[insertAfter || insertBefore ? 'px-2 d-flex' : null, classes]"
      >
        {{ insertBefore }}
        <MDBIcon
          :iconStyle="isActive ? 'fas' : 'far'"
          :icon="dynamicStyle.icon ? dynamicStyle.icon : icon"
          :size="size"
          :flag="flag"
          :class="[isActive && 'active', !color && 'text-primary', iconClass]"
          :style="{ color: dynamicStyle.color ? dynamicStyle.color : color }"
          @click="handleClick"
          @mouseenter="handleMouseEnter"
        />
        {{ insertAfter }}
      </component>
    </template>
    <template #body v-if="popover && popover !== true">{{ popover }}</template>
    <template #body v-else><slot /></template>
  </MDBPopover>
  <MDBTooltip v-else v-model="isTooltipActive" :disabled="title ? null : true">
    <template #reference>
      <component
        :is="tag"
        v-bind="$attrs"
        ref="ratingItemRef"
        :class="[insertAfter || insertBefore ? 'px-2 d-flex' : null, classes]"
      >
        {{ insertBefore }}
        <MDBIcon
          :iconStyle="isActive ? 'fas' : 'far'"
          :icon="dynamicStyle.icon ? dynamicStyle.icon : icon"
          :size="size"
          :flag="flag"
          :class="[isActive && 'active', !color && 'text-primary', iconClass]"
          :style="{ color: dynamicStyle.color ? dynamicStyle.color : color }"
          @click="handleClick"
          @mouseenter="handleMouseEnter"
        />
        {{ insertAfter }}
      </component>
    </template>
    <template #tip>
      {{ title }}
    </template>
  </MDBTooltip>
</template>

<script>
import { computed, inject, ref, watch } from "vue";
import MDBIcon from "../../free/content-styles/MDBIcon";
import MDBTooltip from "../../free/components/MDBTooltip";
import MDBPopover from "../../free/components/MDBPopover";

export default {
  name: "MDBRatingItem",
  inheritAttrs: false,
  components: {
    MDBIcon,
    MDBTooltip,
    MDBPopover,
  },
  props: {
    tag: {
      type: String,
      default: "li",
    },
    iconClass: String,
    classes: String,
    icon: {
      type: String,
      default: "star",
    },
    flag: String,
    size: {
      type: String,
      default: "sm",
    },
    color: String,
    title: String,
    index: {
      type: Number,
      required: true,
    },
    popover: { type: [Boolean, String], default: false },
    insertBefore: String,
    insertAfter: String,
  },
  setup(props) {
    const ratingItemRef = ref(null);

    const activeItem = inject("activeItem", false);
    const hoveredItem = inject("hoveredItem", false);
    const setActiveRating = inject("setActiveRating", false);
    const setHoveredRating = inject("setHoveredRating", false);
    const readonly = inject("readonly", false);
    const dynamic = inject("dynamic", false);
    const dynamicStyle = inject("dynamicStyle", false);
    const setDynamic = inject("setDynamic", false);

    const isActive = computed(() => {
      return (
        (!hoveredItem.value &&
          activeItem.value &&
          activeItem.value >= props.index) ||
        hoveredItem.value >= props.index
      );
    });

    watch(
      [hoveredItem, activeItem],
      () => {
        if (dynamic) {
          const dynamicValue = hoveredItem.value
            ? hoveredItem.value
            : activeItem.value;
          dynamicValue === props.index && setDynamic(props.icon, props.color);
        }
      },
      { immediate: true }
    );

    const handleClick = (e) => {
      if (props.popover) {
        isPopoverActive.value = !isPopoverActive.value;
      }
      !readonly && setActiveRating(props.index, e);
    };

    const handleMouseEnter = (e) => {
      !readonly && setHoveredRating(props.index, e);
    };

    const isTooltipActive = ref(false);
    const isPopoverActive = ref(false);

    return {
      ratingItemRef,
      isActive,
      isTooltipActive,
      isPopoverActive,
      handleClick,
      handleMouseEnter,
      dynamicStyle,
      props,
    };
  },
};
</script>
