<template>
  <component
    :is="tag"
    ref="lightboxItemRef"
    :data-lightbox-src="fullScreenSrc"
    :data-lightbox-caption="caption"
    :data-lightbox-disabled="disabled ? true : null"
    :id="uid"
    @click="handleClick"
  >
    <slot />
  </component>
</template>

<script>
import { ref, inject } from "vue";
import { getUID } from "@/components/utils/getUID";

export default {
  name: "MDBLightboxItem",
  props: {
    tag: {
      type: String,
      default: "img",
    },
    id: String,
    fullScreenSrc: String,
    caption: String,
    disabled: Boolean,
  },
  emits: ["update:modelValue", "item-click", "item-hover"],
  setup(props) {
    const lightboxItemRef = ref(null);
    const uid = props.id || getUID("MDBLightboxItem-");

    const toggleLightbox = inject("toggleLightbox", false);
    const initialImagesArray = inject("initialImagesArray", []);

    const findImg = () => {
      return (
        initialImagesArray.value &&
        initialImagesArray.value.find((img) => img.refId === uid)
      );
    };

    const handleClick = () => {
      const image = findImg();
      if (toggleLightbox && image) {
        toggleLightbox(image.id);
      }
    };

    return {
      uid,
      lightboxItemRef,
      handleClick,
    };
  },
};
</script>
