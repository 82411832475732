<template>
  <component
    :is="tag"
    :class="[
      'timepicker-head d-flex flex-row align-items-center justify-content-center',
      inline && 'timepicker-head-inline',
    ]"
    :style="{ paddingRight: `${inline ? 0 : hoursFormat ? 50 : 0}px` }"
  >
    <div
      :class="[
        'timepicker-head-content d-flex w-100 justify-content-evenly',
        inline && 'align-items-center',
      ]"
    >
      <div class="timepicker-current-wrapper">
        <span
          :class="[
            'position-relative h-100',
            inline && 'timepicker-inline-hour-icons',
          ]"
        >
          <i
            v-if="inline"
            class="
              fas
              fa-chevron-up
              position-absolute
              text-white
              timepicker-icon-up timepicker-icon-inline-hour
            "
            @click="incrementHoursValue(1)"
          ></i>
          <button
            type="button"
            :class="[
              'timepicker-current timepicker-hour',
              inline && 'timepicker-current-inline',
              unitsMode === 'hours' && 'active',
            ]"
            :style="{ pointerEvents: unitsMode === 'hours' ? 'none' : 'auto' }"
            tabindex="0"
            @click="chageUnitsMode('hours')"
            @keydown.enter="chageUnitsMode('hours')"
            @keydown.space="chageUnitsMode('hours')"
          >
            {{ renderedHours }}
          </button>
          <i
            v-if="inline"
            :class="[
              'fas fa-chevron-down position-absolute text-white timepicker-icon-down timepicker-icon-inline-hour',
            ]"
            @click="incrementHoursValue(-1)"
          ></i>
        </span>
        <button
          type="button"
          :class="['timepicker-dot', inline && 'timepicker-current-inline']"
          disabled
        >
          :
        </button>
        <span
          :class="[
            'position-relative h-100',
            inline && 'timepicker-inline-minutes-icons',
          ]"
        >
          <i
            v-if="inline"
            class="
              fas
              fa-chevron-up
              position-absolute
              text-white
              timepicker-icon-up timepicker-icon-inline-minute
            "
            @click="incrementMinutesValue(increment)"
          ></i>
          <button
            type="button"
            :class="[
              'timepicker-current timepicker-minute',
              inline && 'timepicker-current-inline',
              unitsMode === 'minutes' && 'active',
            ]"
            :style="{
              pointerEvents: unitsMode === 'minutes' ? 'none' : 'auto',
            }"
            tabindex="0"
            @click="chageUnitsMode('minutes')"
            @keydown.enter="chageUnitsMode('minutes')"
            @keydown.space="chageUnitsMode('minutes')"
          >
            {{ minutes }}
          </button>
          <i
            v-if="inline"
            class="
              fas
              fa-chevron-down
              position-absolute
              text-white
              timepicker-icon-down timepicker-icon-inline-minute
            "
            @click="incrementMinutesValue(-increment)"
          ></i>
        </span>
      </div>
      <div
        v-if="hoursFormat === 12"
        :class="[
          'd-flex justify-content-center timepicker-mode-wrapper',
          !inline && 'flex-column',
        ]"
      >
        <button
          type="button"
          :class="[
            'timepicker-hour-mode timepicker-am',
            inline && 'me-2 ms-4',
            dayTime === 'am' && 'active',
          ]"
          tabindex="0"
          @keydown.enter="changeDayTime('am')"
          @keydown.space="changeDayTime('am')"
          @click="changeDayTime('am')"
        >
          {{ amLabel }}
        </button>
        <button
          :class="[
            'timepicker-hour-mode timepicker-pm',
            dayTime === 'pm' && 'active',
          ]"
          tabindex="0"
          @keydown.enter="changeDayTime('pm')"
          @keydown.space="changeDayTime('pm')"
          @click="changeDayTime('pm')"
          :aria-label="pmLabel"
        >
          {{ pmLabel }}
        </button>
        <button
          v-if="inline"
          type="button"
          class="
            timepicker-button timepicker-submit timepicker-submit-inline
            py-1
            px-2
            mb-0
          "
          tabindex="0"
          @click="handleOkClick"
          @keydown.enter="handleOkClick"
          @keydown.space="handleOkClick"
        >
          {{ okLabel }}
        </button>
      </div>
      <button
        v-else-if="hoursFormat === 24 && inline"
        class="
          timepicker-button timepicker-submit timepicker-submit-inline
          py-1
          px-2
          mb-0
        "
        tabindex="0"
        @click="handleOkClick"
        @keydown.enter="handleOkClick"
        @keydown.space="handleOkClick"
      >
        {{ okLabel }}
      </button>
    </div>
  </component>
</template>

<script>
import { computed, inject } from "vue";

export default {
  name: "MDBTimepickerHeader",
  props: {
    tag: {
      type: String,
      default: "div",
    },
  },
  setup(props) {
    // ------------- TIMEPICKER PROPS INJECTS -------------
    const hoursFormat = inject("hoursFormat", null);
    const amLabel = inject("amLabel", null);
    const pmLabel = inject("pmLabel", null);
    const okLabel = inject("okLabel", null);
    const inline = inject("inline", null);
    const increment = inject("increment", null);

    // ------------- STATE MANAGEMENT -------------
    const hours = inject("hours", null);
    const minutes = inject("minutes", null);
    const dayTime = inject("dayTime", null);
    const unitsMode = inject("unitsMode", null);

    const changeDayTime = inject("changeDayTime", null);
    const handleOkClick = inject("handleOkClick", null);
    const chageUnitsMode = inject("chageUnitsMode", null);
    const incrementHoursValue = inject("incrementHoursValue", null);
    const incrementMinutesValue = inject("incrementMinutesValue", null);

    const renderedHours = computed(() => {
      if (hoursFormat == 24 && hours.value === 24) {
        return "00";
      }

      return hours.value;
    });

    return {
      hoursFormat,
      pmLabel,
      amLabel,
      okLabel,
      inline,
      increment,
      hours,
      renderedHours,
      minutes,
      dayTime,
      unitsMode,
      changeDayTime,
      chageUnitsMode,
      handleOkClick,
      incrementHoursValue,
      incrementMinutesValue,
      props,
    };
  },
};
</script>
