<template>
  <PerfectScrollbar
    :class="className"
    :options="settings"
    :style="scrollbarStyles"
    v-bind="$attrs"
  >
    <slot></slot>
  </PerfectScrollbar>
</template>

<script>
import { computed, ref, watchEffect } from "vue";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";

export default {
  name: "MDBScrollbar",
  inheritAttrs: false,
  components: {
    PerfectScrollbar,
  },
  props: {
    wheelSpeed: {
      type: Number,
      default: 1,
    },
    wheelPropagation: Boolean,
    swipeEasing: {
      type: Boolean,
      default: true,
    },
    minScrollbarLength: {
      type: Number,
      default: 0,
    },
    maxScrollbarLength: {
      type: Number,
      default: 0,
    },
    scrollingThreshold: {
      type: Number,
      default: 1000,
    },
    useBothWheelAxes: Boolean,
    suppressScrollX: Boolean,
    suppressScrollY: Boolean,
    scrollXMarginOffset: {
      type: Number,
      default: 0,
    },
    scrollYMarginOffset: {
      type: Number,
      default: 0,
    },
    scrollbarClass: String,
    scrollbarStyle: String,
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  setup(props) {
    const width = ref(props.width);
    const height = ref(props.height);
    const className = computed(() => {
      return [props.scrollbarClass];
    });
    const scrollbarStyles = computed(() => {
      return {
        ...props.scrollbarStyle,
        width: width.value,
        height: height.value,
      };
    });
    const settings = computed(() => {
      return {
        wheelSpeed: props.wheelSpeed,
        wheelPropagation: props.wheelPropagation,
        swipeEasing: props.swipeEasing,
        minScrollbarLength: props.minScrollbarLength,
        maxScrollbarLength: props.maxScrollbarLength,
        scrollingThreshold: props.scrollingThreshold,
        useBothWheelAxes: props.useBothWheelAxes,
        suppressScrollX: props.suppressScrollX,
        suppressScrollY: props.suppressScrollY,
        scrollXMarginOffset: props.scrollXMarginOffset,
        scrollYMarginOffset: props.scrollYMarginOffset,
      };
    });

    watchEffect(() => {
      height.value = props.height;
      width.value = props.width;
    });

    return {
      props,
      className,
      scrollbarStyles,
      settings,
    };
  },
};
</script>
