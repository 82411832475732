<template>
  <teleport to="body" v-if="isActive && !parentSelector">
    <component
      :class="loadingClassName"
      :is="tag"
      v-bind="$attrs"
      ref="loadingRef"
    >
      <div :class="spinnerClassName" role="status"></div>
      <span :class="loadingTextClassName">{{ loadingText }}</span>
    </component>
    <div
      class="loading-backdrop"
      :style="{ opacity: backdropOpacity, backgroundColor: backdropColor }"
    ></div>
  </teleport>
  <component
    v-else-if="parentSelector && isActive"
    :class="loadingClassName"
    :is="tag"
    v-bind="$attrs"
    ref="loadingRef"
  >
    <div :class="spinnerClassName" role="status"></div>
    <span :class="loadingTextClassName">{{ loadingText }}</span>
  </component>
  <div
    v-if="parentSelector && isActive"
    class="loading-backdrop position-absolute"
    :style="{ opacity: backdropOpacity, backgroundColor: backdropColor }"
  ></div>
</template>

<script>
import { computed, onMounted, ref, watch, watchEffect } from "vue";
export default {
  name: "MDBLoading",
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "div",
    },
    fullScreen: {
      type: Boolean,
      default: true,
    },
    parentSelector: String,
    modelValue: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "spinner-border",
    },
    loadingText: {
      type: String,
      default: "Loading...",
    },
    backdropColor: {
      type: String,
      default: "black",
    },
    backdropOpacity: {
      type: Number,
      default: 0.4,
    },
    overflow: {
      type: Boolean,
      defeault: true,
    },
    spinnerClasses: String,
    textClasses: String,
  },
  setup(props) {
    const loadingClassName = computed(() => {
      return [
        props.parentSelector
          ? "loading position-absolute"
          : "loading-full position-fixed",
        "loading-spinner",
      ];
    });

    const spinnerClassName = computed(() => {
      return [props.icon, "loading-icon", props.spinnerClasses];
    });

    const loadingTextClassName = computed(() => {
      return ["loading-text", props.textClasses];
    });

    const loadingRef = ref(null);
    const isActive = ref(props.modelValue);
    watchEffect(() => (isActive.value = props.modelValue));

    watch(
      () => isActive.value,
      (cur) => {
        if (props.fullScreen && props.overflow) {
          cur
            ? (document.body.style.overflow = "hidden")
            : (document.body.style.overflow = "");
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      if (props.parentSelector) {
        const parent = document.querySelector(props.parentSelector);
        parent.classList.add("position-relative");
      }
    });

    return {
      isActive,
      loadingRef,
      loadingClassName,
      spinnerClassName,
      loadingTextClassName,
    };
  },
};
</script>
