<template>
  <div class="datepicker-date-controls" ref="datepickerDateControlsRef">
    <button
      class="datepicker-view-change-button"
      :aria-label="switchToMultiYearViewLabel"
      @click.stop="changeView('year')"
    >
      {{ activeMonth }} {{ activeYear }}
    </button>
    <div class="datepicker-arrow-controls">
      <button
        class="datepicker-previous-button"
        :aria-label="prevMonthLabel"
        @click="goToPrevMonth"
      ></button>
      <button
        class="datepicker-next-button"
        :aria-label="nextMonthLabel"
        @click="goToNextMonth"
      ></button>
    </div>
  </div>

  <div
    class="datepicker-view"
    ref="datepickerViewRef"
    tabindex="0"
    @keydown.up.prevent="handleDayUp"
    @keydown.down.prevent="handleDayDown"
    @keydown.left.prevent="handleDayLeft"
    @keydown.right.prevent="handleDayRight"
    @keydown.enter.prevent="handleEnter"
    @keydown="handleKeydown"
    @keydown.tab="handleTab"
    @focus="isDatepickerViewFocused = true"
    @blur="isDatepickerViewFocused = false"
  >
    <table class="datepicker-table">
      <thead>
        <tr>
          <th
            v-for="(weekday, key) in weekdaysNarrow"
            :key="key"
            class="datepicker-day-heading"
            scope="col"
            :aria-label="weekdaysFull[key]"
          >
            {{ weekday }}
          </th>
        </tr>
      </thead>
      <tbody class="datepicker-table-body">
        <tr v-for="(week, key) in days" :key="key">
          <td
            v-for="(day, key) in week"
            :key="key"
            class="datepicker-cell datepicker-small-cell datepicker-day-cell"
            :class="[
              day.current && 'current',
              day.focused && isDatepickerViewFocused && 'focused',
              day.selected && 'selected',
              !day.selectable && 'disabled',
            ]"
            :aria-label="day.dayJS"
            :aria-selected="day.selected"
            @click="handleSelectDay(day)"
          >
            <div
              class="datepicker-cell-content datepicker-small-cell-content"
              v-if="!day.disabled"
            >
              {{ day.label }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject, computed, ref, onMounted } from "vue";

export default {
  name: "MDBDatepickerDayView",
  props: {
    inline: Boolean,
  },
  setup(props) {
    const activeDate = inject("activeDate");
    const selectedDate = inject("selectedDate");
    const focusedDate = inject("focusedDate");
    const monthsFull = inject("monthsFull");
    const weekdaysNarrow = inject("weekdaysNarrow");
    const weekdaysFull = inject("weekdaysFull");
    const prevMonthLabel = inject("prevMonthLabel");
    const nextMonthLabel = inject("nextMonthLabel");
    const startDay = inject("startDay");
    const switchToMultiYearViewLabel = inject("switchToMultiYearViewLabel");

    const activeMonth = computed(() => monthsFull[activeDate.value.month()]);
    const activeYear = computed(() => activeDate.value.year());

    const selectDay = inject("selectDay");
    const goToPrevMonth = inject("goToPrevMonth");
    const goToNextMonth = inject("goToNextMonth");
    const changeView = inject("changeView");

    const minDate = inject("minDate");
    const maxDate = inject("maxDate");
    const filter = inject("filter");

    const days = computed(() => {
      const list = [];
      for (let i = 0; i < 6; i++) {
        list.push([]);
        let startingWeekDate;

        if (startDay === 0) {
          startingWeekDate = activeDate.value
            .startOf("month")
            .add(i, "week")
            .startOf("week");
        } else if (startDay === 1) {
          startingWeekDate = activeDate.value
            .startOf("month")
            .add(i, "week")
            .startOf("isoWeek");
        }

        for (let j = 0; j < 7; j++) {
          const dayJS = startingWeekDate.add(j, "day");
          list[i].push({
            current: dayJS.isToday(),
            dayJS,
            disabled: dayJS.month() !== activeDate.value.month(),
            focused: dayJS.isSame(focusedDate.value, "day"),
            label: dayJS.date(),
            selectable: isDateSelectable(dayJS) && !filter(dayJS),
            selected: dayJS.isSame(selectedDate.value, "day"),
          });
        }
      }

      return list;
    });

    const handleSelectDay = (day) => {
      if (day.disabled) {
        return;
      }

      selectDay(day);
    };

    const isDateSelectable = (date) => {
      if (minDate.value && maxDate.value) {
        return date.isAfter(minDate.value) && date.isBefore(maxDate.value);
      } else if (minDate.value) {
        return date.isAfter(minDate.value);
      } else if (maxDate.value) {
        return date.isBefore(maxDate.value);
      }

      return true;
    };

    // keyboard accessibility -------------------------------------
    const handleDayUp = inject("handleDayUp");
    const handleDayDown = inject("handleDayDown");
    const handleDayLeft = inject("handleDayLeft");
    const handleDayRight = inject("handleDayRight");
    const handleDayHome = inject("handleDayHome");
    const handleDayEnd = inject("handleDayEnd");
    const handleDayPageUp = inject("handleDayPageUp");
    const handleDayPageDown = inject("handleDayPageDown");
    const isDatepickerViewFocused = ref(false);

    const handleEnter = () => {
      if (isDateSelectable(focusedDate.value) && !filter(focusedDate.value)) {
        const dateObj = {};
        dateObj.dayJS = focusedDate.value;
        selectDay(dateObj);
      }
    };
    const handleKeydown = (event) => {
      switch (event.key) {
        case "Home":
          handleDayHome();
          break;
        case "End":
          handleDayEnd();
          break;
        case "PageUp":
          handleDayPageUp();
          break;
        case "PageDown":
          handleDayPageDown();
          break;
        default:
          break;
      }
    };
    const datepickerDateControlsRef = ref(null);
    const handleTab = (event) => {
      if (props.inline) {
        event.preventDefault();
        const changeButtonEl = datepickerDateControlsRef.value.querySelector(
          ".datepicker-view-change-button"
        );
        changeButtonEl.focus();
      }
    };
    const datepickerViewRef = ref(null);
    onMounted(() => {
      setTimeout(() => {
        datepickerViewRef.value.focus();
      }, 100);
    });

    return {
      activeMonth,
      activeYear,
      goToPrevMonth,
      goToNextMonth,
      weekdaysNarrow,
      weekdaysFull,
      prevMonthLabel,
      nextMonthLabel,
      switchToMultiYearViewLabel,
      days,
      handleSelectDay,
      changeView,
      datepickerDateControlsRef,
      datepickerViewRef,
      handleDayUp,
      handleDayDown,
      handleDayLeft,
      handleDayRight,
      handleEnter,
      handleKeydown,
      handleTab,
      isDatepickerViewFocused,
    };
  },
};
</script>
