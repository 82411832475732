<template>
  <div class="datepicker-main" ref="datepickerMainRef">
    <MDBDatepickerDayView v-if="view === 'day'" :inline="inline" />
    <MDBDatepickerYearView v-if="view === 'year'" />
    <MDBDatepickerMonthView v-if="view === 'month'" />

    <div v-if="!inline" class="datepicker-footer">
      <button
        class="datepicker-footer-btn datepicker-clear-btn"
        :aria-label="clearBtnLabel"
        @click="clear"
      >
        {{ clearBtnText }}
      </button>
      <button
        class="datepicker-footer-btn datepicker-cancel-btn"
        :aria-label="cancelBtnLabel"
        @click="cancel"
      >
        {{ cancelBtnText }}
      </button>
      <button
        class="datepicker-footer-btn datepicker-ok-btn"
        :aria-label="okBtnLabel"
        @click="ok"
        @keydown.tab.prevent="handleTab"
      >
        {{ okBtnText }}
      </button>
    </div>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import MDBDatepickerDayView from "./MDBDatepickerDayView";
import MDBDatepickerYearView from "./MDBDatepickerYearView";
import MDBDatepickerMonthView from "./MDBDatepickerMonthView";

export default {
  name: "MDBDatepickerMain",
  components: {
    MDBDatepickerDayView,
    MDBDatepickerYearView,
    MDBDatepickerMonthView,
  },
  props: {
    inline: Boolean,
  },
  setup() {
    const ok = inject("ok");
    const cancel = inject("cancel");
    const clear = inject("clear");
    const view = inject("view");

    const cancelBtnText = inject("cancelBtnText");
    const clearBtnText = inject("clearBtnText");
    const okBtnText = inject("okBtnText");
    const cancelBtnLabel = inject("cancelBtnLabel");
    const clearBtnLabel = inject("clearBtnLabel");
    const okBtnLabel = inject("okBtnLabel");

    const datepickerMainRef = ref(null);
    const handleTab = () => {
      const changeButtonEl = datepickerMainRef.value.querySelector(
        ".datepicker-view-change-button"
      );
      changeButtonEl.focus();
    };

    return {
      ok,
      cancel,
      clear,
      view,
      cancelBtnText,
      clearBtnText,
      okBtnText,
      cancelBtnLabel,
      clearBtnLabel,
      okBtnLabel,
      datepickerMainRef,
      handleTab,
    };
  },
};
</script>
