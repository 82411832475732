<template>
  <component
    :is="tag"
    class="
      timepicker-clock-wrapper
      d-flex
      justify-content-center
      flex-column
      align-items-center
    "
  >
    <MDBTimepickerClock
      v-if="unitsMode === 'hours'"
      :unitsMode="unitsMode"
      :min="1"
      :max="hoursFormat"
      :allowedValues="allowedHours"
      :rotate="30"
      v-model="hours"
      :double="double"
      v-model:angle="angle"
      @input="(e) => updateHoursValue(e)"
      @change="chageUnitsMode('minutes')"
    />
    <MDBTimepickerClock
      v-if="unitsMode === 'minutes'"
      :unitsMode="unitsMode"
      :min="0"
      :max="59"
      :allowedValues="allowedMinutes"
      v-model="minutes"
      v-model:angle="angle"
      @input="(e) => updateMinutesValue(e)"
    />
  </component>
</template>

<script>
import { computed, inject, ref } from "vue";
import MDBTimepickerClock from "./MDBTimepickerClock";

export default {
  name: "MDBTimepickerPlate",
  components: { MDBTimepickerClock },
  props: {
    tag: {
      type: String,
      default: "div",
    },
  },
  setup(props) {
    // ------------- TIMEPICKER PROPS INJECTS -------------
    const hoursFormat = inject("hoursFormat", null);
    const increment = inject("increment", null);

    // ------------- STATE MANAGEMENT -------------
    const hours = inject("hours", null);
    const minutes = inject("minutes", null);
    const unitsMode = inject("unitsMode", null);
    const updateMinutesValue = inject("updateMinutesValue", null);
    const updateHoursValue = inject("updateHoursValue", null);
    const chageUnitsMode = inject("chageUnitsMode", null);

    const double = computed(() => {
      return hoursFormat === 24;
    });

    const angle = ref(360);

    // ------------- VALIDATION -------------
    const allowedHours = inject("allowedHours", null);
    const allowedMinutes = inject("allowedMinutes", null);

    return {
      hoursFormat,
      increment,
      hours,
      minutes,
      unitsMode,
      double,
      updateMinutesValue,
      updateHoursValue,
      chageUnitsMode,
      allowedHours,
      allowedMinutes,
      angle,
      props,
    };
  },
};
</script>
