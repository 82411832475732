<template>
  <component v-if="active" :is="tag" :class="className">
    <img v-if="src" :src="src" :alt="alt" />
    <slot></slot>
    <i v-show="close" class="fas fa-times close" @click="handleCloseClick" />
  </component>
</template>

<script>
import { computed, ref } from "vue";

export default {
  name: "MDBChip",
  props: {
    tag: {
      type: String,
      default: "div",
    },
    src: String,
    alt: String,
    size: String,
    outline: String,
    close: Boolean,
    handleClose: Function,
  },
  emits: ["close-chip"],
  setup(props, { emit }) {
    const active = ref(true);

    function handleCloseClick() {
      if (props.handleClose) {
        props.handleClose();
      }
      emit("close-chip");
      active.value = false;
    }

    const className = computed(() => [
      "chip",
      props.size && `chip-${props.size}`,
      props.outline && `chip-outline btn-outline-${props.outline}`,
    ]);

    return { className, handleCloseClick, active };
  },
};
</script>

<style></style>
