<template>
  <component
    :is="tag"
    :class="className"
    :style="{ maxWidth: width }"
    :key="datatableKey"
  >
    <div
      class="datatable-inner table-responsive"
      style="overflow: auto; position: relative"
    >
      <MDBScrollbar
        :width="width"
        :height="height"
        :wheelPropagation="true"
        style="background-color: inherit"
      >
        <table class="table datatable-table">
          <thead class="datatable-header" v-if="data.columns">
            <tr>
              <th
                v-if="selectable"
                scope="col"
                :class="fixedHeader && 'fixed-cell'"
              >
                <MDBCheckbox
                  v-if="multi"
                  @click.stop="toggleSelectAll"
                  v-model="allSelectedCheckbox"
                  wrapperClass="d-flex align-items-center mb-0"
                />
              </th>
              <th
                v-for="(col, colKey) in data.columns"
                :key="'col-' + colKey"
                scope="col"
                :style="{
                  cursor: col.sort !== false ? 'pointer' : 'default',
                  minWidth: col.width + 'px',
                  maxWidth: col.width + 'px',
                  left: col.fixed && !col.right ? col.left + 'px' : false,
                  right: col.fixed && col.right ? 0 : false,
                }"
                :class="(fixedHeader || col.fixed) && 'fixed-cell'"
                @click="col.sort !== false && sortAndFilter(col.field)"
              >
                <i
                  v-if="col.sort !== false"
                  class="datatable-sort-icon fas fa-arrow-up"
                  :class="orderBy && orderKey === col.field && 'active'"
                  :style="{
                    transform:
                      orderBy === 'desc' && orderKey === col.field
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                  }"
                ></i>
                {{ col.label }}
              </th>
            </tr>
          </thead>
          <tbody
            class="datatable-body"
            v-if="(data.rows && data.rows.length > 0) || loading"
          >
            <tr
              v-for="(row, rowKey) in data.rows.slice(
                pageKey * rowsPerPage,
                pageKey * rowsPerPage + rowsPerPage
              )"
              :key="'row-' + row.mdbIndex"
              :data-mdb-index="row.mdbIndex"
              :class="row.selected && 'active'"
              @click="handleRowClick(row.mdbIndex)"
              scope="row"
            >
              <td v-if="selectable">
                <MDBCheckbox
                  :data-mdb-row-index="row.mdbIndex"
                  v-model="row.selected"
                  @click.stop
                  @change="handleCheckboxChange(row.mdbIndex, row.selected)"
                />
              </td>
              <td
                v-for="(col, colKey) in data.columns"
                :key="'cell-' + colKey"
                :style="[
                  row.formats && row.formats[col.field],
                  {
                    minWidth: col.width + 'px',
                    maxWidth: col.width + 'px',
                    left: col.fixed && !col.right ? col.left + 'px' : false,
                    right: col.fixed && col.right ? 0 : false,
                  },
                ]"
                :class="col.fixed && 'fixed-cell'"
                :contenteditable="edit ? true : null"
                @blur="handleCellBlur($event, rowKey, col.field)"
                v-html="row[col.field] || defaultValue"
              ></td>
            </tr>
          </tbody>
          <tbody class="datatable-body" v-else>
            <tr>
              <td>
                {{ noFoundMessage }}
              </td>
            </tr>
          </tbody>
        </table>
      </MDBScrollbar>
    </div>

    <div v-if="loading" class="datatable-loader bg-light">
      <span class="datatable-loader-inner"
        ><span class="datatable-progress" :class="loaderClass"></span
      ></span>
    </div>
    <p v-if="loading" class="text-center text-muted my-4">Loading results...</p>

    <div class="datatable-pagination" v-if="pagination">
      <div class="datatable-select-wrapper">
        <p class="datatable-select-text">{{ rowsText }}</p>
        <MDBSelect
          v-model:options="selectOptions"
          v-model:selected="rowsPerPage"
        />
      </div>
      <div class="datatable-pagination-nav">
        {{ firstRowIndex }} - {{ lastRowIndex }} of
        {{ data.rows ? data.rows.length : "" }}
      </div>
      <div class="datatable-pagination-buttons">
        <MDBBtn
          v-if="fullPagination"
          @click="
            () => {
              pageKey = 0;
              $nextTick(() => $emit('render', data));
            }
          "
          :ripple="false"
          color="link"
          class="datatable-pagination-button datatable-pagination-start"
          :disabled="pageKey === 0 ? true : null"
        >
          <i class="fa fa-angle-double-left"></i>
        </MDBBtn>
        <MDBBtn
          @click="
            () => {
              pageKey--;
              $nextTick(() => $emit('render', data));
            }
          "
          :ripple="false"
          color="link"
          class="datatable-pagination-button datatable-pagination-left"
          :disabled="pageKey === 0 ? true : null"
        >
          <i class="fa fa-chevron-left"></i>
        </MDBBtn>
        <MDBBtn
          @click="
            () => {
              pageKey++;
              $nextTick(() => $emit('render', data));
            }
          "
          :ripple="false"
          color="link"
          class="datatable-pagination-button datatable-pagination-right"
          :disabled="pageKey === pages - 1 || pages === 0 ? true : null"
        >
          <i class="fa fa-chevron-right"></i>
        </MDBBtn>
        <MDBBtn
          v-if="fullPagination"
          @click="
            () => {
              pageKey = pages - 1;
              $nextTick(() => $emit('render', data));
            }
          "
          :ripple="false"
          color="link"
          class="datatable-pagination-button datatable-pagination-start"
          :disabled="pageKey === pages - 1 || pages === 0 ? true : null"
        >
          <i class="fa fa-angle-double-right"></i>
        </MDBBtn>
      </div>
    </div>
  </component>
</template>

<script>
import { computed, ref, onMounted, watch, nextTick } from "vue";
import MDBSelect from "@/components/pro/forms/MDBSelect";
import MDBCheckbox from "@/components/free/forms/MDBCheckbox";
import MDBBtn from "@/components/free/components/MDBBtn";
import MDBScrollbar from "@/components/pro/methods/MDBScrollbar";

export default {
  name: "MDBDatatable",
  components: {
    MDBSelect,
    MDBCheckbox,
    MDBBtn,
    MDBScrollbar,
  },
  props: {
    bordered: Boolean,
    borderless: Boolean,
    borderColor: String,
    clickableRows: Boolean,
    color: String,
    dark: Boolean,
    defaultValue: {
      type: String,
      default: "-",
    },
    dataset: {
      type: Object,
      default() {
        return {
          columns: [],
          rows: [],
        };
      },
    },
    edit: Boolean,
    entries: {
      type: Number,
      default: 10,
    },
    entriesOptions: {
      type: Array,
      default: () => [10, 25, 50, 200],
    },
    fixedHeader: Boolean,
    fullPagination: Boolean,
    hover: Boolean,
    loaderClass: {
      type: String,
      default: "bg-primary",
    },
    loading: Boolean,
    loadingMessage: {
      type: String,
      default: "Loading results...",
    },
    maxHeight: [Number, String],
    maxWidth: {
      type: [Number, String],
      default: "100%",
    },
    multi: Boolean,
    noFoundMessage: {
      type: String,
      default: "No matching results found",
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    rowsText: {
      type: String,
      default: "Rows per page:",
    },
    search: String,
    searchColumns: {
      type: Array,
      default: () => [],
    },
    selectable: Boolean,
    sm: Boolean,
    sortField: String,
    sortOrder: String,
    striped: Boolean,
    tag: {
      type: String,
      default: "div",
    },
  },
  emits: [
    "render",
    "selected-rows",
    "selected-indexes",
    "all-selected",
    "row-click",
    "update",
  ],
  setup(props, { slots, emit }) {
    // Defaults
    const className = computed(() => [
      "datatable",
      props.color,
      props.bordered && "datatable-bordered",
      props.borderColor && `border-${props.borderColor}`,
      props.borderless && "datatable-borderless",
      props.clickableRows && "datatable-clickable-rows",
      props.dark && "datatable-dark",
      props.hover && "datatable-hover",
      props.loading && "datatable-loading",
      props.sm && "datatable-sm",
      props.striped && "datatable-striped",
    ]);
    const height = computed(() =>
      typeof props.maxHeight === "number"
        ? props.maxHeight + "px"
        : props.maxHeight
    );
    const width = computed(() =>
      typeof props.maxWidth === "number"
        ? props.maxWidth + "px"
        : props.maxWidth
    );
    const data = ref({});
    const rowsPerPage = ref(props.entries);
    const pageKey = ref(0);
    const pages = computed(() =>
      data.value.rows
        ? Math.ceil(data.value.rows.length / rowsPerPage.value)
        : 1
    );
    const firstRowIndex = computed(() =>
      data.value.rows ? pageKey.value * rowsPerPage.value + 1 : 1
    );
    const lastRowIndex = computed(() =>
      data.value.rows
        ? pageKey.value === pages.value - 1
          ? data.value.rows.length
          : pageKey.value * rowsPerPage.value + rowsPerPage.value
        : rowsPerPage.value
    );
    const selectOptions = ref(
      props.entriesOptions.map((entry) => {
        return {
          text: entry,
          value: entry,
          selected: entry === rowsPerPage.value,
        };
      })
    );
    const datatableKey = ref(0);
    let defaultData = {};

    // Getting data
    onMounted(() => {
      if (slots.default && slots.default()[0].type === "table") {
        getDataFromSlot(slots.default()[0]);
      } else {
        getDataFromProps();
      }
    });
    if (slots.default) {
      watch(
        () => slots.default(),
        () => {
          if (
            slots.default().length > 0 &&
            slots.default()[0].type === "table"
          ) {
            getDataFromSlot(slots.default()[0]);
          }
        }
      );
    } else {
      watch(
        () => props.dataset,
        () => {
          getDataFromProps();
          sort();
        },
        { deep: true }
      );
    }
    watch(
      () => rowsPerPage.value,
      () => {
        pageKey.value = 0;
        datatableKey.value++;
        nextTick(() => emit("render", data.value));
      }
    );

    // Setting data
    const setData = (columns, rows) => {
      data.value.columns = columns;
      data.value.rows = rows;

      setDefaultData(columns, rows);
      nextTick(() => emit("render", data.value));
    };
    const setDefaultData = (columns, rows) => {
      defaultData.columns = [...columns];
      defaultData.rows = [...rows];
    };
    const getGeneratedColumns = () => {
      if (props.dataset.columns[0].field) {
        return [...props.dataset.columns];
      } else {
        return props.dataset.columns.map((th) => {
          return {
            label: th,
            field: th.toLowerCase(),
          };
        });
      }
    };
    const getGeneratedRows = (columns) => {
      let rows = [];
      if (props.dataset.rows[0][columns[0].field]) {
        rows = props.dataset.rows.map((row, key) => ({
          ...row,
          mdbIndex: key,
          selected: false,
        }));
      } else {
        const rowsArr = props.dataset.rows.map((tr) => tr);
        rowsArr.forEach((row, key) => {
          rows.push({});
          row.forEach((td, tdKey) => {
            rows[key][columns[tdKey].field] = td;
            rows[key].mdbIndex = key;
            rows[key].selected = false;
          });
        });
      }

      return rows;
    };
    const setColMarginsAndFormats = (columns, formattedColumns) => {
      let colMarginLeft = -columns[0].width || 0;
      const colLeftMargins = columns.map((col) => {
        colMarginLeft += col.fixed ? col.width || 0 : 0;
        return colMarginLeft;
      });
      columns.forEach((col, key) => {
        if (col.fixed && col.fixed === "right") {
          col.right = true;
        }

        if ("format" in col) {
          formattedColumns.push({ field: col.field, rules: col.format.value });
        }

        col.left = colLeftMargins[key];
      });
    };
    const formatCells = (rows, formattedColumns) => {
      rows.forEach((row, key) => {
        row.formats = {};
        formattedColumns.forEach((col) => {
          row.formats[col.field] = col.rules[key];
        });
      });
    };
    const getDataFromSlot = (slot) => {
      const columns = slot.children[0].children[0].children.map((th) => {
        return {
          label: th.children,
          field: th.children.toLowerCase(),
          sort:
            th.props && th.props["data-mdb-sort"] === "false" ? false : true,
        };
      });
      const rows = [];
      let rowsObj = slot.children[1].children.map((tr) => tr.children);
      rowsObj.forEach((row, key) => {
        rows.push({});
        row.forEach((td, tdKey) => {
          rows[key][columns[tdKey].field] = td.children;
          rows[key].mdbIndex = key;
          rows[key].selected = false;
        });
      });

      setData(columns, rows);
    };
    const getDataFromProps = () => {
      let columns = [];
      let rows = [];

      if (props.dataset.columns && props.dataset.columns.length > 0) {
        columns = getGeneratedColumns();
      }
      if (props.dataset.rows && props.dataset.rows.length > 0) {
        rows = getGeneratedRows(columns);
      }

      // Formatting
      let formattedColumns = [];
      setColMarginsAndFormats(columns, formattedColumns);
      if (formattedColumns.length > 0) {
        formatCells(rows, formattedColumns);
      }

      setData(columns, rows);
    };

    // Sort
    const orderBy = ref(props.sortOrder || null);
    const orderKey = ref(props.sortField || null);
    const setOrderData = (order, key) => {
      orderBy.value = order;
      orderKey.value = key;
    };
    const sortAsc = () => {
      data.value.rows.sort((a, b) =>
        a[orderKey.value] > b[orderKey.value]
          ? 1
          : b[orderKey.value] > a[orderKey.value]
          ? -1
          : 0
      );
    };
    const sortDesc = () => {
      data.value.rows.sort((a, b) =>
        a[orderKey.value] < b[orderKey.value]
          ? 1
          : b[orderKey.value] < a[orderKey.value]
          ? -1
          : 0
      );
    };
    const sortAndFilter = (key) => {
      if (orderBy.value === null || orderKey.value !== key) {
        setOrderData("asc", key);
        sortAsc();
      } else if (orderBy.value === "asc" && orderKey.value === key) {
        setOrderData("desc", key);
        sortDesc();
      } else {
        setOrderData(null, null);
        data.value.rows = [...defaultData.rows];

        if (search.value) {
          filter();
        }
      }
      nextTick(() => emit("render", data.value));
    };
    const sort = () => {
      if (orderBy.value === "asc") {
        sortAsc();
      } else if (orderBy.value === "desc") {
        sortDesc();
      }
      nextTick(() => emit("render", data.value));
    };
    onMounted(() => {
      if (orderKey.value) {
        sort();
      }
    });

    // Search
    const search = ref("");
    const searchColumns = ref(props.searchColumns);
    const filter = () => {
      if (searchColumns.value.length > 0) {
        data.value.rows = defaultData.rows.filter((row) =>
          searchColumns.value
            .map((column) =>
              row[column].toString().toLowerCase().includes(search.value)
            )
            .some((value) => value === true)
        );
      } else {
        data.value.rows = defaultData.rows.filter((row) =>
          data.value.columns
            .map((column) =>
              row[column.field].toString().toLowerCase().includes(search.value)
            )
            .some((value) => value === true)
        );
      }
    };
    watch(
      () => props.search,
      (searchString) => {
        search.value = searchString.toLowerCase();
        if (searchString === "") {
          data.value.rows = defaultData.rows;
        } else {
          filter();
        }
        sort();
        pageKey.value = 0;
      }
    );
    watch(
      () => props.searchColumns,
      (searchCols) => {
        searchColumns.value = searchCols;
        filter();
        sort();
        pageKey.value = 0;
      }
    );

    // Select
    const selectedRows = computed(() =>
      data.value.rows.filter((row) => row.selected === true)
    );
    const selectedIndexes = computed(() =>
      selectedRows.value.map((row) => row.mdbIndex)
    );
    const allSelected = computed(
      () => selectedIndexes.value.length === data.value.rows.length
    );
    const allSelectedCheckbox = ref(false);
    const handleCheckboxChange = (rowId, rowChecked) => {
      if (!props.multi && rowChecked === false) {
        data.value.rows.forEach((row) => {
          if (row.mdbIndex !== rowId) {
            row.selected = false;
          }
        });
      }

      emitSelectedValues();
      updateAllSelectedCheckbox();
    };
    const toggleSelectAll = () => {
      if (allSelected.value) {
        data.value.rows.forEach((row) => (row.selected = false));
      } else {
        data.value.rows.forEach((row) => (row.selected = true));
      }

      emitSelectedValues();
    };
    const updateAllSelectedCheckbox = () => {
      nextTick(() => (allSelectedCheckbox.value = allSelected.value));
    };
    const emitSelectedValues = () => {
      nextTick(() => {
        emit("selected-rows", selectedRows.value);
        emit("selected-indexes", selectedIndexes.value);
        emit("all-selected", allSelected.value);
      });
    };

    // Events
    const handleRowClick = (index) => {
      emit("row-click", index);
    };
    const handleCellBlur = (event, rowIndex, field) => {
      if (props.edit) {
        data.value.rows[rowIndex][field] = event.target.innerHTML;
        nextTick(() => {
          sort();
          emit("update", data.value);
        });
      }
    };

    return {
      className,
      height,
      width,
      data,
      sortAndFilter,
      orderBy,
      orderKey,
      rowsPerPage,
      datatableKey,
      pageKey,
      pages,
      firstRowIndex,
      lastRowIndex,
      selectOptions,
      toggleSelectAll,
      handleCheckboxChange,
      allSelectedCheckbox,
      handleRowClick,
      handleCellBlur,
    };
  },
};
</script>
