<template>
  <component
    :is="tag"
    :class="className"
    ref="formRef"
    noValidate
    @submit="onSubmit"
  >
    <slot />
  </component>
</template>

<script>
import { computed, ref, toRefs, inject } from "vue";
import { validateStep } from "./stepper-validation";

export default {
  name: "MDBStepperForm",
  props: {
    tag: {
      type: String,
      default: "form",
    },
  },
  setup(props) {
    const { steps, activeStep } = toRefs(inject("state"));
    const linear = inject("linear");
    const setStepAttribute = inject("setStepAttribute");

    const formRef = ref(null);

    const className = computed(() => {
      return ["stepper-form", "needs-validation"];
    });

    const onSubmit = (e) => {
      const form = e.target;

      if (!form.checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
      }

      steps.value.forEach((el, i) => {
        setStepAttribute(i, "validated");
        validateStep(activeStep.value, steps.value, i, linear, true);
      });
    };

    return {
      formRef,
      className,
      onSubmit,
      props,
    };
  },
};
</script>
