<template>
  <div class="datepicker-date-controls">
    <button
      class="datepicker-view-change-button"
      :aria-label="switchToDayViewLabel"
      @click.stop="changeView('day')"
    >
      {{ activeDate.year() }}
    </button>
    <div class="datepicker-arrow-controls">
      <button
        class="datepicker-previous-button"
        :aria-label="prevYearLabel"
        @click="changeActiveYear(activeDate.year() - 1)"
      ></button>
      <button
        class="datepicker-next-button"
        :aria-label="nextYearLabel"
        @click="changeActiveYear(activeDate.year() + 1)"
      ></button>
    </div>
  </div>

  <div
    class="datepicker-view"
    ref="datepickerViewRef"
    @keydown.up.prevent="handleDecrementMonth(4)"
    @keydown.down.prevent="handleIncrementMonth(4)"
    @keydown.left.prevent="handleDecrementMonth(1)"
    @keydown.right.prevent="handleIncrementMonth(1)"
    @keydown.enter="handleEnter"
    @keydown="handleKeydown"
    @focus="isDatepickerViewFocused = true"
    @blur="isDatepickerViewFocused = false"
    tabindex="0"
  >
    <table class="datepicker-table">
      <tbody class="datepicker-table-body">
        <tr v-for="(monthRow, key) in months" :key="key">
          <td
            v-for="(month, key) in monthRow"
            :key="key"
            class="datepicker-cell datepicker-large-cell datepicker-month-cell"
            :class="[
              month.current && 'current',
              month.focused && isDatepickerViewFocused && 'focused',
              !month.selectable && 'disabled',
              month.selected && 'selected',
            ]"
            :aria-label="month.ariaLabel"
            @click.stop="
              () => {
                changeActiveMonth(month.key);
                changeView('day');
              }
            "
          >
            <div class="datepicker-cell-content datepicker-large-cell-content">
              {{ month.label }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject, computed, ref, onMounted } from "vue";

export default {
  name: "MDBDatepickerMonthView",
  setup() {
    const activeDate = inject("activeDate");
    const selectedDate = inject("selectedDate");
    const focusedDate = inject("focusedDate");
    const monthsShort = inject("monthsShort");
    const changeActiveYear = inject("changeActiveYear");
    const changeActiveMonth = inject("changeActiveMonth");
    const changeView = inject("changeView");
    const today = inject("today");
    const nextYearLabel = inject("nextYearLabel");
    const prevYearLabel = inject("prevYearLabel");
    const switchToDayViewLabel = inject("switchToDayViewLabel");

    const minDate = inject("minDate");
    const maxDate = inject("maxDate");

    const months = computed(() => {
      const list = [];

      for (let i = 0; i < 3; i++) {
        list.push([]);
        const rowStartingDate = activeDate.value
          .startOf("year")
          .add(i * 4, "month");

        for (let j = 0; j < 4; j++) {
          const colStartingDate = rowStartingDate.add(j, "month");
          list[i].push({
            ariaLabel: `${
              monthsShort[colStartingDate.month()]
            }, ${colStartingDate.year()}`,
            current:
              colStartingDate.month() === today.month() &&
              colStartingDate.year() === today.year(),
            focused: colStartingDate.isSame(focusedDate.value, "month"),
            key: colStartingDate.month(),
            label: monthsShort[colStartingDate.month()],
            selectable: isMonthSelectable(colStartingDate),
            selected:
              colStartingDate.isSame(selectedDate.value, "month") &&
              colStartingDate.isSame(selectedDate.value, "year"),
          });
        }
      }

      return list;
    });

    const isMonthSelectable = (month) => {
      if (minDate.value && maxDate.value) {
        return (
          month.isSameOrAfter(minDate.value, "month") &&
          month.isSameOrBefore(maxDate.value, "month") &&
          isRangeYearSelectable(month.year())
        );
      } else if (minDate.value && isMinYearSelectable(month.year())) {
        return month.isSameOrAfter(minDate.value, "month");
      } else if (maxDate.value && isMaxYearSelectable(month.year())) {
        return month.isSameOrBefore(maxDate.value, "month");
      }

      return true;
    };

    const isRangeYearSelectable = (year) => {
      if (year >= minDate.value.year() && year <= maxDate.value.year()) {
        return true;
      }

      return false;
    };
    const isMinYearSelectable = (year) => {
      if (year >= minDate.value.year()) {
        return true;
      }

      return false;
    };
    const isMaxYearSelectable = (year) => {
      if (year <= maxDate.value.year()) {
        return true;
      }

      return false;
    };

    // keyboard accessibility -------------------------------------
    const handleDecrementMonth = inject("handleDecrementMonth");
    const handleIncrementMonth = inject("handleIncrementMonth");
    const handleMonthHome = inject("handleMonthHome");
    const handleMonthEnd = inject("handleMonthEnd");
    const handleMonthPageUp = inject("handleMonthPageUp");
    const handleMonthPageDown = inject("handleMonthPageDown");
    const datepickerViewRef = ref(null);
    const isDatepickerViewFocused = ref(false);

    const handleEnter = () => {
      changeActiveMonth(focusedDate.value.month());
      changeView("day");
    };
    const handleKeydown = (event) => {
      switch (event.key) {
        case "Home":
          handleMonthHome();
          break;
        case "End":
          handleMonthEnd();
          break;
        case "PageUp":
          handleMonthPageUp();
          break;
        case "PageDown":
          handleMonthPageDown();
          break;
        default:
          break;
      }
    };
    onMounted(() => {
      setTimeout(() => {
        datepickerViewRef.value.focus();
      }, 100);
    });

    return {
      activeDate,
      changeView,
      changeActiveYear,
      changeActiveMonth,
      nextYearLabel,
      prevYearLabel,
      switchToDayViewLabel,
      months,
      datepickerViewRef,
      handleDecrementMonth,
      handleIncrementMonth,
      handleEnter,
      handleKeydown,
      isDatepickerViewFocused,
    };
  },
};
</script>
