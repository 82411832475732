<template>
  <component
    v-if="vertical"
    :is="tag"
    :style="heightStyle"
    :class="className"
    ref="contentRef"
  >
    <slot />
  </component>
  <MDBAnimation
    v-else-if="!vertical && (isActive || shouldHide)"
    :tag="tag"
    :class="className"
    trigger="onLoad"
    reset
    :animation="animation"
    :duration="800"
    ref="contentRef"
  >
    <slot />
  </MDBAnimation>
</template>

<script>
import MDBAnimation from "../../content-styles/MDBAnimation";
import { computed, inject, nextTick, onMounted, ref, toRefs, watch } from "vue";

export default {
  name: "MDBStepperContent",
  components: { MDBAnimation },
  props: {
    tag: {
      type: String,
      default: "div",
    },
  },
  setup() {
    const className = computed(() => {
      return [
        "stepper-content",
        "py-3",
        vertical.value && !isActive.value && "stepper-content-hide",
      ];
    });

    const height = ref("0px");
    const heightStyle = computed(() => ({
      height: height.value,
    }));

    const contentRef = ref(null);
    const { activeStep, prevStep } = toRefs(inject("state"));
    const stepIndex = inject("stepIndex", null);

    const shouldAnimate = ref(true);
    const shouldBeActive = computed(() => activeStep.value === stepIndex);
    const isActive = ref(shouldBeActive.value);
    const shouldHide = ref(null);

    const vertical = inject("vertical", false);

    const animation = computed(() => {
      return shouldHide.value
        ? activeStep.value > prevStep.value
          ? "slide-out-left"
          : activeStep.value < prevStep.value
          ? "slide-out-right"
          : ""
        : (prevStep.value || prevStep.value === 0) &&
          activeStep.value > prevStep.value
        ? "slide-in-right"
        : activeStep.value < prevStep.value
        ? "slide-in-left"
        : "";
    });

    const setHeight = () => {
      height.value = "100%";
    };

    watch(
      () => shouldBeActive.value,
      (cur) => {
        if (!vertical.value) {
          isActive.value = cur;
          return;
        }
        if (!cur) {
          const stepContent = contentRef.value;
          const stepHeight = stepContent.scrollHeight;

          contentRef.value.style.transition = "none";

          height.value = `${stepHeight}px`;
        }
        setTimeout(() => {
          contentRef.value.style.transition =
            "height 0.3s ease-in-out, margin-top 0.3s ease-in-out, margin-bottom 0.3s ease-in-out, padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out";
          isActive.value = cur;
        });
      }
    );

    watch(
      () => isActive.value,
      (cur) => {
        if (vertical.value && cur) {
          setTimeout(setHeight, 250);
        }
      }
    );

    watch(
      () => vertical.value,
      (cur) => {
        nextTick(() => {
          if (cur && contentRef.value) {
            setTimeout(setHeight, 250);
          }
        });
      }
    );

    watch(
      () => prevStep.value,
      (cur) => {
        if (vertical.value) {
          return;
        }
        if (contentRef.value && cur === stepIndex) {
          shouldHide.value = true;
          contentRef.value.startAnimation();

          setTimeout(() => {
            shouldHide.value = false;
          }, 800);
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      if (vertical.value) {
        setHeight();
      }
    });

    return {
      heightStyle,
      className,
      contentRef,
      isActive,
      activeStep,
      prevStep,
      stepIndex,
      animation,
      shouldHide,
      shouldAnimate,
      vertical,
    };
  },
};
</script>
