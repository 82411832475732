<template>
  <component
    :is="tag"
    :class="[
      'timepicker-clock',
      shouldClockAnimateOnShow && 'timepicker-clock-animation',
    ]"
    ref="clockRef"
    @mousedown="onMouseDown"
    @touchstart="onMouseDown"
    @mouseup="onMouseUp"
    @touchend="onMouseUp"
  >
    <span class="timepicker-middle-dot position-absolute"></span>
    <div
      :class="[
        'timepicker-hand-pointer',
        'position-absolute',
        handTransformClass,
      ]"
      ref="handRef"
      :style="handStyle"
    >
      <div class="timepicker-circle position-absolute"></div>
    </div>

    <span
      :class="[
        tipClassName,
        tip.active && activeClass,
        tip.disabled && 'disabled',
      ]"
      v-for="(tip, key) in outerCircleValues"
      :key="key"
      :style="tip.style"
      :ref="setTipRef"
    >
      <span class="timepicker-tips-element">{{ tip.value }}</span>
    </span>
    <div v-if="double" class="timepicker-clock-inner" ref="innerClockRef">
      <span
        :class="[
          'timepicker-time-tips-inner',
          tip.active && activeClass,
          tip.disabled && 'disabled',
        ]"
        v-for="(tip, key) in innerCircleValues"
        :key="key"
        :style="tip.style"
        :ref="setTipRef"
      >
        <span class="timepicker-tips-inner-element">{{ tip.value }}</span>
      </span>
    </div>
  </component>
</template>

<script>
import { computed, onMounted, ref, inject, onUnmounted, nextTick } from "vue";
import { offMulti, onMulti, on, off } from "../../../utils/MDBEventHandlers";

export default {
  name: "MDBTimepickerClock",
  props: {
    tag: {
      type: String,
      default: "div",
    },
    unitsMode: String,
    max: {
      type: Number,
    },
    min: {
      type: Number,
    },
    rotate: {
      type: Number,
      default: 0,
    },
    increment: Boolean,
    modelValue: [Number, String],
    angle: [Number, String],
    double: Boolean,
    size: {
      type: [Number, String],
      default: 260,
    },
    allowedValues: Function,
  },
  emits: ["input", "change", "update:angle"],
  setup(props, { emit }) {
    // ------------- REFS -------------
    const clockRef = ref("clockRef");
    const innerClockRef = ref("innerClockRef");
    const handRef = ref("handRef");
    const tipRef = ref([]);

    const setTipRef = (el) => {
      if (el) {
        tipRef.value.push(el);
      }
    };

    // ------------- STYLES -------------
    const tipClassName = computed(() => {
      return [`timepicker-time-tips-${props.unitsMode}`];
    });

    const activeClass = ref("");

    const handAngle = ref(props.angle);
    const firstOpen = ref(true);

    const handStyle = computed(() => {
      /* eslint-disable */
      //  needed for case when hand should be animated between hours and minutes values
      if (!firstOpen.value) {
        handAngle.value =
          props.rotate + degreesPerUnit.value * (props.modelValue - props.min);
        emit("update:angle", handAngle.value);

        // setTimeout is mandatory for hand rotation animation
        setTimeout(() => {
          activeClass.value = "active";
        }, 400);
      } else {
        nextTick(() => {
          firstOpen.value = false;
        });
      }

      return {
        transform: `rotateZ(${handAngle.value}deg)`,
        height: handScale.value
      };
      /* eslint-enable */
    });

    const handTransformClass = ref("timepicker-transform");

    const shouldClockAnimateOnShow = inject("shouldClockAnimateOnShow", null);
    const setClockAnimateOnShow = inject("setClockAnimateOnShow", null);

    // ------------- STATE MANAGEMENT -------------
    const displayedValue = computed(() => {
      return props.modelValue === null
        ? props.outerCircle[0]
        : parseInt(props.modelValue, 10);
    });

    // ------------- CLOCK GEOMETRY -------------
    const outerClockSize = ref(null);
    const innerClockSize = ref(null);

    const getCircleGeometry = (circleSize) => {
      const width = (circleSize - 32) / 2;
      const radius = width - 4;

      return {
        width,
        height: width,
        radius,
      };
    };

    const elementCount = computed(() => {
      return props.max - props.min + 1;
    });

    const roundElementCount = computed(() => {
      return props.double ? elementCount.value / 2 : elementCount.value;
    });

    const degreesPerUnit = computed(() => {
      return 360 / roundElementCount.value;
    });

    const handScale = computed(() => {
      return props.double &&
        (props.modelValue > 12 || props.modelValue === "00")
        ? `21.5%`
        : `calc(40% - 1px)`;
    });

    // ------------- GENERATE CLOCK ITEMS -------------

    const radian = (el) => {
      return el * (Math.PI / 180);
    };

    const getStyle = (tipIndex, circleSize) => {
      const { x, y } = getPosition(tipIndex, circleSize);
      return `left: ${x}; bottom: ${y}`;
    };

    const getPosition = (tipIndex, circleSize) => {
      const angle = radian(tipIndex * degreesPerUnit.value);
      const { width, height, radius } = getCircleGeometry(circleSize);

      const result = {
        x: `${width + Math.sin(angle) * radius}px`,
        y: `${height + Math.cos(angle) * radius}px`,
      };
      return result;
    };

    const outerCircleValues = computed(() => {
      if (props.double) {
        return tipsValues(1, 12);
      }
      return tipsValues(props.min, props.max);
    });

    const innerCircleValues = computed(() => {
      if (props.double) {
        return tipsValues(13, 24);
      }
      return null;
    });

    const setClockSizes = () => {
      if (props.double) {
        innerClockSize.value = innerClockRef.value.offsetWidth;
      }
      outerClockSize.value = clockRef.value.offsetWidth;
    };

    const tipsValues = (min, max) => {
      setClockSizes();

      const circleSize =
        props.double && max === 24
          ? innerClockSize.value
          : outerClockSize.value;

      const list = [];
      const increment = props.unitsMode === "hours" ? 1 : 5;

      for (let tipIndex = min; tipIndex <= max; tipIndex += increment) {
        list.push({
          value: tipIndex === 24 ? `00` : formatTimeValue(tipIndex),
          active: tipIndex === displayedValue.value,
          disabled: !isAllowed(tipIndex),
          style: getStyle(tipIndex, circleSize),
        });
      }
      return list;
    };

    // ------------- HANDLE TIME CHANGE -------------
    const isDragging = ref(false);

    const onMouseDown = (e) => {
      isDragging.value = true;
      e.preventDefault();

      // case for @click
      onDragMove(e);

      on(document, "mouseup", onMouseUp);
      // case for moving over clock plate
      onMulti(
        document,
        "mouseup mousemove mouseleave mouseover touchstart touchmove touchend",
        onDragMove
      );
    };

    const onMouseUp = (e) => {
      e.preventDefault();
      isDragging.value = false;

      emit("change");

      offMulti(
        document,
        "mouseup mousemove mouseleave mouseover touchstart touchmove touchend",
        onDragMove
      );
    };

    const onDragMove = (e) => {
      if (!isDragging.value || !clockRef.value) return;

      const { width, top, left } = clockRef.value.getBoundingClientRect();
      const { clientX, clientY } = "touches" in e ? e.touches[0] : e;
      const center = { x: width / 2, y: -width / 2 };
      const coords = { x: clientX - left, y: top - clientY };
      const handAngle =
        Math.round(angle(center, coords) - props.rotate + 360) % 360;

      const { radius: outerRadius } = getCircleGeometry(outerClockSize.value);
      let innerRadius = null;

      if (props.double) {
        const { radius } = getCircleGeometry(innerClockSize.value);

        innerRadius = radius;
      }

      const insideClick =
        props.double &&
        euclidean(center, coords) < (outerRadius + innerRadius) / 2 - 16;

      const value =
        Math.round(handAngle / degreesPerUnit.value) +
        props.min +
        (insideClick ? roundElementCount.value : 0);

      // Necessary to fix edge case when selecting left part of max value
      if (handAngle >= 360 - degreesPerUnit.value / 2) {
        update(insideClick ? props.max : props.min);
      } else {
        update(value);
      }
    };

    const update = (value) => {
      if (props.modelValue !== value && isAllowed(value)) {
        emit("input", value);
      }
    };
    const euclidean = (p0, p1) => {
      const dx = p1.x - p0.x;
      const dy = p1.y - p0.y;

      return Math.sqrt(dx * dx + dy * dy);
    };
    const angle = (center, p1) => {
      const value =
        2 *
        Math.atan2(p1.y - center.y - euclidean(center, p1), p1.x - center.x);
      return Math.abs((value * 180) / Math.PI);
    };

    // ------------- UTILITIES -------------

    const isAllowed = (value) => {
      return !props.allowedValues || props.allowedValues(value);
    };

    const formatTimeValue = (value) => {
      return props.unitsMode === "minutes" && value < 10 ? `0${value}` : value;
    };

    // ------------- LIFECYCLE HOOKS -------------
    onMounted(() => {
      setTimeout(() => {
        setClockAnimateOnShow(false);
        handTransformClass.value = "";
      }, 400);
    });

    onUnmounted(() => {
      off(document, "mouseup", onMouseUp);
      offMulti(
        document,
        "mouseup mousemove mouseleave mouseover touchstart touchmove touchend",
        onDragMove
      );
    });

    return {
      clockRef,
      innerClockRef,
      handRef,
      setTipRef,
      tipClassName,
      activeClass,
      shouldClockAnimateOnShow,
      handTransformClass,
      handStyle,
      outerCircleValues,
      innerCircleValues,
      displayedValue,
      onMouseDown,
      onMouseUp,
      props,
    };
  },
};
</script>
