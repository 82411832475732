<template>
  <teleport to="body" v-if="appendToBody">
    <component
      :id="uid"
      ref="toastRef"
      :is="tag"
      class=""
      :class="className"
      :style="[displayStyle, widthStyle, alignmentStyle]"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      :data-mdb-stacking="stacking ? true : null"
      :data-mdb-static="props.static ? true : null"
      v-bind="$attrs"
    >
      <div :class="headerClassName">
        <i v-if="icon" :class="icon" />
        <strong class="me-auto"><slot name="title" /></strong>
        <small><slot name="small" /></small>
        <button
          type="button"
          :class="btnClassName"
          aria-label="Close"
          @click="hide"
        ></button>
      </div>
      <div :class="bodyClassName"><slot /></div>
    </component>
  </teleport>
  <component
    :id="uid"
    ref="toastRef"
    v-else
    :is="tag"
    class=""
    :class="className"
    :style="[displayStyle, widthStyle, alignmentStyle]"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    :data-mdb-stacking="stacking ? true : null"
    v-bind="$attrs"
  >
    <div :class="headerClassName">
      <i v-if="icon" :class="icon" />
      <strong class="me-auto"><slot name="title" /></strong>
      <small><slot name="small" /></small>
      <button
        type="button"
        :class="btnClassName"
        aria-label="Close"
        @click="hide"
      ></button>
    </div>
    <div :class="bodyClassName"><slot /></div>
  </component>
</template>

<script>
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import { on, off } from "@/components/utils/MDBEventHandlers";
import { getUID } from "@/components/utils/getUID";
import MDBStackableElements from "@/components/utils/MDBStackableElements";

export default {
  name: "MDBToast",
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "div",
    },
    modelValue: Boolean,
    offset: {
      type: String,
      default: "10",
    },
    position: {
      type: String,
      default: "top-right",
    },
    width: {
      type: [String, null],
      default: null,
    },
    color: {
      type: String,
    },
    container: String,
    autohide: {
      type: Boolean,
      default: true,
    },
    animation: {
      type: Boolean,
      default: true,
    },
    delay: {
      type: [Number, String],
      default: 5000,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    stacking: {
      type: Boolean,
      default: true,
    },
    text: String,
    static: {
      type: Boolean,
      default: false,
    },
    icon: String,
  },
  emits: ["update:modelValue", "show", "shown", "hide", "hidden"],
  setup(props, { emit }) {
    // -------------- Classes and Styles --------------
    const className = computed(() => {
      return [
        "toast",
        props.animation && "fade",
        "mx-auto",
        props.color && `bg-${props.color}`,
        toastPositionClasses.value,
        showClass.value && "show",
      ];
    });
    const toastPositionClasses = computed(() => {
      if (props.static) return;
      return props.container ? "toast-absolute" : "toast-fixed";
    });
    const headerClassName = computed(() => {
      return [
        "toast-header",
        props.color && `bg-${props.color}`,
        props.text && `text-${props.text}`,
      ];
    });
    const btnClassName = computed(() => {
      return ["btn-close", props.text === "white" ? "btn-close-white" : null];
    });
    const bodyClassName = computed(() => {
      return [
        "toast-body",
        props.text === "white" ? "text-white" : null,
        "text-start",
      ];
    });
    const widthStyle = computed(() => `width: ${props.width}`);
    const alignmentStyle = ref(null);
    const displayStyle = ref(null);
    const showClass = ref(props.static ? true : false);

    const uid = props.id || getUID("MDBToast-");

    // -------------- Refs --------------
    const toastRef = ref(null);

    // -------------- Positioning --------------
    const verticalOffset = () => {
      if (!props.stacking || !props.position) return 0;

      return calculateStackingOffset();
    };

    const getPosition = () => {
      if (!props.position) return null;
      const [y, x] = props.position.split("-");
      return { y, x };
    };

    const updatePosition = () => {
      const { y } = getPosition();
      const offsetY = verticalOffset();

      //  quick update vertical position for stack placement
      toastRef.value.style[y] = `${
        parseInt(offsetY) + parseInt(props.offset)
      }px`;
      // update alignmentStyle value
      // without that toastRef.value.style[y] will be overwritten on hide by alignementStyle
      setupAlignment();
    };

    const setupAlignment = () => {
      const offsetY = verticalOffset();
      const position = getPosition();

      const oppositeY = position.y === "top" ? "bottom" : "top";
      const oppositeX = position.x === "left" ? "right" : "left";
      if (position.x === "center") {
        alignmentStyle.value = {
          [position.y]: `${parseInt(offsetY) + parseInt(props.offset)}px`,
          [oppositeY]: "unset",
          left: "50%",
          transform: "translate(-50%)",
        };
      } else {
        alignmentStyle.value = {
          [position.y]: `${parseInt(offsetY) + parseInt(props.offset)}px`,
          [position.x]: `${props.offset}px`,
          [oppositeY]: "unset",
          [oppositeX]: "unset",
          transform: "unset",
        };
      }
    };

    watch(
      () => props.position,
      () => setupAlignment()
    );

    watch(
      () => props.offset,
      () => setupAlignment()
    );

    // -------------- Stacking --------------
    const {
      setStack,
      calculateStackingOffset,
      nextStackElements,
      resetStackingOffset,
    } = MDBStackableElements();
    const observer = ref(null);

    const setupStacking = () => {
      setStack(toastRef, toastRef.value, ".toast", {
        position: getPosition().y,
        offset: props.offset,
        container: props.container,
        filter: (el) => {
          return el.dataset.mdbStacking && !el.dataset.mdbStatic;
        },
      });

      observerStacking();
    };

    // MutationObserver is a workaround for Vue not being able to communicate
    const observerStacking = () => {
      observer.value = new MutationObserver((mutations) => {
        for (const m of mutations) {
          const newValue = m.target.getAttribute(m.attributeName);
          nextTick(() => {
            onShouldStackUpdate(newValue, m.oldValue);
          });
        }
      });

      observer.value.observe(toastRef.value, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ["class"],
      });
    };

    const updateToastStack = () => {
      const nextElements = nextStackElements();
      if (nextElements.length <= 0) {
        return;
      }
      nextElements.forEach((el) => {
        if (el.id !== uid.value) {
          el.classList.add("should-stack-update");
        }
      });
    };

    // MutationObserver
    // will fire on component class change
    const onShouldStackUpdate = (classAttrValue) => {
      const classList = classAttrValue.split(" ");
      if (classList.includes("should-stack-update")) {
        updatePosition();
        toastRef.value.classList.remove("should-stack-update");
      }
    };

    // -------------- Open/Close --------------
    const isActive = ref(props.modelValue);
    const timeoutValue = ref(null);

    watchEffect(() => {
      isActive.value = props.modelValue;
    });

    const openToast = () => {
      emit("show");
      _clearTimeout();
      setupAlignment();

      displayStyle.value = "display: block";

      const complete = () => {
        emit("shown");
        off(toastRef.value, "transitionend", complete);

        if (props.autohide) {
          timeoutValue.value = setTimeout(hide, props.delay);
        }
      };

      nextTick(() => {
        setTimeout(() => {
          showClass.value = true;
        }, 0);

        if (props.animation) {
          on(toastRef.value, "transitionend", complete);
        } else {
          complete();
        }
      });
    };

    const closeToast = () => {
      emit("hide");

      const complete = () => {
        displayStyle.value = "display: none";
        alignmentStyle.value = null;

        emit("hidden");
        off(toastRef.value, "transitionend", complete);

        if (props.stacking) {
          updateToastStack();
        }
      };

      showClass.value = false;

      if (props.stacking && !props.static) {
        resetStackingOffset();
      }

      if (props.animation) {
        on(toastRef.value, "transitionend", complete);
      } else {
        complete();
      }
    };

    watch(
      () => isActive.value,
      (isActive) => {
        if (isActive) {
          openToast();
        } else {
          closeToast();
        }
      }
    );

    const show = () => {
      emit("update:modelValue", true);
    };

    const hide = () => {
      if (props.autohide && !timeoutValue.value) return;
      emit("update:modelValue", false);
    };

    const _clearTimeout = () => {
      clearTimeout(timeoutValue.value);
      timeoutValue.value = null;
    };

    // -------------- Lifecycle Hooks --------------
    onMounted(() => {
      if (!props.modelValue) {
        displayStyle.value = "display: none";
      }
      if (props.container) {
        const containerEl = document.querySelector(props.container);
        if (!containerEl) return;

        containerEl.classList.add("parent-toast-relative");
      }

      if (props.stacking) {
        setupStacking();
      }
    });

    onUnmounted(() => {
      _clearTimeout();
      observer.value.disconnect();
    });

    return {
      uid,
      className,
      headerClassName,
      btnClassName,
      bodyClassName,
      widthStyle,
      alignmentStyle,
      displayStyle,
      toastRef,
      isActive,
      show,
      hide,
      props,
    };
  },
};
</script>
