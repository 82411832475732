<template>
  <teleport to="body">
    <transition-group
      name="datepicker-fade"
      @before-enter="beforeEnter"
      @after-leave="afterLeave"
    >
      <div v-if="isActive" class="datepicker-backdrop"></div>
      <div
        v-if="isActive"
        class="datepicker-modal-container"
        v-mdb-click-outside="handleClickOutside"
      >
        <MDBDatepickerHeader />
        <MDBDatepickerMain />
      </div>
    </transition-group>
  </teleport>
</template>

<script>
import { ref, watch } from "vue";
import mdbClickOutside from "@/directives/free/mdbClickOutside";
import MDBDatepickerHeader from "./MDBDatepickerHeader";
import MDBDatepickerMain from "./MDBDatepickerMain";

export default {
  name: "MDBDatepickerModal",
  props: {
    modelValue: Boolean,
  },
  components: {
    MDBDatepickerHeader,
    MDBDatepickerMain,
  },
  directives: {
    mdbClickOutside,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isActive = ref(props.modelValue);

    const handleClickOutside = () => {
      isActive.value = false;
      emit("update:modelValue", false);
    };

    // Animation
    const defaultPaddingRight = document.body.style.paddingRight;
    const getScrollbarWidth = () => {
      const scrollDiv = document.createElement("div");
      scrollDiv.className = "modal-scrollbar-measure";
      document.body.appendChild(scrollDiv);
      const scrollbarWidth =
        scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
      return scrollbarWidth;
    };
    const beforeEnter = () => {
      if (document.body.scrollHeight > window.innerHeight) {
        document.body.classList.add("datepicker-open");
        const paddingRightWhenActive = getScrollbarWidth().toFixed(2);
        if (paddingRightWhenActive > 0) {
          document.body.style.paddingRight = `${paddingRightWhenActive}px`;
        }
      }
    };
    const afterLeave = () => {
      document.body.classList.remove("datepicker-open");
      document.body.style.paddingRight = defaultPaddingRight;
    };

    watch(
      () => props.modelValue,
      (value) => (isActive.value = value)
    );

    return {
      isActive,
      handleClickOutside,
      beforeEnter,
      afterLeave,
    };
  },
};
</script>
