<template>
  <router-link
    v-if="to"
    :class="className"
    :exact="exact"
    :to="to"
    :target="tab"
    active-class="active"
    exact-active-class="exact-active"
    tabindex="0"
    @keydown="handleKeyDown"
    ref="sidenavLink"
    v-mdb-ripple="rippleConfig"
    @click="toggleActive"
    :id="uid"
    v-bind="$attrs"
  >
    <slot />
    <MDBIcon
      v-if="icon"
      :icon="icon"
      :iconStyle="iconStyle"
      :size="iconSize"
      :flag="iconFlag"
      :class="iconClasses"
      :style="iconTransform"
    />
  </router-link>
  <component
    v-else
    :is="tag"
    :href="href"
    :class="className"
    :target="tab"
    tabindex="0"
    @keydown="handleKeyDown"
    ref="sidenavLink"
    v-mdb-ripple="rippleConfig"
    @click="toggleActive"
    :id="uid"
    v-bind="$attrs"
  >
    <slot />
    <MDBIcon
      v-if="icon"
      :icon="icon"
      :iconStyle="iconStyle"
      :size="iconSize"
      :flag="iconFlag"
      :class="iconClasses"
      :style="iconTransform"
    />
  </component>
</template>

<script>
import { computed, inject, onMounted, ref, watchEffect } from "vue";
import MDBIcon from "../../free/content-styles/MDBIcon";
import mdbRipple from "@/directives/free/mdbRipple";
import { getUID } from "@/components/utils/getUID";

export default {
  name: "MDBSideNavLink",
  inheritAttrs: false,
  components: {
    MDBIcon,
  },
  directives: { mdbRipple },
  props: {
    tag: {
      type: String,
      default: "a",
    },
    href: String,
    to: [String, Object],
    exact: {
      type: Boolean,
      default: false,
    },
    newTab: {
      type: Boolean,
      default: false,
    },
    classes: String,
    icon: {
      type: [Boolean, String],
      default: false,
    },
    iconStyle: String,
    iconSize: String,
    iconFlag: String,
    iconClass: String,
    modelValue: Boolean,
    ripple: {
      type: [Object, Boolean],
      default: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const uid = ref(props.id || getUID("MDBSideNavLink-"));
    const sidenavLink = ref("sidenavLink");
    const hasCollapsibleContent = inject("hasCollapsibleContent", false);
    const activeNode = inject("activeNode", false);
    const setActiveNode = inject("setActiveNode", false);
    const activeClass = computed(() => activeNode.value === uid.value);

    const className = computed(() => {
      return [
        "sidenav-link",
        activeClass.value ? "active" : null,
        props.classes,
      ];
    });

    const isOpened = ref(props.modelValue);
    watchEffect(() => {
      isOpened.value = props.modelValue;
    });

    const toggleActive = () => {
      if (hasCollapsibleContent.value) {
        emit("update:modelValue", !props.modelValue);
      } else {
        isOpened.value = true;
      }
      setActiveNode(uid.value, sidenavLink.value);
    };

    const tab = computed(() => {
      if (props.newTab) {
        return "_blank";
      }

      return false;
    });

    const iconClasses = computed(() => {
      return ["rotate-icon", props.iconClass];
    });

    const rotateIcon = ref(props.modelValue);
    watchEffect(() => (rotateIcon.value = props.modelValue));

    const iconTransform = computed(() => {
      return { transform: `rotate(${isOpened.value ? 180 : 0}deg)` };
    });

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        if (hasCollapsibleContent.value) {
          emit("update:modelValue", !props.modelValue);
        } else {
          toggleActive();
        }
      }
    };

    const openCollapse = inject("openCollapse", false);

    const openParentCollapse = () => {
      if (openCollapse) {
        openCollapse();
        emit("update:modelValue", true);
      }
    };

    const rippleConfig = ref({
      color: inject("sidenavColor", "primary"),
      ...props.ripple,
    });

    onMounted(() => {
      if (
        window.location.href === props.href ||
        (window.location.hash &&
          window.location.hash.substring(1) === props.to) ||
        (window.location.pathname !== "/" &&
          window.location.pathname === props.to)
      ) {
        toggleActive();
        openParentCollapse();
      }
    });

    return {
      sidenavLink,
      className,
      iconClasses,
      iconTransform,
      tab,
      props,
      handleKeyDown,
      rippleConfig,
      toggleActive,
      isOpened,
      uid,
    };
  },
};
</script>
