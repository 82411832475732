<template>
  <component
    :is="tag"
    ref="ratingRef"
    :class="className"
    @mouseleave="setHoveredRating(null, 'leave')"
  >
    <slot />
  </component>
</template>

<script>
import { computed, provide, reactive, ref, watchEffect } from "vue";
export default {
  name: "MDBRating",
  props: {
    tag: {
      type: String,
      default: "ul",
    },
    modelValue: {
      type: Number,
      default: -1,
    },
    readonly: Boolean,
    events: Boolean,
    dynamic: Boolean,
    classes: String,
  },
  emits: ["update:modelValue", "item-click", "item-hover"],
  setup(props, { emit }) {
    const ratingRef = ref(null);

    const className = computed(() => {
      return ["rating", props.classes];
    });

    const activeItem = ref(props.modelValule);
    const setActiveRating = (rating, e) => {
      if (!e) {
        return;
      }
      emit("update:modelValue", rating);
      props.events && emit("item-click", e);
    };
    watchEffect(() => (activeItem.value = props.modelValue));

    const hoveredItem = ref(null);
    const setHoveredRating = (rating, e) => {
      if (!e) {
        return;
      }
      hoveredItem.value = rating;
      e !== "leave" && props.events && emit("item-hover", e);
    };

    const dynamicStyle = reactive({
      icon: "",
      color: "",
    });

    const setDynamic = (icon, color) => {
      dynamicStyle.icon = icon;
      dynamicStyle.color = color;
    };

    provide("activeItem", activeItem);
    provide("hoveredItem", hoveredItem);
    provide("setActiveRating", setActiveRating);
    provide("setHoveredRating", setHoveredRating);
    provide("readonly", props.readonly);
    provide("dynamic", props.dynamic);
    provide("dynamicStyle", dynamicStyle);
    provide("setDynamic", setDynamic);

    return {
      ratingRef,
      className,
      setHoveredRating,
      props,
    };
  },
};
</script>
