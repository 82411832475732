<template>
  <component :is="tag" v-bind="$attrs" ref="lightboxRef" :class="className">
    <slot />
  </component>
  <teleport to="body">
    <div
      class="lightbox-gallery"
      ref="galleryRef"
      :style="{
        opacity: isActive ? 1 : 0,
        pointerEvents: isActive ? 'initial' : 'none',
        visibility: isActive ? 'visible' : 'hidden',
      }"
      @mousemove="resetToolsToggler"
    >
      <div class="lightbox-gallery-loader" v-show="isLoading">
        <MDBSpinner grow color="light" />
      </div>
      <div class="lightbox-gallery-toolbar" :style="{ opacity: toolsOpacity }">
        <div class="lightbox-gallery-left-tools">
          <p class="lightbox-gallery-counter">
            {{ `${activeImgIndex + 1} / ${imgCount}` }}
          </p>
        </div>
        <div class="lightbox-gallery-right-tools">
          <button
            :class="[
              'lightbox-gallery-fullscreen-btn',
              fullscreen && 'active',
              fontAwesome === 'pro' && 'fontawesome-pro',
            ]"
            @click="toggleFullscreen"
            aria-label="Toggle fullscreen"
          ></button>
          <button
            :class="[
              'lightbox-gallery-zoom-btn',
              fontAwesome === 'pro' && 'fontawesome-pro',
              zoom > 1 && 'active',
            ]"
            :aria-label="zoom > 1 ? 'Zoom in' : 'Zoom out'"
            @click="toggleZoom"
          ></button>
          <button
            :class="[
              'lightbox-gallery-close-btn',
              fontAwesome === 'pro' && 'fontawesome-pro',
            ]"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div class="lightbox-gallery-content">
        <div
          v-for="(img, key) in images"
          :key="key"
          class="lightbox-gallery-image"
          :style="{
            position: 'absolute',
            top: '0px',
            left: `${img.left}%`,
            opacity: img.opacity,
            transform: `scale(${img.scale})`,
          }"
        >
          <img
            :alt="img.alt || `Lightbox image ${img.id + 1}`"
            draggable="false"
            :class="[img.active ? 'active' : null, 'lightbox-image']"
            :src="img.src"
            :ref="(el) => setRef(el, key)"
            @wheel="handleImgScroll"
            @mouseup="handleMouseup"
            @mousemove="handleMousemove"
            @mousedown="handleMousedown"
            @touchend="(e) => handleMouseup(e, true)"
            @touchmove="handleMousemove"
            @touchstart="handleMousedown"
            @dblclick="handleDoubleClick"
          />
        </div>
      </div>
      <div
        class="lightbox-gallery-arrow-left"
        :style="{ opacity: toolsOpacity }"
      >
        <button
          aria-label="Previous"
          @click="slide('left')"
          :class="[fontAwesome === 'pro' && 'fontawesome-pro']"
        ></button>
      </div>
      <div
        class="lightbox-gallery-arrow-right"
        :style="{ opacity: toolsOpacity }"
      >
        <button
          aria-label="Next"
          @click="slide()"
          :class="[fontAwesome === 'pro' && 'fontawesome-pro']"
        ></button>
      </div>
      <div class="lightbox-gallery-caption-wrapper">
        <p class="lightbox-gallery-caption">
          {{ caption }}
        </p>
      </div>
    </div>
  </teleport>
</template>

<script>
import {
  computed,
  nextTick,
  onBeforeUpdate,
  onMounted,
  onUnmounted,
  provide,
  reactive,
  ref,
  watch,
} from "vue";
import MDBSpinner from "@/components/free/components/MDBSpinner";
import { on, off } from "@/components/utils/MDBEventHandlers";

export default {
  name: "MDBLightbox",
  components: {
    MDBSpinner,
  },
  props: {
    tag: {
      type: String,
      default: "div",
    },
    zoomLevel: {
      type: [Number, String],
      default: 1,
    },
    fontAwesome: {
      type: String,
      default: "free",
    },
  },
  emits: [
    "close",
    "closed",
    "open",
    "opened",
    "slide",
    "slided",
    "zoom-in",
    "zoomed-in",
    "zoom-out",
    "zoomed-out",
  ],
  setup(props, { emit }) {
    const lightboxRef = ref(null);
    const galleryRef = ref(null);

    const className = computed(() => {
      return ["lightbox"];
    });

    const isActive = ref(false);
    const activeImgIndex = ref(-1);
    const initialImagesArray = ref([]);
    const images = ref([]);
    const fullscreen = ref(false);

    provide("initialImagesArray", initialImagesArray);

    // ------------- image handling -------------

    const setRef = (el, key) => {
      images.value[key].ref = el;
    };

    const caption = ref("");

    watch(
      () => activeImgIndex.value,
      () => {
        nextTick(() => {
          const currentImg = getCurrentImg();

          caption.value =
            currentImg.caption ||
            currentImg.alt ||
            `Lightbox image ${currentImg.id + 1}`;
        });
      }
    );

    const resetImagesArrays = () => {
      initialImagesArray.value = [];
      images.value = [];
      imgCount.value = 0;
    };

    const queryImages = () => {
      resetImagesArrays();

      const queryImages = lightboxRef.value.querySelectorAll("img");
      queryImages.forEach((img) => {
        addImg(img);
      });

      getImages();
    };

    const updateImages = () => {
      queryImages();
    };

    const imgCount = ref(0);
    const addImg = (img) => {
      const {
        lightboxThumbnail,
        lightboxSrc,
        lightboxCaption,
        lightboxDisabled,
      } = img.dataset;

      img = {
        id: imgCount.value,
        refId: img.id,
        left: 0,
        opacity: 1,
        scale: 0.25,
        active: false,
        thumbnail: lightboxThumbnail,
        src: lightboxSrc || lightboxThumbnail,
        alt: img.alt,
        caption: lightboxCaption,
        disabled: lightboxDisabled,
        ref: img,
      };

      if (img.disabled) {
        return;
      }

      initialImagesArray.value.push(img);
      images.value.push(img);

      imgCount.value++;
    };

    const setActiveImg = (imgId) => {
      activeImgIndex.value = imgId;
    };

    const setImgStyle = (img, key) => {
      if (img.left === 0) {
        img.active = true;
        img.opacity = 1;
        img.scale = 1;
      } else {
        img.active = false;
        img.opacity = 0;
      }

      if (key === imgCount.value - 1 && imgCount.value > 1) {
        img.left = -100;
      }
    };

    const calculateImgSize = (img) => {
      if (img.width >= img.height) {
        img.style.width = "100%";
        img.style.maxWidth = "100%";
        img.style.height = "auto";
        img.style.top = `${(img.parentNode.offsetHeight - img.height) / 2}px`;
        img.style.left = 0;
      } else {
        img.style.height = "100%";
        img.style.maxHeight = "100%";
        img.style.width = "auto";
        img.style.left = `${(img.parentNode.offsetWidth - img.width) / 2}px`;
        img.style.top = 0;
      }

      if (img.width >= img.parentNode.offsetWidth) {
        img.style.width = `${img.parentNode.offsetWidth}px`;
        img.style.height = "auto";
        img.style.left = 0;
        img.style.top = `${(img.parentNode.offsetHeight - img.height) / 2}px`;
      }
      if (img.height >= img.parentNode.offsetHeight) {
        img.style.height = `${img.parentNode.offsetHeight}px`;
        img.style.width = "auto";
        img.style.top = 0;
        img.style.left = `${(img.parentNode.offsetWidth - img.width) / 2}px`;
      }
    };

    const getImages = () => {
      images.value = [...initialImagesArray.value];
    };

    const resetImagesValues = () => {
      images.value = images.value.map((img) => {
        return {
          ...img,
          left: 0,
          opacity: 0,
          active: false,
        };
      });
    };

    const getCurrentImg = () => {
      return images.value.filter((img) => img.left === 0)[0];
    };

    const sortImages = () => {
      for (let i = 0; i < activeImgIndex.value; i++) {
        images.value.push(images.value.shift());
      }
    };

    const resetImgLeftPosition = () => {
      images.value = images.value.map((img, i) => {
        return {
          ...img,
          left: 0 + 100 * i,
        };
      });
    };

    provide("addImg", addImg);

    // ------------- loader methods -------------
    const isLoading = ref(false);

    // ------------- tools methods -------------
    const toolsOpacity = ref(1);
    const toolsToggleTimer = ref(0);

    const resetToolsToggler = () => {
      toolsOpacity.value = 1;
      clearTimeout(toolsToggleTimer.value);
      setToolsToggleTimout();
    };

    const setToolsToggleTimout = () => {
      toolsToggleTimer.value = setTimeout(() => {
        toolsOpacity.value = 0;

        clearTimeout(toolsToggleTimer);
      }, 4000);
    };
    // ------------- scrollbar methods -------------
    const disableScroll = () => {
      document.body.classList.add("disabled-scroll");

      if (
        document.documentElement.scrollHeight >
        document.documentElement.clientHeight
      ) {
        document.body.classList.add("replace-scrollbar");
      }
    };

    const enableScroll = () => {
      setTimeout(() => {
        document.body.classList.remove("disabled-scroll");
        document.body.classList.remove("replace-scrollbar");
      }, 300);
    };

    // ------------- gallery methods -------------
    const toggleLightbox = (itemId = 0) => {
      if (itemId === -1 || isActive.value) {
        close();
      } else {
        open(itemId);
      }
    };

    const toggleFullscreen = () => {
      if (!fullscreen.value) {
        if (galleryRef.value?.requestFullscreen) {
          galleryRef.value.requestFullscreen();
        }

        fullscreen.value = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }

        fullscreen.value = false;
      }
    };
    provide("toggleLightbox", toggleLightbox);

    const open = (itemId = 0) => {
      emit("open");
      getImages();
      setActiveImg(itemId);
      sortImages();
      resetImgLeftPosition();
      isActive.value = true;

      nextTick(() => {
        images.value.forEach((img, key) => {
          setImgStyle(img, key);
          calculateImgSize(img.ref);
        });

        setTimeout(() => {
          images.value.forEach((img) => {
            if (img.left === 0) {
              img.scale = 1;
            }
          });
        }, 50);

        disableScroll();
        setToolsToggleTimout();
        addEvents();
        emit("opened");
      });
    };

    const close = () => {
      if (isActive.value) {
        emit("close");
        if (fullscreen.value) {
          toggleFullscreen();
        }
        enableScroll();

        isActive.value = false;
        activeImgIndex.value = -1;
        emit("closed");
        removeEvents();

        // reset images for future rerender
        getImages();

        resetImagesValues();
      }
    };

    // ------------- image sliding -------------
    const isAnimating = ref(false);
    const animationStart = () => {
      isAnimating.value = true;
      setTimeout(() => {
        isAnimating.value = false;
      }, 400);
    };

    const slideHorizontally = (direction) => {
      images.value.forEach((img) => {
        let newPositionLeft;

        if (direction === 1) {
          newPositionLeft = img.left - 100;
          if (newPositionLeft < -100)
            newPositionLeft = (imgCount.value - 2) * 100;
        } else {
          newPositionLeft = img.left + 100;
          if (newPositionLeft === (imgCount.value - 1) * 100)
            newPositionLeft = -100;
        }

        if (newPositionLeft === 0) {
          img.opacity = 1;
          img.scale = 1;
          img.active = true;
          calculateImgSize(img.ref);
        } else {
          img.opacity = 0;
          img.scale = 0.25;
          img.active = false;
        }

        img.left = newPositionLeft;
      });
    };

    const slideToTarget = (target) => {
      if (target === 0 && activeImgIndex.value === 0) {
        return;
      }
      if (
        target === imgCount.value - 1 &&
        activeImgIndex.value === imgCount.value - 1
      ) {
        return;
      }

      isLoading.value = true;

      images.value.forEach((img) => {
        img.opacity = 0;
        img.scale = 0.25;
      });
      resetImagesValues();

      setTimeout(() => {
        getImages();
        setActiveImg(target === 0 ? 0 : imgCount.value - 1);
        sortImages();
        resetImgLeftPosition();

        nextTick(() => {
          calculateImgSize(getCurrentImg().ref);

          images.value.forEach((img, key) => {
            setImgStyle(img, key);
          });
          isLoading.value = false;
        });
      }, 400);
    };

    const slide = (direction = "right") => {
      if (isAnimating.value || imgCount.value <= 1) {
        return;
      }

      emit("slide");

      animationStart();
      resetDoubleTap();

      let change;

      switch (direction) {
        case "right":
          change = 1;
          break;
        case "left":
          change = -1;
          break;
        case "first":
          change = 0;
          break;
        case "last":
          change = imgCount.value - 1;
          break;

        default:
          break;
      }

      if (Math.abs(change) === 1) {
        slideHorizontally(change);
        let next =
          activeImgIndex.value + change < 0
            ? imgCount.value - 1
            : activeImgIndex.value + change > imgCount.value - 1
            ? 0
            : activeImgIndex.value + change;

        setActiveImg(next);
      } else {
        slideToTarget(change);
      }

      emit("slided");
    };

    // ------------- gestures -------------
    const originalPosition = reactive({
      x: 0,
      y: 0,
    });

    const position = reactive({
      x: 0,
      y: 0,
    });

    const mousedownPosition = reactive({
      x: 0,
      y: 0,
    });

    const doubleTapTimer = ref(0);
    const tapTime = ref(0);
    const tapCounter = ref(0);

    const checkDoubleTap = (e) => {
      clearTimeout(doubleTapTimer.value);
      const currentTime = new Date().getTime();
      const tapLength = currentTime - tapTime.value;

      if (tapCounter.value > 0 && tapLength < 500) {
        handleDoubleClick(e);
        doubleTapTimer.value = setTimeout(() => {
          tapTime.value = new Date().getTime();
          tapCounter.value = 0;
        }, 300);
      } else {
        tapCounter.value++;
        tapTime.value = new Date().getTime();
      }
    };

    const resetDoubleTap = () => {
      tapTime.value = 0;
      tapCounter.value = 0;
      clearTimeout(doubleTapTimer.value);
    };

    const handleDoubleClick = (e) => {
      if (!e.touches) setNewPositionOnZoomIn(e);
      if (zoom.value !== 1) {
        restoreDefaultZoom();
      } else {
        zoomIn();
      }
    };

    const mousedown = ref(false);

    const handleMousedown = (e) => {
      const touch = e.touches;
      const x = touch ? touch[0].clientX : e.clientX;
      const y = touch ? touch[0].clientY : e.clientY;

      const { ref: currentImg } = getCurrentImg();

      originalPosition.x = parseFloat(currentImg.style.left) || 0;
      originalPosition.y = parseFloat(currentImg.style.top) || 0;
      position.x = originalPosition.x;
      position.y = originalPosition.y;
      mousedownPosition.x = x * (1 / zoom.value) - position.x;
      mousedownPosition.y = y * (1 / zoom.value) - position.y;
      mousedown.value = true;
    };

    const handleMousemove = (e) => {
      if (!mousedown.value) return;

      const touch = e.touches;
      const x = touch ? touch[0].clientX : e.clientX;
      const y = touch ? touch[0].clientY : e.clientY;

      if (touch) resetToolsToggler();
      const { ref: currentImg } = getCurrentImg();

      if (zoom.value !== 1) {
        position.x = x * (1 / zoom.value) - mousedownPosition.x;
        position.y = y * (1 / zoom.value) - mousedownPosition.y;
        currentImg.style.left = `${position.x}px`;
        currentImg.style.top = `${position.y}px`;
      } else {
        if (images.value.length <= 1) return;
        position.x = x * (1 / zoom.value) - mousedownPosition.x;
        currentImg.style.left = `${position.x}px`;
      }
    };

    const handleMouseup = (e, touch) => {
      if (!mousedown.value) return;

      const moveX = position.x - originalPosition.x;
      mousedown.value = false;

      if (moveX !== 0) {
        moveImg(moveX);
      }

      if (touch) {
        checkDoubleTap(e);
      }
    };

    const moveImg = (movement) => {
      if (zoom.value !== 1 || imgCount.value <= 1) return;

      if (movement > 0) {
        if (isRTL) {
          slide();
        } else {
          slide("left");
        }
      } else if (movement < 0) {
        if (isRTL) {
          slide("left");
        } else {
          slide();
        }
      }
    };

    const handleWindowResize = () => {
      images.value.forEach((img) => {
        calculateImgSize(img.ref);
      });
    };

    const handleFullScreenChange = () => {
      const isFullscreenEnabled =
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement;
      if (isFullscreenEnabled === undefined) {
        fullscreen.value = false;
      }
    };

    // ------------- zoom -------------
    const zoom = ref(1);
    const zoomTimer = ref(null);

    const toggleZoom = () => {
      if (zoom.value !== 1) {
        restoreDefaultZoom();
      } else {
        zoomIn();
      }
    };

    const zoomIn = () => {
      if (zoom.value >= 3) return;
      emit("zoom-in");
      zoom.value += parseFloat(props.zoomLevel);
      getCurrentImg().scale = zoom.value;

      emit("zoomed-in");
    };

    const zoomOut = () => {
      if (zoom.value <= 1) return;
      emit("zoom-out");
      zoom.value -= parseFloat(props.zoomLevel);
      getCurrentImg().scale = zoom.value;

      emit("zoomed-out");
      if (zoom.value === 1) {
        restoreDefaultPosition();
      }
    };

    const restoreDefaultZoom = () => {
      if (zoom.value !== 1) {
        emit("zoom-out");
        zoom.value = 1;
        getCurrentImg().scale = 1;
        restoreDefaultPosition();
        emit("zoomed-out");
      }
    };

    const handleImgScroll = (e) => {
      if (e.deltaY > 0) {
        zoomOut();
      } else {
        if (zoom.value >= 3) return;
        setNewPositionOnZoomIn(e);
        zoomIn();
      }
    };

    const setNewPositionOnZoomIn = (e) => {
      clearTimeout(zoomTimer.value);
      const positionX = window.innerWidth / 2 - e.offsetX - 50;
      const positionY = window.innerHeight / 2 - e.offsetY - 50;

      const currentImg = getCurrentImg();

      currentImg.ref.style.transition = "all 0.5s ease-out";
      currentImg.ref.style.left = `${positionX}px`;
      currentImg.ref.style.top = `${positionY}px`;

      zoomTimer.value = setTimeout(() => {
        currentImg.ref.style.transition = "none";
      }, 500);
    };

    const restoreDefaultPosition = () => {
      clearTimeout(zoomTimer.value);
      const currentImg = getCurrentImg().ref;

      currentImg.style.transition = "all 0.5s ease-out";
      currentImg.style.top = 0;
      currentImg.style.left = 0;

      calculateImgSize(currentImg);

      setTimeout(() => {
        currentImg.style.transition = "none";
      }, 500);
    };

    // ------------- navigation -------------
    const isRTL = document.documentElement.dir === "rtl";

    const handleBackdropClick = (e) => {
      resetToolsToggler();

      if (e.target.tagName !== "DIV") return;
      close();
    };

    const handleKeyup = (e) => {
      if (!isActive.value) {
        return;
      }
      e.preventDefault();

      resetToolsToggler();
      switch (e.keyCode) {
        case 39:
          if (isRTL) {
            slide("left");
          } else {
            slide();
          }

          break;
        case 37:
          if (isRTL) {
            slide();
          } else {
            slide("left");
          }

          break;
        case 27:
          close();
          break;
        case 36:
          slide("first");
          break;
        case 35:
          slide("last");
          break;
        case 38:
          zoomIn();
          break;
        case 40:
          zoomOut();
          break;
        default:
          break;
      }
    };

    // ------------- events -------------
    const addEvents = () => {
      on(galleryRef.value, "click", handleBackdropClick);
      on(window, "keyup", handleKeyup);
      on(window, "resize", handleWindowResize);
      on(window, "orientationchange", handleWindowResize);
      on(window, "fullscreenchange", handleFullScreenChange);
    };

    const removeEvents = () => {
      off(galleryRef.value, "click", handleBackdropClick);
      off(window, "keyup", handleKeyup);
      off(window, "resize", handleWindowResize);
      off(window, "orientationchange", handleWindowResize);
      off(window, "fullscreenchange", handleFullScreenChange);
    };

    // ------------- lifecycle -------------
    onMounted(() => {
      addEvents();
      queryImages();
    });

    onUnmounted(() => {
      removeEvents();
    });

    onBeforeUpdate(() => {
      images.value.forEach((img) => {
        img.ref = null;
      });
    });

    return {
      // styles
      toolsOpacity,
      fullscreen,
      isActive,
      isLoading,
      className,
      // refs
      setRef,
      lightboxRef,
      galleryRef,
      // data
      images,
      activeImgIndex,
      imgCount,
      caption,
      mousedown,
      initialImagesArray,
      // methods
      resetToolsToggler,
      setActiveImg,
      toggleZoom,
      getCurrentImg,
      // gestures
      handleMousedown,
      handleDoubleClick,
      zoom,
      handleImgScroll,
      handleMousemove,
      handleMouseup,
      // public methods
      resetImagesArrays,
      addImg,
      open,
      close,
      slide,
      zoomIn,
      zoomOut,
      toggleFullscreen,
      updateImages,
      toggleLightbox,
      handleWindowResize,
    };
  },
};
</script>
